import {parse, stringify} from 'query-string';
import {log} from "util";

type ClientProps = {
	body?: any,
	customConfig?: any,
}

async function client(host: string, endpoint: string, props: ClientProps = {}) {
	if (host === 'login' && endpoint === 'test') {
		if (props.body.UserName === 'bad')
			return Promise.reject('Invalid Credentials')
		
		return Promise.resolve({  session: "Test Key" })
	}

	const body = props.body;
	const customConfig = props.customConfig;
	const headers = {'content-type': 'application/json'}
	const config = {
		method: body ? 'POST' : 'GET',
		...customConfig,
		headers: {
			...headers,
		},
	}
	if (body) {
		config.body = JSON.stringify(body)
	}
	
	const url = `${host}/${endpoint}`

	return window
		.fetch(url, config)
		.then(async r => {
			if (r.status === 401) {
				//Expired Key??
				logout()
				// refresh the page for them
				// @ts-ignore
				window.location.assign(window.location)
				return
			}
			const data = await r.json()
			if (data.status === 'success') {
				return data
			} else {
				return Promise.reject(data.message)
			}
		})
}

const logout = () => {
	sessionStorage.removeItem('blCustomer')
	sessionStorage.removeItem('blSkippedContact')
	sessionStorage.removeItem('blMembershipTypeSet')
	sessionStorage.removeItem('blCurrentPage')
	sessionStorage.removeItem('blMembershipType')
	sessionStorage.removeItem('blGiftRecipient')
	sessionStorage.removeItem('blGiftRecipientSessionKey')
	sessionStorage.removeItem('bL_sk')
	sessionStorage.removeItem('blSessionKey')
	let params = parse(window.location.search)
	delete params.blSessionKey
	delete params.bL_sk
	delete params.bl_sk
	const props = Object.keys(params);
	window.location.assign(window.location.origin + (props.length > 0 ? '?' + stringify(params) : ''))

}

export { client, logout }
