import React, {SyntheticEvent, useEffect, useState} from 'react';
import {GiftCardBalanceSnippetProfile, WebSnippetProfile} from "../Helpers/Types";
import TextInput from "../TextInput";
import {Button} from "@material-ui/core";
import useGetGiftCardBalance from "../Utils/UseGetGiftCardBalance";
import {useSnippetContext} from "../Utils/UseSnippetContext";
import useServiceUrls from "../Utils/UseServiceUrls";
import LoadingSpinner from "../LoadingSpinner";
import {getCurrencyDisplayValue} from "../Helpers/Functions";
import useDeviceSessionProfile from "../Utils/UseDeviceSessionProfile";
import SnippetHeader from "../Helpers/SnippetHeader";
import SnippetFooter from "../Helpers/SnippetFooter";

type GiftCardBalanceSnippetProps = {
    snippetProfile: WebSnippetProfile
}

export default function GiftCardBalanceSnippet(props: GiftCardBalanceSnippetProps) {
    const config = props.snippetProfile.GiftCardBalance
    const [cardNumber, setCardNumber] = useState('')
    const [changed, setChanged] = useState(false)
    // @ts-ignore
    const { domain, deviceCode } = useSnippetContext();
    const { serviceUrls } = useServiceUrls(domain);
    const {status, balanceResponse, error, refetch, isFetching } = useGetGiftCardBalance(serviceUrls, cardNumber, domain, deviceCode)
    const { deviceSessionProfileStatus, deviceSessionProfile, deviceSessionProfileError} = useDeviceSessionProfile(serviceUrls, domain, deviceCode)
    
    const handleChange = (field: string, value: string) => {
        setCardNumber(value)
        setChanged(true)
    }
    
    const checkBalance = (e: SyntheticEvent) => {
        e.preventDefault()
        refetch()
        setChanged(false)
    }
    
    return (
        <>
            {deviceSessionProfile &&
                <>
                    <SnippetHeader logoUrl={props.snippetProfile?.LogoUrl ?? ''} title={props.snippetProfile?.Title ?? ''} message={props.snippetProfile?.Message ?? ''} snippetCode={props.snippetProfile?.Code ?? ''}/>
    
                    <TextInput id={'GiftCardNumber'} value={cardNumber} label={config?.InputLabel ? config.InputLabel : 'Gift Card Number'} handleChange={handleChange} />
                    <button className='bl-snippet-button' onClick={checkBalance} disabled={balanceResponse && !changed}>{config?.ButtonLabel ? config.ButtonLabel : 'Check Balance'}</button>
                    {balanceResponse && !changed &&
                        <>
                        <br />
                        {balanceResponse.Status === 'Approved' &&
                            <p>{`${config?.BalanceMessage ? config.BalanceMessage : 'Balance:'} ${deviceSessionProfile.Globalization.CurrencySymbol}${getCurrencyDisplayValue(balanceResponse.Balance)}`}</p>
                        }
                        {(balanceResponse.Status === 'GiftNumberNotFound' || balanceResponse.Status === 'NotProvisioned') &&
                            <p>{config?.CardNotFoundMessage ? config.CardNotFoundMessage : 'Card not found.'}</p>
                        }
                        </>
                    }
                </>
            }
            {status === 'loading' && <LoadingSpinner />}
            {status === 'error' && <p className='bLoyal-error-text'>Unable to get gift card balance.</p>}
            {deviceSessionProfileStatus === 'error' && <p className='bLoyal-error-text'>Unable to get device profile.</p>}
            <SnippetFooter footer={props.snippetProfile?.Footer ?? ''} snippetCode={props.snippetProfile?.Code ?? ''}/>
        </>
    )
}