import {useQuery} from 'react-query';
import {client, logout} from './ApiClient';
import {mapServiceUrls} from '../Helpers/Functions';

const getInventoryLocationsByUid = (key: any, urls: any, session: string, domain: string, deviceCode: string, uids: Array<any>) => {
	let uidString = '';
	uids.forEach((uid: string, index: number) => {
		if (index === 0)
			uidString += uid;
		else
			uidString += ',' + uid;
	})
	return client(urls.LoyaltyEngineApiUrl, `api/v4/${domain}/${deviceCode}/sessions/${session}/locations?uids=${uidString}`);
}

export default function useInventoryLocations(urls: any, session: string, domain: string, deviceCode: string, uids: Array<any>) {
	const {status, data, error, isFetching} = useQuery('InventoryLocations', (key) => getInventoryLocationsByUid(key, mapServiceUrls(urls), session, domain, deviceCode, uids), {enabled: urls && uids?.length > 0, staleTime: 1000*60*5});
	const inventoryLocationStatus = status;
	const inventoryLocations = data ? data.data : undefined;
	const inventoryLocationError = error;
	const isInventoryLocationFetching = isFetching
	if (error === 'Expired key')
		logout();

	return { inventoryLocationStatus, inventoryLocations, inventoryLocationError, isInventoryLocationFetching };
}
