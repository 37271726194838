import React, {createContext} from 'react';

// @ts-ignore
const DeviceSessionContext = createContext();
DeviceSessionContext.displayName = 'DeviceSessionContext';

type DeviceSessionProviderProps = {
	profile: any,
	children: any
}

function DeviceSessionProvider(props: DeviceSessionProviderProps) {

	const profile = props.profile

	const value = React.useMemo(() => ({ profile }), [
		props.profile
	]);

	return <DeviceSessionContext.Provider value={value} {...props} />;
}

function useDeviceSessionContext() {
	const context = React.useContext(DeviceSessionContext);
	if (context === undefined) {
		throw new Error(`useDeviceSessionContext must be used within a DeviceSessionProvider`);
	}
	return context;
}

export {DeviceSessionProvider, useDeviceSessionContext};
