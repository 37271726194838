import React from 'react';
import {IShippingAddress} from './Helpers/Types';

type AddressPreviewProps = {
	address: IShippingAddress,
	disabled: boolean,
}

export default function AddressPreview(props: AddressPreviewProps) {
	if (!props.address)
		return <></>
	const { LastName, FirstName, Address } = props.address;
	return (
		<div className={`bLoyal-address-preview bLoyal-center ${props.disabled ? 'disabled':''}`}>
			<p>{FirstName + ' ' + LastName}</p>
			<p>{Address.Address1 + ' ' + Address.Address2}</p>
			<p>{Address.City + ', ' + Address.State + ' ' + Address.PostalCode}</p>
		</div>
	);
}