import React, {useEffect, useMemo, useState} from 'react';
import SelectInput from "../SelectInput";
import NumberInput from "../NumberInput";
import {EGiftCard, EGiftCardSnippetProfile} from "../Helpers/Types";
import {getInputField} from "../Helpers/Functions";

type GiftAmountProps = {
    handleChange: (field: string, value: any) => void,
    state: EGiftCard,
    currencySymbol: string,
    invalidInputs: Array<any>
    config?: EGiftCardSnippetProfile,
    productName?: string,
}


const getNumber = (stringValue: string, defaultValue: number) => {
    var num = parseInt(stringValue)
    if (isNaN(num))
        return defaultValue

    return num
}

export default function GiftAmount(props: GiftAmountProps) {
    const [selectedAmount, setSelectedAmount] = useState(props.state.Amount);
    const [customAmount, setCustomAmount] = useState(props.state.CustomAmount ?? props.config?.MinValue ?? 0);

    const options = useMemo(() => props.config?.AmountOptions.map(opt => {
        return {text: opt === 'Custom' ? opt : `$${opt}`, value: opt}
    }) ?? [], [props.config?.AmountOptions]);

    useEffect(() => {
        setSelectedAmount(props.state.Amount)
        setCustomAmount(props.state.CustomAmount ?? 0)
    }, [props.state.Amount, props.state.CustomAmount]);

    const handleSelectChange = (field: string, value: string) => {
        props.handleChange('GiftAmount', value)
        setSelectedAmount(value)
    }

    const handleCustomAmountChange = (field: string, value: number) => {
        props.handleChange('CustomAmount', value)
        setCustomAmount(value)
    }

    return (
        <>
            <h3>{props.config?.GiftAmountSectionLabel ?? ''}</h3>
            {props.config?.UseCartPrice === true &&
                <strong>{`${props.productName ? `${props.productName} - ` : ''}${props.currencySymbol ?? ''}${props.state.Amount}`}</strong>}
            {props.config?.UseCartPrice === false &&
                <>
                    <SelectInput
                        label={getInputField(props.config?.EGiftFields ?? [], 'GiftAmountDropdown')?.Label ?? 'Select Amount'}
                        options={options} current={selectedAmount} disabled={false}
                        fieldName='GiftAmount' handleChange={handleSelectChange} removeWidth={true} required={true} invalid={props.invalidInputs.includes('GiftAmount')}/>
                    {selectedAmount === 'Custom' && <NumberInput id={'CustomAmount'} value={customAmount}
                                                                 label={(getInputField(props.config?.EGiftFields ?? [], 'CustomAmountInput')?.Label ?? 'Enter Amount') + ` (${props.config?.MaxValue !== null && props.config?.MaxValue !== undefined ? `${props.currencySymbol}${props.config?.MinValue ?? 0}-${props.currencySymbol}${props.config.MaxValue}` : `- Minimum: ${props.currencySymbol}${props.config?.MinValue ?? 0}`})   `}
                                                                 handleChange={handleCustomAmountChange}
                                                                 max={props.config?.MaxValue ?? undefined}
                                                                 min={props.config?.MinValue} showError={true}/>}
                </>
            }
        </>
    )
}