import React, {Fragment, useEffect, useReducer, useState} from 'react';
import {FormControlLabel, Radio, RadioGroup} from '@material-ui/core';
import {
	DeliveryType,
	ICustomer,
	IFulfillmentData,
	IShippingAddress,
	IShippingState,
	ISyntheticEvent,
	ShippingPreferenceType
} from './Helpers/Types';
import Address from './Address';
import AddressSelector from './AddressSelector';
import LoadingSpinner from './LoadingSpinner';
import InventoryLocationSelector from './InventoryLocationSelector';
import useShippingAddress from './Utils/UseShippingAddress';
import useInventoryLocations from './Utils/UseInventoryLocations';
import useServiceUrls from './Utils/UseServiceUrls';
import {
	ShippingAction,
	ShippingActionTypes,
	updateInventoryLocation,
	updateOrderType,
	updateShippingAddress
} from './ShippingActions';
import {MDBCol, MDBRow} from 'mdbreact';
import {logout} from './Utils/AuthClient';
import {useSnippetContext} from './Utils/UseSnippetContext';
import {getParameterCaseInsensitive, isInViewport} from "./Helpers/Functions";

export const shippingState: IShippingState = {
	OrderType: DeliveryType.Ship,
	ShippingAddressUid: '',
	InventoryLocationUid: ''
};

export const shippingReducer = (state = shippingState, action: ShippingAction): IShippingState => {
	switch (action.type) {
		case ShippingActionTypes.UpdateShippingAddress:
			return {
				...state,
				ShippingAddressUid: action.address
			};
		case ShippingActionTypes.UpdateInventoryLocation:
			return {
				...state,
				InventoryLocationUid: action.location
			};
		case ShippingActionTypes.UpdateOrderType:
			return {
				...state,
				OrderType: action.orderType
			};
		default:
			return state;
	}
};

type ShippingPreferencePageProps = {
	updateField: (field: string, value: any) => void
	fulfillment: IFulfillmentData
	nextStep: (e: ISyntheticEvent) => void
	prevStep: (e: ISyntheticEvent) => void
	config: any
	customer: ICustomer
	sessionKey: string
	isGift: boolean
}

export default function ShippingPreferencePage(props: ShippingPreferencePageProps) {
	const orderType = props.config.IsShippingOrder && props.config.IsPickupOrder ? props.fulfillment?.DeliveryType : props.config.IsShippingOrder ? DeliveryType.Ship : DeliveryType.Pickup
	let initialState: IShippingState = { ...shippingState, 
		ShippingAddressUid: props.fulfillment?.ShippingAddressUid,
		InventoryLocationUid: props.fulfillment?.PickupLocationUid,
		OrderType: orderType === DeliveryType.None || orderType === undefined ? props.config.IsShippingOrder ? DeliveryType.Ship : DeliveryType.Pickup : orderType
	};
	const shippingPreferenceType = props.config.IsShippingOrder && props.config.IsPickupOrder ? ShippingPreferenceType.Option : props.config.IsShippingOrder ? ShippingPreferenceType.Shipping : ShippingPreferenceType.Pickup
	const [state, dispatch] = useReducer(shippingReducer, initialState);
	// @ts-ignore
	const { domain, deviceCode } = useSnippetContext();
	const { serviceUrls } = useServiceUrls(domain);
	const { shippingAddressesStatus, shippingAddresses, shippingAddressesError, shippingAddressesFetching } = useShippingAddress(serviceUrls, props.sessionKey, domain, deviceCode, props.isGift);
	const { inventoryLocationStatus, inventoryLocations, inventoryLocationError, isInventoryLocationFetching } = useInventoryLocations(serviceUrls, props.sessionKey, domain, deviceCode, props.config.InventoryLocationUids);
	const [addressOptions, setAddressOptions] = useState<Array<any>>([]);
	const [inventoryOptions, setInventoryOptions] = useState<Array<any>>([]);
	const [editAddress, setEditAddress] = useState(false);
	const [newAddress, setNewAddress] = useState(false);
	const [, setSessionKey] = useState('');
	const [tempAddress, setTempAddress] = useState('');
	

	useEffect(() => {
		const queryString = require('query-string');
		const parsed = queryString.parse(window.location.search);
		const sessionKey = getParameterCaseInsensitive(parsed, 'bl_sk')  ?? getParameterCaseInsensitive(parsed, 'sessionKey')
		setSessionKey(sessionKey);
	}, []);

	useEffect(() => {
		if (shippingAddressesError) {
			if (shippingAddressesError === 'Expired key') {
				logout();
			}
		}
	});

	const mapAddressesToOptions = (data: any) => {
		if (!data || data.length === 0)
			return [];
		let list: Array<any> = [];
		data.forEach((address: any) => {
			if (!address.Title)
				return;

			let option = {
				value: address.Uid.toString(),
				text: address.Title.toString(),
				checked: tempAddress === address.Uid
			};
			list.push(option);
		});
		return list;
	};

	const mapLocationsToOptions = (locations: Array<any>) => {
		if (!locations || locations.length === 0)
			return [];
		let pickupLocations = locations.filter((location: any) => {
			return location.PickupCapable;
		})
		let list: Array<any> = [];
		pickupLocations.forEach((location: any) => {
			let option = {
				value: location.Uid.toString(),
				text: location.Name.toString()
			};
			list.push(option);
		});
		return list;
	};
	
	useEffect(() => {
		if (props.isGift && tempAddress !== '') {
			//@ts-ignore
			dispatch(updateShippingAddress(tempAddress));
		}
			
		if (shippingAddresses) {
			setAddressOptions(mapAddressesToOptions(shippingAddresses.data));

			if (tempAddress !== '') {
				let found = false;
				shippingAddresses.data.forEach((curr: any) => {
					if (curr.Uid === tempAddress) {
						//@ts-ignore
						dispatch(updateShippingAddress(curr.Uid));
						found = true;
					}
				});
				if (found) {
					setTempAddress('');
					return;
				}
			}

			let found = false;
			shippingAddresses.data.forEach((curr: any) => {
				if (curr.Uid === state.ShippingAddressUid) {
					found = true;
				}
				if (curr.IsPrimary && !found) {
					//@ts-ignore
					dispatch(updateShippingAddress(curr.Uid));
					found = true;
				}
			});
			if (!found && shippingAddresses.data.length !== 0)
				// @ts-ignore
				dispatch(updateShippingAddress(shippingAddresses.data[0].Uid))
		}
	}, [shippingAddresses, tempAddress]);

	useEffect(() => {
		if (inventoryLocations)
			setInventoryOptions(mapLocationsToOptions(inventoryLocations));
	}, [inventoryLocations]);

	const handleChange = (event: ISyntheticEvent) => {
		let value = event.target.value;
		if (value === 'Pickup') {
			// @ts-ignore
			dispatch(updateOrderType(DeliveryType.Pickup));
			props.updateField('OrderType', DeliveryType.Pickup);
			if ((state.InventoryLocationUid === '' || state.InventoryLocationUid === '00000000-0000-0000-0000-000000000000' || state.InventoryLocationUid === undefined) && inventoryLocations?.length > 0)
				{ // @ts-ignore
					dispatch(updateInventoryLocation(inventoryLocations[0].Uid))
				}
		}
		else {
			// @ts-ignore
			dispatch(updateOrderType(DeliveryType.Ship));
			props.updateField('OrderType', DeliveryType.Ship);
			if ((state.ShippingAddressUid === '' || state.ShippingAddressUid === '00000000-0000-0000-0000-000000000000') && shippingAddresses?.data.length > 0)
			{
				// @ts-ignore
				dispatch(updateShippingAddress(shippingAddresses.data[0].Uid));
			}
		}

	};

	const handleSelectChange = (fieldName: string, value: string) => {
		switch (fieldName) {
			case 'ShippingAddress':
				//@ts-ignore
				dispatch(updateShippingAddress(value));
				break;
			case 'InventoryLocation':
				//@ts-ignore
				dispatch(updateInventoryLocation(value));
				break;
			default:
		}
		props.updateField(fieldName, value);
	};

	const toggleEditAddress = (type: string) => {
		console.log(type)
		if (type === 'new')
			setNewAddress(true);
		else if (type === 'edit')
			setNewAddress(false);
		else
			setTempAddress(type);

		if (!props.isGift)
			setEditAddress(!editAddress);
	};
	
	const saveAndContinue = (e: ISyntheticEvent) => {
		if (state.OrderType === DeliveryType.Ship) {
			const fulfillment: IFulfillmentData = {DeliveryType: DeliveryType.Ship, ShippingAddressUid: state.ShippingAddressUid, PickupLocationUid: ''}
			props.updateField('Fulfillment', fulfillment)
		} else {
			const fulfillment: IFulfillmentData = {DeliveryType: DeliveryType.Pickup, ShippingAddressUid: '', PickupLocationUid: state.InventoryLocationUid}
			props.updateField('Fulfillment', fulfillment)
		}
		props.nextStep(e);
	}

	return (
		<>
			<div className='bLoyal-center form'>
				{(shippingAddressesStatus === 'loading' || inventoryLocationStatus === 'loading') &&
                <LoadingSpinner/>
				}
				{(isInventoryLocationFetching || shippingAddressesFetching) &&
                <p>Refreshing...</p>
				}
				{(shippingAddressesStatus === 'success' || props.isGift) && shippingPreferenceType === ShippingPreferenceType.Option &&
					<Fragment>
						<RadioGroup aria-label="productSelector" name="products" value={state.OrderType} onChange={handleChange}>
							<MDBRow style={{width: '90%'}} className='bLoyal-center'>
								<MDBCol md='2'>
									<FormControlLabel className='shipping-radio' value={DeliveryType.Ship} control={<Radio/>}
													  label={props.config.ShippingAddressSelectionText + ':'}/>
		
								</MDBCol>
								{props.isGift && state.OrderType === DeliveryType.Ship &&
									<Address fields={props.config.ShippingAddressFields} customer={props.customer}
											 sessionKey={props.sessionKey}
											 address={null}
											 toggleEdit={toggleEditAddress} isGift={props.isGift}/>
								}
								{!props.isGift &&
									<>
										<AddressSelector editAddress={toggleEditAddress}
														 disabled={state.OrderType !== DeliveryType.Ship}
														 options={addressOptions}
														 value={state.ShippingAddressUid}
														 handleChange={handleSelectChange}
														 address={shippingAddresses.data.find((curr: IShippingAddress) => curr.Uid === state.ShippingAddressUid)}
														 editing={editAddress}
										/>
									</>
								}
							</MDBRow>
							<br/>
							{editAddress && !props.isGift &&
							<Address fields={props.config.ShippingAddressFields} customer={props.customer} sessionKey={props.sessionKey} address={newAddress ? null : shippingAddresses.data.find((curr: IShippingAddress) => curr.Uid === state.ShippingAddressUid)}
									 toggleEdit={toggleEditAddress}/>
							}
							{(!editAddress || props.isGift) && props.config.InventoryLocationUids.length !== 0 &&
							<>
								<br/>
								<MDBRow style={{width: '90%'}} className='bLoyal-center'>
									<MDBCol md='2'>
										<FormControlLabel className='shipping-radio' value={DeliveryType.Pickup} control={<Radio/>} label={props.config.PickupLocationSelectionText + ':'}/>
									</MDBCol>
									<MDBCol md='10'>
										<InventoryLocationSelector disabled={state.OrderType !== DeliveryType.Pickup} options={inventoryOptions}
																   value={state.InventoryLocationUid}
																   handleChange={handleSelectChange}/>
									</MDBCol>
								</MDBRow>
							</>
							}
		
						</RadioGroup>
					</Fragment>
				}
				{shippingAddressesStatus === 'success' && shippingPreferenceType === ShippingPreferenceType.Shipping &&
					<>
				<MDBRow>
					<MDBCol size='2'>
						<p className="single-shipping-option">{props.config.ShippingAddressSelectionText + ':'}</p>

					</MDBCol>
					<MDBCol size='10'>
						{props.isGift &&
							<Address fields={props.config.ShippingAddressFields} customer={props.customer}
									 sessionKey={props.sessionKey}
									 address={null}
									 toggleEdit={toggleEditAddress} isGift={props.isGift}/>
						}
						{!props.isGift &&
							<>
								<AddressSelector editAddress={toggleEditAddress}
												 disabled={state.OrderType !== DeliveryType.Ship}
												 options={addressOptions}
												 value={state.ShippingAddressUid}
												 handleChange={handleSelectChange}
												 address={shippingAddresses.data.find((curr: IShippingAddress) => curr.Uid === state.ShippingAddressUid)}
												 editing={editAddress}
								/>
							</>
						}
					</MDBCol>
				</MDBRow>
				<br/>
				{editAddress && !props.isGift &&
				<Address fields={props.config.ShippingAddressFields} customer={props.customer} sessionKey={props.sessionKey} address={newAddress ? null : shippingAddresses.data.find((curr: IShippingAddress) => curr.Uid === state.ShippingAddressUid)}
						 toggleEdit={toggleEditAddress}/>
				}
				</>
				}
				{shippingAddressesStatus === 'success' && shippingPreferenceType === ShippingPreferenceType.Pickup &&
				<MDBRow>
					<MDBCol size='3'>
						<p className="single-shipping-option">{props.config.PickupLocationSelectionText + ':'}</p>
					</MDBCol>
					<MDBCol size='9'>
						<InventoryLocationSelector disabled={state.OrderType !== DeliveryType.Pickup} options={inventoryOptions}
												   value={state.InventoryLocationUid}
												   handleChange={handleSelectChange}/>
					</MDBCol>
				</MDBRow>
				}

					</div>
			<br/>
			{!editAddress &&
            <>
                <button className='bl-snippet-button' id='back' onClick={props.prevStep}>Back</button>
                <button className='bl-snippet-button' id='continue' onClick={saveAndContinue} disabled={(state.OrderType === DeliveryType.Ship && (state.ShippingAddressUid === '' || state.ShippingAddressUid === '00000000-0000-0000-0000-000000000000')) || (state.OrderType === DeliveryType.Pickup && (state.InventoryLocationUid === '' || state.InventoryLocationUid === '00000000-0000-0000-0000-000000000000')) || state.OrderType === DeliveryType.None}>Continue</button>
            </>
			}
			{(shippingAddressesError || inventoryLocationError) &&
            <p className='bLoyal-error-text'>There was an error completing the request.</p>
			}
		</>
	);
}