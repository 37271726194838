import {useQuery} from 'react-query';
import {client} from './ApiClient';
import {mapServiceUrls} from '../Helpers/Functions';

const getSnippetConfigFromLoyaltyEngine = (key: any, urls: any, domain: string, deviceCode: string, snippetCode: string) => {
	return client(urls.LoyaltyEngineApiUrl, `api/v4/${domain}/${deviceCode}/snippets/websnippetprofiles/profile/?code=${snippetCode}`);
}

export default function useSnippetConfig(urls: any, domain: string, deviceCode: string, snippetCode: string, enabled: boolean = true) {
	const {status, data, error, isFetching } = useQuery(['SnippetConfig', snippetCode], (key) => {
		return getSnippetConfigFromLoyaltyEngine(key, mapServiceUrls(urls), domain, deviceCode, snippetCode)
		
	}, { enabled: urls && enabled, staleTime: 1000*60*5 });
	const SnippetConfigStatus = status;
	// @ts-ignore
	const SnippetConfig = data ? data.data : undefined;
	const SnippetConfigError = error;
	const SnippetConfigFetching = isFetching;
	return {SnippetConfigStatus, SnippetConfig, SnippetConfigError, SnippetConfigFetching};
}
