import React, {useMemo} from 'react';
import RadioSelection from "./RadioSelection";
import {ChoiceResponse} from "../../Helpers/Types";

type GiftChoiceProps = {
    label: string,
    helpText: string,
    value: ChoiceResponse,
    handleChange: (field: string, value: any) => void
}

export default function GiftChoice(props: GiftChoiceProps) {
    
    const radioOptions = useMemo(() => [{label: "Yes", value: ChoiceResponse.Yes}, {label: "No", value: ChoiceResponse.No}], [])
    
    return (
        <RadioSelection  fieldName={'giftChoice'} value={props.value} handleChange={props.handleChange} helpText={props.helpText} label={props.label} options={radioOptions}/>
    )
}