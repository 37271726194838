import React, {useEffect, useMemo, useState} from 'react';
import SelectInput from './SelectInput';
import {BillingAddress, IPaymentType, ISyntheticEvent, PaymentType} from './Helpers/Types';
import TextInput from './TextInput';
import UsaePayAddCreditCardFrame from './UsaePayAddCreditCardFrame';
import useSavePaymentMethod from './Utils/UseSavePaymentMethod';
import {useSnippetContext} from './Utils/UseSnippetContext';
import {useAuth} from './Utils/UseAuth';
import {mapServiceUrls} from './Helpers/Functions';
import useServiceUrls from './Utils/UseServiceUrls';
import {Checkbox, FormControlLabel, FormGroup} from '@material-ui/core';
import {v4 as uuid} from 'uuid'
import useDeviceSessionProfile from "./Utils/UseDeviceSessionProfile";
import UsaepayForm from "./PaymentFlows/UsaepayForm";
import CardConnectForm from "./PaymentFlows/CardConnectForm";
import {CardConnectProviderUid, UsaePayProviderUid} from "./Helpers/Constants";

type AddPaymentDetailsProps = {
	close: () => void
	savePayment: (title: string) => void
	saveCreditPayment: (token: string, cardNumber: string, cardType: string, isPrimary: boolean, title: string, billingAddress: BillingAddress) => void
	payment: any
	paymentTypeList: Array<IPaymentType>
}

export default function AddPaymentDetails(props: AddPaymentDetailsProps) {
	// @ts-ignore
	const { domain, deviceCode } = useSnippetContext();
	// @ts-ignore
	const { session } = useAuth();
	const { serviceUrls } = useServiceUrls(domain);
	const { deviceSessionProfile } = useDeviceSessionProfile(serviceUrls, domain, deviceCode)
	const [savePaymentMethod, { status, error, data }] = useSavePaymentMethod();
	const [paymentType, setPaymentType] = useState<PaymentType>(props.payment ? props.payment.PaymentType : PaymentType.Undefined);
	const [giftCardNum, setGiftCardNum] = useState(props.payment && props.payment.GiftCard ? props.payment.GiftCard.CardMask : '');
	const [title, setTitle] = useState(props.payment ? props.payment.Title : '');
	const [isPrimary, setIsPrimary] = useState(props.payment ? props.payment.IsPrimary : false);
	const [showCreditCard, setShowCreditCard] = useState(false);

	const paymentTypeOptions = useMemo(() => props.paymentTypeList?.filter((type) => {
		return type.IsDisplay;
	}).map((type: IPaymentType) => {
		switch (type.Enumkey) {
			case PaymentType.CreditCard:
				return {
					text: "Credit Card",
					value: PaymentType.CreditCard
				}
			case PaymentType.GiftCard:
				return {
					text: "Gift Card",
					value: PaymentType.GiftCard
				}
			default:
				return {
					text: type.Enumkey,
					value: type.Enumkey
				}
		}
	}), [props.paymentTypeList])
	
	useEffect(() => {
		if (paymentTypeOptions?.length === 1) {
			// @ts-ignore
			setPaymentType(paymentTypeOptions[0].value)
		}
	}, [paymentTypeOptions])
	
	useEffect(() => {
		if (status === 'success') {
			props.savePayment(data.data.PaymentMethod.Uid)
		}
	}, [status])
	
	const saveCreditPayment = (token: string, cardNumber: string, cardType: string, billingAddress: BillingAddress) => {
		props.saveCreditPayment(token, cardNumber, cardType, isPrimary, title, billingAddress);
	};

	const saveGiftCardPayment = () => {
		const uid = null;
		savePaymentMethod({
			creditCard: undefined,
			giftCard: {
				CardNumber: giftCardNum
			},
			paymentMethodUid: uid,
			title: title,
			isPrimary: isPrimary,
			deviceCode: deviceCode, domain: domain, paymentType: paymentType, session: session, urls: mapServiceUrls(serviceUrls)
		});
	};

	const handleSelectChange = (field: string, value: string) => {
		switch (value) {
			case 'GiftCard':
				setPaymentType(PaymentType.GiftCard);
				return;
			case 'CreditCard':
				setPaymentType(PaymentType.CreditCard);
				return;
			default:
				setPaymentType(PaymentType.Undefined);
		}
	};
	
	const showCreditCardPage = (e: ISyntheticEvent) => {
		e.preventDefault();
		setShowCreditCard(true);
	}
	
	const handleCheckboxChange = (e: ISyntheticEvent) => {
		setIsPrimary(e.target.checked);
	}

	return (
		<div className={'bLoyal-snippet-container'}>
			<div className='bLoyal-center bLoyal-form'>
				{!showCreditCard &&
				<>
					<TextInput id='new-payment-method-title' value={title} label='Title' getValue={setTitle}/>
					<SelectInput label='Payment Type' options={paymentTypeOptions} current={paymentType} disabled={false} fieldName='PaymentType'
								 handleChange={handleSelectChange} width={182}/>
					<FormGroup row className='bLoyal-center' style={{width: '182px'}}>
						<FormControlLabel
							control={<Checkbox checked={isPrimary} onChange={handleCheckboxChange} name="isPrimary" />}
							label="Primary payment"
						/>
					</FormGroup>
				</>
				}
				{paymentType === PaymentType.GiftCard &&
				<>
					<TextInput id='gift-card-number' value={giftCardNum} label='Gift Card Number' getValue={setGiftCardNum}/>
					<br/>
					<button className='bl-snippet-button' id='save-gift-card' onClick={saveGiftCardPayment}>Save</button>
				</>
				}
				{paymentType === PaymentType.CreditCard && !showCreditCard &&
				<button className='bl-snippet-button' id='save-credit-card' onClick={showCreditCardPage}>Next</button>
				}
				{showCreditCard &&
					<>
						{deviceSessionProfile.PaymentProviderUid.toLowerCase() === CardConnectProviderUid &&
							<CardConnectForm savePayment={saveCreditPayment} payment={props.payment}
											 paymentFormUrl={deviceSessionProfile.PaymentFormUrl}
											 back={() => setShowCreditCard(false)}/>
						}
						{deviceSessionProfile.PaymentProviderUid.toLowerCase() === UsaePayProviderUid &&
							<UsaepayForm payment={props.payment} savePayment={saveCreditPayment}
										 paymentFormUrl={deviceSessionProfile.PaymentFormUrl}
										 back={() => setShowCreditCard(false)}/>
						}
						{deviceSessionProfile.PaymentProviderUid === null && <p>No payment provider found.</p>}
					</>
				}
			</div>
		</div>
	);
}