import React, {createContext} from 'react';
import LoadingSpinner from '../LoadingSpinner';
import * as authClient from './AuthClient';
import {useAsync} from './UseAsync';
import {getParameterCaseInsensitive} from "../Helpers/Functions";

// @ts-ignore
const AuthContext = createContext()
AuthContext.displayName = 'AuthContext'


function AuthProvider(props: any) {
	const {
		data,
		status,
		isLoading,
		isIdle,
		isSuccess,
		setData,
	} = useAsync()

	const setSession = React.useCallback(
		(session, customer, coupons) => {
			if (!urlSession)
				sessionStorage.setItem('bL_sk', session);
			
			setData({ session, customer, coupons })
		},
		[setData],
	)
	const logout = React.useCallback(() => {
		authClient.logout()
		setData(null)
	}, [setData])

	const queryString = require('query-string');
	const parsed = queryString.parse(window.location.search);
	let tempSession = getParameterCaseInsensitive(parsed, 'bl_sk')  ?? getParameterCaseInsensitive(parsed, 'blsessionKey')
	
	let urlSession = true;

	if (tempSession === null || tempSession === undefined || tempSession === "") {
		urlSession = false;
		tempSession = data ? data.session : sessionStorage.getItem('bL_sk') !== null ? sessionStorage.getItem('bL_sk') : sessionStorage.getItem('blSessionKey');
	} else {
		urlSession = true;

		sessionStorage.removeItem('blSkippedContact')
		sessionStorage.removeItem('blMembershipTypeSet')
		sessionStorage.removeItem('blCurrentPage')
		sessionStorage.removeItem('blMembershipType')
		sessionStorage.removeItem('bL_sk')
		sessionStorage.removeItem('blSessionKey')
	}
	
	const session = tempSession;
	const customer = data ? data.customer : undefined
	// const standardCoupons = data ? data.StandardCoupons : []
	// standardCoupons.concat(data ? data.AwardedCoupons : [])
	// const coupons = standardCoupons

	const value = React.useMemo(() => ({session, setSession, logout, customer, urlSession}), [
		setSession,
		logout,
		session,
		customer,
		urlSession
	])

	if (isLoading) {
		return <LoadingSpinner />
	}

	if (isSuccess || isIdle) {
		return <AuthContext.Provider value={value} {...props} />
	}

	throw new Error(`Unhandled status: ${status}`)
}

function useAuth() {
	const context = React.useContext(AuthContext)
	if (context === undefined) {
		throw new Error(`useAuth must be used within a AuthProvider`)
	}
	return context
}

export {AuthProvider, useAuth}
