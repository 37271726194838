import {useQuery} from 'react-query';
import {client} from './ApiClient';
import {mapServiceUrls} from '../Helpers/Functions';

const getDeviceSessionProfile = (key: any, urls: any, domain: string, deviceCode: string) => {
	return client(urls.LoyaltyEngineApiUrl, `api/v4/${domain}/${deviceCode}/deviceSessionProfile`);
}

export default function useDeviceSessionProfile(urls: any, domain: string, deviceCode: string) {
	const {status, data, error, isFetching } = useQuery(['DeviceSessionProfile', deviceCode], (key) => getDeviceSessionProfile(key, mapServiceUrls(urls), domain, deviceCode), { enabled: urls, staleTime: 1000*60*60 });
	const deviceSessionProfileStatus = status;
	const deviceSessionProfile = data ? data.data : undefined;
	const deviceSessionProfileError = error;
	const deviceSessionProfileFetching = isFetching;
	return {deviceSessionProfileStatus, deviceSessionProfile, deviceSessionProfileError, deviceSessionProfileFetching};
}
