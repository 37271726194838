import { useMutation} from 'react-query';
import {client} from './ApiClient';

const loginCustomer = ({urls, domain, deviceCode, email, password}: {urls: any; domain: string; deviceCode: string; email: string; password: string;}) => {
	return client(urls.LoyaltyEngineApiUrl, `api/v4/${domain}/${deviceCode}/sessions/commands/createsession`, {
		body: {
			EmailAddress: email,
			Password: password
		}
	});
}

export default function useLogin() {
	// @ts-ignore
	return useMutation(loginCustomer)
}