import React from 'react';
import {IInputConfig} from "../Helpers/Types";
import {Grid} from "@mui/material";
import {getDisplayValue} from "../Helpers/Functions";

type ShipmentSummariesProps = {
    order: any,
    getField: (field: string) => IInputConfig | undefined
}

export default function ShipmentSummaries(props: ShipmentSummariesProps) {
    
    const mapShipment = (shipment: any, index: number) => {
        return (
            <Grid item xs={6} key={index}>
                <Grid container style={{width: '30%', minWidth: '200px', marginRight: '20px'}}>
                    <Grid item xs={12} style={{height: '25px'}}>
                        <p style={{float: 'left'}}>{props.getField('ShipmentType')?.Label ?? 'Order Type'}:</p>
                        <p style={{float: 'right'}}>{getDisplayValue(shipment.ShipmentType)}</p>
                    </Grid>
                    {shipment.ShipmentType === 'ShipTo' &&
                    <>
                        <Grid item xs={12} style={{height: '25px'}}>
                            <p style={{float: 'left'}}>{props.getField('Carrier')?.Label ?? 'Carrier'}:</p>
                            <p style={{float: 'right'}}>{getDisplayValue(shipment.CarrierCode)}</p>
                        </Grid>
                        {/*<Grid item xs={12} style={{height: '25px'}}>*/}
                        {/*    <p style={{float: 'left'}}>{props.getField('Tracking')?.Label ?? 'Tracking'}:</p>*/}
                        {/*    <p style={{float: 'right'}}>PLACEHOLDER</p>*/}
                        {/*</Grid>*/}
                    </>    
                    }
                </Grid>
            </Grid>
        )
    }
    
    return (
        <Grid container>
            {props.order.Shipments.map(mapShipment)}
        </Grid>
    )
}