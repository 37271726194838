import React, {useState} from 'react';
import useResetPassword from "./Utils/UseResetPassword";
import TextInput from "./TextInput";
import {ISyntheticEvent} from "./Helpers/Types";
import {useSnippetContext} from "./Utils/UseSnippetContext";
import useServiceUrls from "./Utils/UseServiceUrls";
import {mapServiceUrls} from "./Helpers/Functions";
import LoadingSpinner from "./LoadingSpinner";
import EmailInput from "./EmailInput";

type ResetPasswordProps = {
    emailAddress: string
    back: Function
}

export default function ResetPassword(props: ResetPasswordProps) {
    const [email, setEmail] = useState(props.emailAddress)
    // @ts-ignore 
    const { domain, deviceCode } = useSnippetContext();
    const { serviceUrls } = useServiceUrls(domain);
    const [resetPassword, {  isLoading, isSuccess, data, isError, error, status, reset }] = useResetPassword();
    
    const handleChange = (fieldName: string, value: string) => {
        reset();
        setEmail(value);
    }
    
    const back = (e: ISyntheticEvent) => {
        e.preventDefault();
        props.back();
    }
    
    const submit = (e: ISyntheticEvent) => {
        e.preventDefault();
        resetPassword({urls: mapServiceUrls(serviceUrls), domain, deviceCode, email})
    }
        
    return (
        <div className='bLoyal-center'>
            <h1>Password Reset</h1>
            <p>Please enter your email address and we will send you a link to reset your password.</p>
            <div className='bLoyal-input-holder bLoyal-center'>
                <form onSubmit={submit} >
                    <EmailInput id='password-reset-email' value={email} label='Email Address' handleChange={handleChange}/>
                    <button className='bl-snippet-button' onClick={back}>Back</button>
                    <button className='bl-snippet-button' type='submit' >Send Email</button>
                </form>
                {isLoading && <LoadingSpinner />}
                {isError &&
                    //@ts-ignore    
                    <p className='bLoyal-message'>If a customer exists with this email address, a reset mail will be sent.</p>
                }
                {isSuccess && <p className='bLoyal-message'>If a customer exists with this email address, a reset mail will be sent.</p>}
            </div>
        </div>
    )
}