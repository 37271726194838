import {useQuery} from 'react-query';
import {client, logout} from './ApiClient';
import {mapServiceUrls} from '../Helpers/Functions';

const getClubMembership = (key: any, urls: any, domain: string, deviceCode: string, session: string, clubUid: string) => {
	return client(urls.LoyaltyEngineApiUrl, `api/v4/${domain}/${deviceCode}/sessions/${session}/clubmembership/${clubUid}`);
}

export default function useClubMembership(urls: any, domain: string, deviceCode: string, session: string, clubUid: string) {
	const {status, data, error, isFetching, remove: removeMembership } = useQuery(['ClubMembership', clubUid], (key) => getClubMembership(key, mapServiceUrls(urls), domain, deviceCode, session, clubUid), { enabled: urls && clubUid, staleTime: 1000*60*5 });
	const membershipStatus = status;
	const membership = data ? data.data : undefined;
	const membershipError = error;
	const membershipIsFetching = isFetching;
	if (error === 'Expired key')
		logout();

	return {membershipStatus, membership, membershipError, membershipIsFetching, removeMembership};
}
