import React from 'react';
import SelectInput from './SelectInput';

type InventorySelectorProps = {
	disabled: boolean,
	options: { text: string, value: string }[],
	value: string,
	handleChange: Function
}

export default function InventoryLocationSelector(props: InventorySelectorProps) {

	const handleChange = (field: string, value: string) => {
		props.handleChange('InventoryLocation', value);
	};

	return (
			<div className="bLoyal-center">
				<SelectInput
					options={props.options}
					label="Choose your pickup location"
					current={props.value}
					handleChange={handleChange}
					fieldName='PickupLocation'
					disabled={props.disabled}
				/>
			</div>
	);
}