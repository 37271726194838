import React, {useMemo, useReducer, useState} from 'react';
import {useDeviceSessionContext} from "../Utils/UseDeviceSessionProfileContext";
import {useAuth} from "../Utils/UseAuth";
import {buildSignupState, signupReducer} from "../SignupSnippet";
import {PasswordState, WebSnippetProfile} from "../Helpers/Types";
import {useSnippetContext} from "../Utils/UseSnippetContext";
import useServiceUrls from "../Utils/UseServiceUrls";
import SnippetHeader from "../Helpers/SnippetHeader";
import Table from "bloyal-component-library/dist/Tables/Table";
import SnippetFooter from "../Helpers/SnippetFooter";
import useCustomerSalesTransactions from "../Utils/UseCustomerSalesTransactions";
import LoadingSpinner from "../LoadingSpinner";
import ClickableTable from "bloyal-component-library/dist/Tables/ClickableTable";
import OrderDetails from "./OrderDetails";
import {formatDate} from "../Helpers/Functions";
import {getOrderColumns} from "../Helpers/ComponentFunctions";

type PurchaseHistorySnippetProps = {
    snippetProfile: WebSnippetProfile
}

export default function PurchaseHistorySnippet(props: PurchaseHistorySnippetProps) {
    const snippetProfile = props.snippetProfile.PurchaseHistory
    // @ts-ignore
    const {profile} = useDeviceSessionContext();
    // @ts-ignore
    const { customer, session } = useAuth()
    // @ts-ignore
    const { domain, deviceCode } = useSnippetContext();
    const { serviceUrls } = useServiceUrls(domain);
    const [recordNumber, setRecordNumber] = useState('0')
    const [pageSize, setPageSize] = useState('100')
    const [order, setOrder] = useState(undefined)
    const {salesTransStatus: status, salesTrans, salesTransError: error} = useCustomerSalesTransactions(serviceUrls, domain, deviceCode, session, recordNumber, pageSize)

    const columns = useMemo(() => getOrderColumns(snippetProfile), [snippetProfile])
    
    const onClick = (row: any) => {
        setOrder(row.original)
    }
    
    const close = () => {
        setOrder(undefined)
    }
    
    return (
        <>
            <SnippetHeader logoUrl={props.snippetProfile.LogoUrl} title={props.snippetProfile.Title} message={props.snippetProfile.Message} snippetCode={props.snippetProfile.Code}/>
            {status === 'loading' && <LoadingSpinner />}
            {order && <OrderDetails order={order} close={close} snippetProfile={snippetProfile}/>}
            <div className='bLoyal-center' style={{padding: '0 10px'}}>
                {salesTrans && <ClickableTable columns={columns} data={salesTrans} onClick={onClick} alignHeaders='right'/>}
            </div>
            {error && <p className={'bloyal-error-text'}>Unable to load purchase history.</p>}
            <SnippetFooter footer={props.snippetProfile.Footer} snippetCode={props.snippetProfile.Code}/>
        </>
    )
}