import React, {Fragment, SyntheticEvent, useEffect, useMemo, useState} from 'react';
import LoadingSpinner from "../LoadingSpinner";
import useCountries from "../Utils/UseCountries";
import useStates from "../Utils/UseStates";
import {doesCountryHaveStates} from "../Helpers/Functions";
import {useSnippetContext} from "../Utils/UseSnippetContext";
import {useAuth} from "../Utils/UseAuth";
import useServiceUrls from "../Utils/UseServiceUrls";
import {ICountry, IState} from "../Helpers/Types";
import TextInput from "../TextInput";
import SelectInput from "../SelectInput";
import {useDeviceSessionContext} from "../Utils/UseDeviceSessionProfileContext";
import {use} from "msw/lib/types/utils/internal/requestHandlerUtils";

type BillingAddressFormProps = {
    payment: any
    next: (street: string, street2: string, city: string, state: string, zip: string, country: string, name: string) => void
    prev: Function
}

export default function BillingAddressForm(props: BillingAddressFormProps) {
    // @ts-ignore
    const {profile} = useDeviceSessionContext()
    // @ts-ignore
    const { domain, deviceCode } = useSnippetContext();
    // @ts-ignore
    const { session, setSession, customer } = useAuth();
    const { serviceUrls } = useServiceUrls(domain);
    const [street, setStreet] = useState(props.payment?.CreditCard?.BillingAddress1 ?? '')
    const [street2, setStreet2] = useState(props.payment?.CreditCard?.BillingAddress2 ?? '')
    const [city, setCity] = useState(props.payment?.CreditCard?.BillingCity ?? '')
    const [state, setState] = useState(props.payment?.CreditCard?.BillingState ?? '')
    const [zip, setZip] = useState(props.payment?.CreditCard?.BillingPostalCode ?? '')
    const [country, setCountry] = useState(props.payment?.CreditCard?.BillingCountry ?? profile?.DefaultCountry ?? '')
    const [name, setName] = useState(props.payment?.CreditCard?.BillingName ?? '')
    const { countries, countriesStatus } = useCountries(serviceUrls, domain, deviceCode);
    const { states } = useStates(serviceUrls, domain, deviceCode, country, countries && doesCountryHaveStates(countries, country));
    
    const countryOptions = useMemo(() => countries?.map((c: ICountry) => { return {text: c.Name, value: c.Code}}), [countries])
    const stateOptions = useMemo(() => states?.map((s: IState) => { return {text: s.Name, value: s.Code}}), [states])
    const countryHasStates = useMemo(() => doesCountryHaveStates(countries, country), [country])
    
    useEffect(() => {
        if ((country === null || country === '') && profile)
            setCountry(profile.DefaultCountry)
    }, [profile])
    
    const submit = (e: SyntheticEvent) => {
        e.preventDefault()
        props.next(street, street2, city, state, zip, country, name)
    }
    
    const prev = (e: SyntheticEvent) => {
        e.preventDefault()
        props.prev()
    }
    
    const handleChange = (field: string, value: string) => {
        switch (field) {
            case 'BillingName':
                setName(value)
                break;
            case 'BillingAddress':
                setStreet(value)
                break;
            case 'BillingAddress2':
                setStreet2(value)
                break;
            case 'BillingCity':
                setCity(value)
                break;
            case 'BillingState':
                setState(value)
                break;
            case 'BillingZip':
                setZip(value)
                break;
            case 'BillingCountry':
                setCountry(value)
                break;
        }
    }
    
    const copyFromCustomer = () => {
        setName(`${customer?.FirstName === null ? '' : customer?.FirstName === undefined ? '' : customer.FirstName} ${customer?.LastName === null ? '' : customer?.LastName === undefined ? '' : customer.LastName}`)
        setStreet(customer.Address.Address1)
        setStreet2(customer.Address.Address2)
        setCity(customer.Address.City)
        setState(customer.Address.State)
        setZip(customer.Address.PostalCode)
        setCountry(customer.Address.Customer)
    }
    
    return (
        <>
            {countriesStatus === 'loading' && <LoadingSpinner />}
            {countriesStatus === 'success' &&
                <div className='bLoyal-center form'>
                    <h3>Billing Address</h3>
                    <div className='bLoyal-center bLoyal-input-holder'>
                        <button className='bl-snippet-button' onClick={copyFromCustomer} >Copy from Contact</button>
                        <TextInput id={'BillingName'} value={name} label='Name'
                                   handleChange={handleChange}/>
                        <TextInput id={'BillingAddress'} value={street} label='Address'
                                   handleChange={handleChange}/>
                        <TextInput id={'BillingAddress2'} value={street2} label='Address 2'
                                   handleChange={handleChange}/>
                        <TextInput id={'BillingCity'} value={city} label='City'
                                   handleChange={handleChange}/>
                        <TextInput id={'BillingZip'} value={zip} label='Postal Code'
                                   handleChange={handleChange}/>
                        <SelectInput current={country} disabled={false} fieldName={'BillingCountry'} handleChange={handleChange} options={countryOptions}
                        label='Country'/>
                        {countryHasStates && states &&
                            <SelectInput current={state} disabled={false} fieldName={'BillingState'}
                                         handleChange={handleChange}
                                         options={stateOptions} label={'State'} />
                        }
                        {!countryHasStates &&
                            <TextInput id={'BillingState'} value={state} label='State'
                                       handleChange={handleChange}/>
                        }
                    </div>
                </div>
            }
            <br/>
            <button className='bl-snippet-button' id='BillingAddressBack' onClick={prev}>Back</button>
            <button className='bl-snippet-button' id='BillingAddressContinue' onClick={submit}>Continue</button>
        </>
    )
}