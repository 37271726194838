import React, {SyntheticEvent, useEffect, useMemo, useState} from 'react';
import {mapCardConnectCardType} from "../Helpers/Functions";
import LoadingSpinner from "../LoadingSpinner";

type CardConnectAddCreditCardFrameProps = {
    savePayment: Function
    paymentFormUrl: string
    cardNumberLabel?: string
    cvvLabel?: string
    expirationLabel?: string
}

export default function CardConnectAddCreditCardFrame(props: CardConnectAddCreditCardFrameProps) {
    const cardNumberLabel = useMemo(() => 'Credit Card Number', [])
    const cvvLabel = useMemo(() => 'CVV', [])
    const expirationLabel = useMemo(() => 'Expiration Date', [])
    const [token, setToken] = useState('')
    const [saving, setSaving] = useState(false)
    const [error, setError] = useState(false)
    
    useEffect(() => {
        if (saving)
        window.setTimeout(function () {
            setError(true)
            setSaving(false)
        }, (2000))
    }, [saving])

    const setTokenFromMessage = (event: any) => { 
        if (event === undefined || event === null)
            return;

        try {
            const value = JSON.parse(event.data)
            if (value.message !== token) {
                setToken(value.message)
                const mask = "############" + value.message.slice(-4)
                const cardType = mapCardConnectCardType(value.message.charAt(1))
                props.savePayment(value.message, mask, cardType)
            }
        } catch (e) {

        }
    }

    useEffect(() => {
        window.addEventListener('message', setTokenFromMessage, false);
        return () => {
            window.removeEventListener('message', setTokenFromMessage, false);
        };
    }, []);

    const saveCard = (e: SyntheticEvent) => {
        e.preventDefault()
        setSaving(true)
        setError(false)
    }


    return (
        <div>
            <p className={'bLoyal-center'} >Once your information is entered the form will automatically save your payment method.</p>
            <form name="tokenform" id="tokenform">
                <iframe id="tokenFrame" name="tokenFrame" src={`${props.paymentFormUrl.includes('http') ? props.paymentFormUrl : `https://${props.paymentFormUrl}`}?useexpiry=true&usecvv=true&formatinput=true&css=%2Eerror%7Bcolor%3A%20red%3B%7Dinput%7Bwidth%3A250px%3B%7D&cardlabel=${cardNumberLabel}&cvvlabel=${cvvLabel}&expirylabel=${expirationLabel}`} frameBorder="0" scrolling="no" width="300" height="300"></iframe>
                <input type="hidden" name="mytoken" id="mytoken"/>
            </form>
            <button className='bl-snippet-button' id='save-credit-card' onClick={saveCard}>Save</button>
            {saving && <LoadingSpinner />}
            {error && <p className={'bLoyal-error-text'} >Please make sure you have entered your card details properly.</p> }
        </div>
    )

}