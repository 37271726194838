import React from 'react'
import { ImpulseSpinner } from 'react-spinners-kit';

export default function LoadingSpinner()  {
	const color1 = "#a7a7a7"
	const color2 = "#000000";


	return (
		<div className="bLoyal-center-spinner" id="loading" data-testid='loadingSpinner'>
			<ImpulseSpinner loading={true} size={40} frontColor={color1} backColor={color2} />
		</div>
	)
}