import React from 'react';
import {WebSnippetProfile} from "../Helpers/Types";
import {useSnippetContext} from "../Utils/UseSnippetContext";
import useGetAlert from "../Utils/UseGetAlert";
import useServiceUrls from "../Utils/UseServiceUrls";
import SnippetHeader from "../Helpers/SnippetHeader";
import SnippetFooter from "../Helpers/SnippetFooter";

type AlertSnippetProps = {
    profile: WebSnippetProfile
}

export default function AlertSnippet(props: AlertSnippetProps) {
    // @ts-ignore
    const { domain, deviceCode, cartUid, cartExternalId, cartSourceExternalId, lineUid, alertUid, onCompleteFunction } = useSnippetContext();
    const { serviceUrls } = useServiceUrls(domain);
    const {alert, alertStatus, alertError} = useGetAlert(serviceUrls, domain, deviceCode, alertUid)

    return (
        <div className='bLoyal-center'>
            <SnippetHeader logoUrl={props.profile.LogoUrl} title={props.profile.Title} message={props.profile.Message} snippetCode={props.profile.Code}/>
            {alert && <p>{`${alert.Message}${alert.Detail ? `: ${alert.Detail}` : ''}`}</p>}
            <SnippetFooter footer={props.profile.Footer} snippetCode={props.profile.Code}/>
        </div>
    )
}