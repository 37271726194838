import React, {useEffect, useState} from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, {Dayjs} from "dayjs";
import {DemoItem} from "@mui/x-date-pickers/internals/demo";

type DateInputProps = {
    id: string,
    label: string,
    value: Dayjs | null,
    handleChange: (field: string, value: Dayjs) => void
    disablePast?: boolean
    required?: boolean
}

export default function DateInput(props: DateInputProps) {
    const [value, setValue] = useState<Dayjs | null>(props.value);

    useEffect(() => {
        if (value !== props.value)
        setValue(props.value)
    }, [props.value]);

    const handleChange = (newValue: Dayjs) => {
        props.handleChange(props.id, newValue)
        setValue(newValue)
    }
    
    return (
        <div className={'bLoyal-center'} style={{width: '100%', maxWidth: '290px'}}>
            <DemoItem label={props.label} >
                {/*// @ts-ignore*/}
                <DatePicker value={value} onChange={handleChange} minDate={dayjs()} disablePast={props.disablePast ? props.disablePast : false} slotProps={{ textField: { required: props.required === true }}} />
            </DemoItem>
        </div>
    )
}