import React, {useEffect, useState} from 'react';
import './App.css';
import {createTheme, ThemeProvider} from '@material-ui/core';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {AuthProvider} from './Utils/UseAuth';
import AuthenticationApp from './AuthenticationApp';
import packageJson from '../package.json';
import useSnippetConfig from './Utils/UseSnippetConfig';
import useServiceUrls from './Utils/UseServiceUrls';
import PaymentApprovedLanding from './PaymentApprovedLanding';
import LoadingSpinner from './LoadingSpinner';
import useDeviceSessionProfile from './Utils/UseDeviceSessionProfile';
import {DeviceSessionProvider} from './Utils/UseDeviceSessionProfileContext';
import {StyleProvider} from "./Utils/UseStyleContext";
import ClubManagement from "./ClubManagement";
import ReferralSnippet from "./Referral/ReferralSnippet";
import {useSnippetContext} from "./Utils/UseSnippetContext";
import Dashboard from "./AccountManagement/Dashboard";
import {SnippetType, WebSnippetProfile} from "./Helpers/Types";
import CustomerSignupApp from "./CustomerSignupApp";
import RecordEngagementSnippet from "./RecordEngagementSnippet";
import ClubPreferenceSnippet from "./ClubPreference/ClubPreferenceSnippet";
import GiftCardBalanceSnippet from "./GiftCardBalance/GiftCardBalanceSnippet";
import PaymentMethodSnippet from "./PaymentMethod/PaymentMethodSnippet";
import EGiftCardSnippet from "./EGiftCard/EGiftCardSnippet";
import AlertSnippet from "./Alert/AlertSnippet";
import AccountManagementSnippet from "./AccountManagement/AccountManagementSnippet";
import CustomerDetailsSnippet from "./CustomerDetails/CustomerDetailsSnippet";
import ClubSignupSnippet from "./ClubSignup/ClubSignupSnippet";
import ClubListSnippet from "./ClubList/ClubListSnippet";
import RegisterCardSnippet from "./RegisterCard/RegisterCardSnippet";
import PurchaseHistorySnippet from "./PurchaseHistory/PurchaseHistorySnippet";
import ShippingAddressSnippet from "./ShippingAddress/ShippingAddressSnippet";
import NewEGiftSnippet from "./EGiftCard/NewEGiftSnippet";
// @ts-ignore
window.appVersion = packageJson.version;

let muiTheme = createTheme({
	palette: {
		primary: {
			main: '#000'
		},
		secondary: {
			main: '#393939'
		}
	}
});

type AppProps = {
	snippetCode: string
}

function App(props: AppProps) {
	const getSnippetComponent = (config: WebSnippetProfile) => {
		switch (config.Type) {
			case SnippetType.Dashboard:
				return <Dashboard snippetConfig={config} />
			case SnippetType.ClubSignup:
				if (config.ClubSignup === null)
					return <ClubManagement snippetConfig={config.LegacyClubSignup} />
				
				return <ClubSignupSnippet snippetProfile={config} />
			case SnippetType.CustomerReferrals:
				return <ReferralSnippet snippetConfig={config} />
			case SnippetType.Signup:
				return <CustomerSignupApp snippetCode={config.Code} />
			case SnippetType.RecordEngagement:
				return <RecordEngagementSnippet snippetConfig={config}/>
			case SnippetType.ClubPreferences:
				return <ClubPreferenceSnippet snippetProfile={config} />
			case SnippetType.GiftCardBalance:
				return <GiftCardBalanceSnippet snippetProfile={config} />
			case SnippetType.PaymentMethods:
				return <PaymentMethodSnippet snippetProfile={config} />
			case SnippetType.EGiftCard:
				// return <EGiftCardSnippet profile={config} />
				return <NewEGiftSnippet profile={config} />
			case SnippetType.Alert:
				return <AlertSnippet profile={config} />
			case SnippetType.AccountManagement:
				return <AccountManagementSnippet snippetProfile={config} />
			case SnippetType.CustomerDetails:
				return <CustomerDetailsSnippet snippetProfile={config} />
			// @ts-ignore
			case 17:
				return <ShippingAddressSnippet giftCustomer={undefined} giftCustomerSessionKey="" canDelete={true} snippetProfile={config} allowShipping={true} showAll={true}/>
			case SnippetType.ClubList:
				return <ClubListSnippet snippetProfile={config} />
			case SnippetType.RegisterCard:
				return <RegisterCardSnippet snippetProfile={config} />
			case SnippetType.PurchaseHistory:
				return <PurchaseHistorySnippet snippetProfile={config} />
			case SnippetType.Login:
			default:
				return null
		}
	}
	
	// @ts-ignore
	const { domain, deviceCode } = useSnippetContext()
	const { serviceUrls, serviceUrlsStatus } = useServiceUrls(domain);
	const { SnippetConfigStatus, SnippetConfig } = useSnippetConfig(serviceUrls, domain, deviceCode, props.snippetCode);
	const { deviceSessionProfileStatus, deviceSessionProfile } = useDeviceSessionProfile(serviceUrls, domain, deviceCode);
	const [snippetComponent, setSnippetComponent] = useState<React.ReactNode|null>(null)
	const [theme, setTheme] = useState(SnippetConfig?.Theme);
	const [isPaymentLanding, setIsPaymentLanding] = useState(false)
	const [messagePosted, setMessagePosted] = useState(false)
	
	useEffect(() => {
		const queryString = require('query-string')
		const parsed = queryString.parse(window.location.search)
		let cardType = parsed.UMcardType;
		if (cardType)
			setIsPaymentLanding(true)
	}, [window.location.search])
	
	useEffect(() => {
		if (SnippetConfig) {
			setSnippetComponent(getSnippetComponent(SnippetConfig))
		}
	}, [SnippetConfig])
	
	useEffect(() => {
		setTheme(SnippetConfig?.Theme);
	}, [SnippetConfig])
	
	if (isPaymentLanding) {
		// if (!messagePosted) {
		// 	const queryString = require('query-string')
		// 	const parsed = queryString.parse(window.location.search)
		// 	let cardType = parsed.UMcardType;
		// 	cardType = mapCreditCardType(cardType);
		// 	const paymentDetails = {
		// 		Token: parsed.UMcardRef,
		// 		CardNumber: parsed.UMmaskedCardNum,
		// 		CardType: cardType
		// 	}
		// 	window.parent.postMessage(JSON.stringify(paymentDetails), `${window.location.protocol}//${window.location.host}`)
		// 	setMessagePosted(true)
		// }
		
		return (
			<div>
				<h1>Saving Payment Method</h1>
			</div>
		)
	}
	
	if (SnippetConfigStatus === 'success' && SnippetConfig === undefined) {
		return (
			<div className='bLoyal-center'>
				<p>{`Snippet with code ${props.snippetCode} was not found. Verify that the code matches an existing snippet profile. If recently created, please try again in a few minutes.`}</p>
			</div>
		)
	}
	
	return (
		<ThemeProvider theme={muiTheme}>
			{/*@ts-ignore*/}
			<BrowserRouter>
				{/*@ts-ignore*/}
				<Switch>
					{/*@ts-ignore*/}
					<Route exact path='/bloyalpaymentapproved' component={PaymentApprovedLanding}/>
					{/*@ts-ignore*/}
					<Route path='*'>
						<StyleProvider theme={theme} setTheme={setTheme}>
							<div className={'bLoyal-snippet-container'}>
								{SnippetConfigStatus === 'success' && SnippetConfig?.Type === SnippetType.Signup &&
									<CustomerSignupApp snippetCode={SnippetConfig?.Code} />
								}
								{SnippetConfigStatus === 'success' && SnippetConfig?.Type === SnippetType.RecordEngagement &&
									<RecordEngagementSnippet snippetConfig={SnippetConfig} />
								}
								{SnippetConfigStatus === 'success' && SnippetConfig?.Type === SnippetType.Alert &&
									<AlertSnippet profile={SnippetConfig}/>
								}
								<AuthProvider>
									<>
										{(serviceUrlsStatus === 'loading' || SnippetConfigStatus === 'loading' || deviceSessionProfileStatus === 'loading') &&
										<LoadingSpinner/>
										}
									{SnippetConfigStatus === 'success' && SnippetConfig?.Type !== SnippetType.Signup && SnippetConfig?.Type !== SnippetType.RecordEngagement && SnippetConfig?.Type !== SnippetType.Alert &&
										<>
											{SnippetConfig && deviceSessionProfile &&
											<DeviceSessionProvider profile={deviceSessionProfile}>
												<AuthenticationApp snippetConfig={SnippetConfig}
																   authenticatedComponent={snippetComponent}/>
											</DeviceSessionProvider>
											}
										</>
										}
										{(SnippetConfigStatus === 'error' || deviceSessionProfileStatus === 'error' || serviceUrlsStatus === 'error') &&
										<p className='bLoyal-error-text'>We could not complete the request.</p>
										}
									</>
								</AuthProvider>
							</div>
						</StyleProvider>
					</Route>
				</Switch>
			</BrowserRouter>
		</ThemeProvider>
	);
}

export default App;
