import {queryCache, useMutation} from 'react-query';
import {client} from './ApiClient';

const deleteShippingAddress = ({urls, session, domain, deviceCode, addressUid}: {urls: any; session: string; domain: string; deviceCode: string; addressUid: string}) => {
	return client(urls.LoyaltyEngineApiUrl, `api/v4/${domain}/${deviceCode}/sessions/${session}/ShippingAddresses/${addressUid}`, {
		customConfig: {
			method: 'DELETE'
		}
	});
}

export default function useDeleteShippingAddress() {
	// @ts-ignore
	return useMutation(deleteShippingAddress, {
		onMutate: ({addressUid}: {urls: any; session: string; domain: string; deviceCode: string; addressUid: string}) => {
			// @ts-ignore
			const previousAddresses = queryCache.getQueryData('ShippingAddresses')
			queryCache.setQueryData('ShippingAddresses', (old: any) => {
                const updated = old.data.filter((curr: any) => curr.Uid !== addressUid)
                return {...old, data: updated }
            })
			return () => queryCache.setQueryData('ShippingAddresses', previousAddresses);
		},
		onSuccess: () => {
			queryCache.invalidateQueries('ShippingAddresses')
		},
		onError: (err, data, rollback: Function) => rollback()
	})
}