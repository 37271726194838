import {ICountry, IInputConfig, ISelectOption, PurchaseHistorySnippetProfile} from "./Types";
import React, {Fragment} from "react";
import TextInput from "../TextInput";
import {formatDate, getDisplayValue} from "./Functions";
import PasswordField from "../PasswordField";
import EmailInput from "../EmailInput";
import SelectInput from "../SelectInput";

export const mapInputField = (field: IInputConfig, index: number, state: any, stateOptions: {text: string, value: string}[],  countryOptions: { text: string, value: string }[], countries: Array<ICountry>, states: { text: string, value: string }[], handleChange: (field: string, value: string) => void, mapCustomField?: Function, customFields?: Array<any>) => {
    if (!field.IsIncluded && !field.IsRequired)
        return;

    let fieldDefinition = customFields?.find((cf: any) => cf.Name === field.FieldName);

    if (fieldDefinition) {
        return mapCustomField?.(field, fieldDefinition, index);
    }
    
    if (field.FieldName === 'Zip')
        field.FieldName = 'PostalCode'
    // @ts-ignore
    let value = state[field.FieldName];
    let inputField: any;
    const label = field.Label ? field.Label : getDisplayValue(field.FieldName);
    switch (field.FieldName) {
        case 'EmailAddress':
            inputField = <EmailInput required={field.IsRequired} id={field.FieldName} value={value} label={label} handleChange={handleChange}/>;
            break;
        case 'Country':
            return (
                <Fragment key={index}>
                    <SelectInput current={value} disabled={false} fieldName={field.FieldName} handleChange={handleChange} options={countryOptions}
                                 label={label} required={field.IsRequired}/>
                </Fragment>
            );
        case 'State':
            if (state.Country === '') {
                return;
            }
            const selected: ICountry | undefined = countries?.find((curr: ICountry) => curr.Code === state.Country);
            if (selected?.HasStates)
                return (
                    <Fragment key={index}>
                        <SelectInput current={value} disabled={false} fieldName={field.FieldName} handleChange={handleChange}
                                     options={stateOptions} label={label} required={field.IsRequired}/>
                    </Fragment>
                );
            else
                inputField = <TextInput required={field.IsRequired} id={field.FieldName} value={value} label={label} handleChange={handleChange}/>;

            break;
        case 'BirthDate':
            if (value !== undefined && value.includes('T')) {
                let date = new Date(value);
                let options = {year: "numeric", month: "2-digit", day: "2-digit"}
                // @ts-ignore
                value = date.toLocaleDateString('en-US', options);
            }
            return (
                <Fragment key={index}>

                    <TextInput id={field.FieldName} value={value ?? ''} label={label} required={field.IsRequired}
                               handleChange={handleChange}/>
                    <br/>
                </Fragment>
            );
        default:
            inputField = <TextInput required={field.IsRequired} id={field.FieldName} value={value} label={label} handleChange={handleChange}/>;
    }
    return (
        <Fragment key={index}>
            {inputField}
            <br/>
        </Fragment>
    );
};

export const getCurrencyString = (value: any) => {
    const num = parseFloat(value)
    if (isNaN(num))
        return '$0.00'
        
    return `${num < 0 ? '(' : ''}$${Math.abs(num).toFixed(2)}${num < 0 ? ')' : ''}`
}

const getOrderNumber = (salesTrans: any) => {
    if (salesTrans.OrderNumber !== null)
        return salesTrans.OrderNumber
    
    if (salesTrans.ReferenceNumber !== null)
        return salesTrans.ReferenceNumber
    
    if (salesTrans.SourceExternalId !== null)
        return salesTrans.SourceExternalId
    
    if (salesTrans.Number !== null)
        return salesTrans.Number
    
    return "Not Found"
}

const getProductsPrice = (salesTrans: any) => {
    if (!salesTrans?.Lines)
        return 0
    
    let cost = 0
    salesTrans.Lines.forEach((l: any) => cost += l.Price * l.Quantity)
    return cost
}

const getProductsDiscount = (salesTrans: any) => {
    if (!salesTrans?.Lines)
        return 0

    let discount = 0
    salesTrans.Lines.forEach((l: any) => {
        discount += l.Quantity * l.Discount
    })
    discount += salesTrans.OrderDiscount?.Amount ?? 0
    return discount * -1
}

const getNetProductsPrice = (salesTrans: any) => {
    if (!salesTrans?.Lines)
        return 0
    
    return getProductsPrice(salesTrans) - (getProductsDiscount(salesTrans) * -1)
}

const getNetShipping = (salesTrans: any) => {
    if (!salesTrans?.Shipments)
        return 0

    let cost = 0
    salesTrans.Shipments.forEach((s: any) => cost += s.Charge - s.Discount)
    return cost
}

export const getOrderColumns = (profile?: PurchaseHistorySnippetProfile) => {
    if (profile === undefined || profile === null)
        return []
    
    var columns: Array<any> = []
    profile.PurchaseColumns.forEach(c => {
        switch (c.Name) {
            case 'Created':
                columns.push({
                    Header: c.Label ? c.Label : getDisplayValue(c.Name),
                    accessor: 'Created',
                    Cell: (row: any) => <p className='bloyal-table-cell bLoyal-right-align'>{`${formatDate(row.row.original.Created)}`}</p>
                })
                break;
            case 'Order #':
                columns.push({
                    Header: c.Label ? c.Label : getDisplayValue(c.Name),
                    accessor: 'OrderNumber',
                    Cell: (row: any) => <p className='bloyal-table-cell bLoyal-right-align'>{`${getOrderNumber(row.row.original)}`}</p>
                })
                break;
            case 'Store':
                columns.push({
                    Header: c.Label ? c.Label : getDisplayValue(c.Name),
                    accessor: 'StoreName',
                    Cell: (row: any) => <p className='bloyal-table-cell bLoyal-right-align'>{`${row.row.original.StoreName !== null ? row.row.original.StoreName : 'Not found'}`}</p>
                })
                break;
            case 'Product Discount':
                columns.push({
                    Header: c.Label ? c.Label : getDisplayValue(c.Name),
                    accessor: 'TotalDiscount',
                    Cell: (row: any) => <p className='bloyal-table-cell bLoyal-right-align'>{`${getCurrencyString(getProductsDiscount(row.row.original))}`}</p>
                })
                break;
            case 'Product Price':
                columns.push({
                    Header: c.Label ? c.Label : getDisplayValue(c.Name),
                    accessor: 'TotalProducts',
                    Cell: (row: any) => <p className='bloyal-table-cell bLoyal-right-align'>{`${getCurrencyString(getProductsPrice(row.row.original))}`}</p>
                })
                break;
            case 'Net Product Price':
                columns.push({
                    Header: c.Label ? c.Label : getDisplayValue(c.Name),
                    accessor: 'NetProducts',
                    Cell: (row: any) => <p className='bloyal-table-cell bLoyal-right-align'>{`${getCurrencyString(getNetProductsPrice(row.row.original))}`}</p>
                })
                break;
            case 'Net Shipping':
                columns.push({
                    Header: c.Label ? c.Label : getDisplayValue(c.Name),
                    accessor: 'ShippingCharge',
                    Cell: (row: any) => <p className='bloyal-table-cell bLoyal-right-align'>{`${getCurrencyString(getNetShipping(row.row.original))}`}</p>
                })
                break;
            case 'Total Taxes':
                columns.push({
                    Header: c.Label ? c.Label : getDisplayValue(c.Name),
                    accessor: 'TotalTax',
                    Cell: (row: any) => <p className='bloyal-table-cell bLoyal-right-align'>{`${getCurrencyString(row.row.original.TotalTax)}`}</p>
                })
                break;
            case 'Total Amount':
                columns.push({
                    Header: c.Label ? c.Label : getDisplayValue(c.Name),
                    accessor: 'TotalPayment',
                    Cell: (row: any) => <p className='bloyal-table-cell bLoyal-right-align'>{`${getCurrencyString(row.row.original.TotalPayment)}`}</p>
                })
                break;
            default:
                columns.push({
                    Header: c.Label ? c.Label : getDisplayValue(c.Name),
                    accessor: c.Name,
                    Cell: (row: any) => <p className='bloyal-table-cell bLoyal-right-align'>{`${row.row.original[c.Name]}`}</p>
                })
        }
    })
    
    return columns
}