import React, {useEffect, useState} from 'react';
import {ISyntheticEvent} from "./Helpers/Types";

type CheckboxInputProps = {
    id: string
    value: boolean
    label: string
    handleChange: (value: boolean, field: string) => void
}

export default function CheckboxInput(props: CheckboxInputProps) {
    const [value, setValue] = useState(props.value)
    
    const handleChange = (e: ISyntheticEvent) => {
        e.persist();
        setValue(e.target.checked)
        props.handleChange(e.target.checked, props.id)
    }
    
    return (
        <>
            <input className='bLoyal-basic-checkbox' type='checkbox' id={props.id} onChange={handleChange} checked={value}/>
            <label htmlFor={props.id}>{props.label}</label>
            <br />
        </>  
    )
}