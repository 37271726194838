import React from 'react';
import {useSnippetContext} from "./Utils/UseSnippetContext";
import {useStyleContext} from "./Utils/UseStyleContext";
import useServiceUrls from "./Utils/UseServiceUrls";
import useDeviceSessionProfile from "./Utils/UseDeviceSessionProfile";
import useSnippetConfig from "./Utils/UseSnippetConfig";
import LoadingSpinner from "./LoadingSpinner";
import LegacySignupSnippet from "./LegacySignupSnippet";
import SignupSnippet from "./SignupSnippet";

type SignupWrapperProps = {
    snippetCode: string
    setShowSignup: Function
}

export default function SignupWrapper(props: SignupWrapperProps) {
    // @ts-ignore
    const { domain, deviceCode } = useSnippetContext();
    const { serviceUrls } = useServiceUrls(domain);
    const { SnippetConfig, SnippetConfigStatus } = useSnippetConfig(serviceUrls, domain, deviceCode, props.snippetCode);
    
    return (
        <>
            {SnippetConfigStatus === 'loading' && <LoadingSpinner />}
            {SnippetConfigStatus === 'error' && <p className='bLoyal-error-text'>Unable to load snippet profile {props.snippetCode}</p>}
            {SnippetConfig &&
                <>
                    {SnippetConfig.Signup && <LegacySignupSnippet snippetCode={SnippetConfig.Code} isStandalone={false} setShowSignup={props.setShowSignup} />}
                    {SnippetConfig.CustomerSignup && <SignupSnippet snippetCode={SnippetConfig.Code} isStandalone={false} setShowSignup={props.setShowSignup} />}
                    {!SnippetConfig.Signup && !SnippetConfig.CustomerSignup && <p className='bLoyal-error-text'>Snippet is not a signup snippet.</p>}
                </>
            }
        </>
    )
}