import React, {useState} from 'react';
import {ISyntheticEvent, SnippetTheme} from './Helpers/Types';
import {TextField} from '@material-ui/core';
import {useStyleContext} from "./Utils/UseStyleContext";

type PasswordFieldProps = {
	id: string,
	value: string,
	label: string,
	required: boolean
	getValue?: (value: string) => void
	handleChange?: (fieldName: string, value: string) => void
}

export default function PasswordField(props: PasswordFieldProps) {
	const [value, setValue] = useState(props.value);
	// @ts-ignore
	const {theme} = useStyleContext();

	const handleChange = (event: ISyntheticEvent) => {
		event.preventDefault();
		setValue(event.target.value);
		props.getValue?.(event.target.value);
		props.handleChange?.(props.id, event.target.value)
	};
	
	switch (theme) {
		case SnippetTheme.MaterialUI:
			return (
				<TextField
					id={props.id}
					label={props.label + (props.required ? "* " : " ")}
					value={value}
					required={props.required}
					type='password'
					onChange={handleChange}
				/>
			);
		default:
			return (
				<label>{props.label + (props.required ? "* " : " ")}
					<input style={{width: '100%'}} type="password" id={props.id} onChange={handleChange} required={props.required} value={value}/>
				</label>
			);
	}
}