import {useQuery} from 'react-query';
import {client} from './ApiClient';
import {mapServiceUrls} from '../Helpers/Functions';

const getAlert = (key: any, urls: any, domain: string, deviceCode: string, alertUid: string) => {
	return client(urls.LoyaltyEngineApiUrl, `api/v4/${domain}/${deviceCode}/alerts?uid=${alertUid}`);
}

export default function useGetAlert(urls: any, domain: string, deviceCode: string, alertUid: string) {
	const {status, data, error, refetch } = useQuery(['Alert', alertUid], (key) => getAlert(key, mapServiceUrls(urls), domain, deviceCode, alertUid), { enabled: urls, staleTime: 1000*60*60 });
	const alertStatus = status;
	const alert = data ? data.data : undefined;
	const alertError = error;
	return {alertStatus, alert, alertError, refetch};
}
