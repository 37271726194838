import {queryCache, useMutation} from 'react-query';
import {client} from './ApiClient';
import {PaymentType} from '../Helpers/Types';

const savePaymentMethod = ({urls, session, domain, deviceCode, paymentType, title, creditCard, giftCard, isPrimary, paymentMethodUid}: { urls: any; session: string; domain: string; deviceCode: string; paymentType: PaymentType; title: string; creditCard: any; giftCard: any; isPrimary: boolean; paymentMethodUid: string | null }) => {
    return client(urls.LoyaltyEngineApiUrl, `api/v4/${domain}/${deviceCode}/sessions/${session}/paymentmethods/commands/save`, {
        body: {
            CreditCard: creditCard,
            GiftCard: giftCard,
            IsPrimary: isPrimary,
            PaymentType: paymentType,
            Title: title,
            PaymentMethodUid: paymentMethodUid
        }
    });
}

export default function useSavePaymentMethod() {
    // @ts-ignore
    return useMutation(savePaymentMethod, {
        onMutate: ({ paymentType, title, creditCard, giftCard, isPrimary, paymentMethodUid}: { urls: any; session: string; domain: string; deviceCode: string; paymentType: PaymentType; title: string; creditCard: any; giftCard: any; isPrimary: boolean; paymentMethodUid: string | null; }) => {
            // @ts-ignore
            const previousMethods = queryCache.getQueryData('PaymentMethods')
            queryCache.setQueryData('PaymentMethods', (old: any) => {
                let isNew = true;
                const updated = old.data.map((curr: any) => {
                    if (curr.Uid === paymentMethodUid) {
                        isNew = false;
                        return {
                            CreditCard: creditCard,
                            GiftCard: giftCard ? {
                                CardMask: giftCard.CardNumber
                            } : undefined,
                            IsPrimary: isPrimary,
                            PaymentType: paymentType,
                            Title: title,
                            Uid: paymentMethodUid
                        }
                    }
                    return curr
                })
                if (isNew)
                    return {
                        ...old, data: [...updated, {
                            CreditCard: creditCard,
                            GiftCard: giftCard ? {
                                CardMask: giftCard.CardNumber
                            } : undefined,
                            IsPrimary: isPrimary,
                            PaymentType: paymentType,
                            Title: title,
                            Uid: paymentMethodUid
                        }]
                    }

                return {...old, data: updated}
            })

            return () => queryCache.setQueryData('PaymentMethods', previousMethods);
        },
        onSuccess: () => {
            queryCache.invalidateQueries('PaymentMethods')
        },
        onError: (err, data, rollback: Function) => rollback()
    })
}