

import { useMutation} from 'react-query';
import {client} from './ApiClient';

const resetPassword = ({urls, domain, deviceCode, email}: {urls: any; domain: string; deviceCode: string; email: string;}) => {
    return client(urls.LoyaltyEngineApiUrl, `api/v4/${domain}/${deviceCode}/snippets/customers/commands/resetpassword`, {
        body: {
            EmailAddress: email,
        }
    });
}

export default function  useResetPassword() {
    // @ts-ignore
    return useMutation(resetPassword)
}