import React, {useEffect, useState} from 'react';
import useServiceUrls from "./Utils/UseServiceUrls";
import useDeviceSessionProfile from "./Utils/UseDeviceSessionProfile";
import LoadingSpinner from "./LoadingSpinner";
import {DeviceSessionProvider} from "./Utils/UseDeviceSessionProfileContext";
import SignupSnippet from "./SignupSnippet";
import {useSnippetContext} from "./Utils/UseSnippetContext";
import {AuthProvider} from "./Utils/UseAuth";
import {LoginType, SnippetTheme} from "./Helpers/Types";
import {StyleProvider} from "./Utils/UseStyleContext";
import useSnippetConfig from "./Utils/UseSnippetConfig";
import AuthenticationApp from "./AuthenticationApp";
import LegacySignupSnippet from "./LegacySignupSnippet";

type CustomerSignupAppProps = {
    snippetCode: string,
}

export default function CustomerSignupApp(props: CustomerSignupAppProps) {
    // @ts-ignore
    const {domain, deviceCode} = useSnippetContext();
    const { serviceUrls, serviceUrlsStatus } = useServiceUrls(domain);
    const { deviceSessionProfileStatus, deviceSessionProfile } = useDeviceSessionProfile(serviceUrls, domain, deviceCode);
    const [theme, setTheme] = useState(SnippetTheme.Default);
    const { SnippetConfig: config, SnippetConfigStatus: status, SnippetConfigError: error } = useSnippetConfig(serviceUrls, domain, deviceCode, props.snippetCode);

    return (
        <AuthProvider>
            <StyleProvider theme={theme} setTheme={setTheme}>
                {(serviceUrlsStatus === 'loading' || deviceSessionProfileStatus === 'loading' || status === 'loading') &&
                <LoadingSpinner/>
                }
                {deviceSessionProfile && config &&
                    <DeviceSessionProvider profile={deviceSessionProfile}>
                        {config.Signup &&
                        <LegacySignupSnippet snippetCode={config.Code} isStandalone={true}/>
                        }
                        {config.LoginType === LoginType.NotRequired && !config.Signup &&
                            <SignupSnippet  snippetCode={props.snippetCode} isStandalone={true}/>
                        }
                        {config.LoginType !== LoginType.NotRequired && !config.Signup &&
                            <AuthenticationApp snippetConfig={config} authenticatedComponent={<SignupSnippet snippetCode={props.snippetCode} isStandalone={true} requiresLogin={true}/>} />
                        }
                    </DeviceSessionProvider>
                }
                {(deviceSessionProfileStatus === 'error' || serviceUrlsStatus === 'error') &&
                <p className='bLoyal-error-text'>We could not complete the request.</p>
                }
            </StyleProvider>
        </AuthProvider>
    )
}