import {Action} from 'typesafe-actions';
import {ICustomer} from './Helpers/Types';

export enum AddressActionTypes {
	UpdateField = 'Address/UpdateField',
	CopyAddress = 'Address/CopyAddress',
}

export interface IUpdateFieldAction extends Action<AddressActionTypes.UpdateField> {
	fieldName: string,
	value: string,
}

export interface ICopyAddress extends Action<AddressActionTypes.CopyAddress> {
	customer: ICustomer,
}

export type AddressAction =
	| IUpdateFieldAction
	| ICopyAddress

export const updateFieldAction = (fieldName: string, value: string) => {
	return {
		type: AddressActionTypes.UpdateField,
		fieldName,
		value
	};
};

export const copyAddress = (customer: ICustomer) => {
	return {
		type: AddressActionTypes.CopyAddress,
		customer,
	}
}