import {Action} from 'typesafe-actions';

export enum SignupActionTypes {
	UpdateField = 'Signup/UpdateField',
	UpdateCustomField = 'Signup/UpdateCustomField',
	UpdateSubscriberGroupsField = 'Signup/UpdateSubscriberGroupsField'
}

export interface IUpdateFieldAction extends Action<SignupActionTypes.UpdateField> {
	fieldName: string,
	value: string,
}

export interface IUpdateCustomFieldAction extends Action<SignupActionTypes.UpdateCustomField> {
	fieldName: string, value: Array<{code: string, value: string}>
}

export interface IUpdateSubscriberGroupsFieldAction extends Action<SignupActionTypes.UpdateSubscriberGroupsField> {
	value: Array<string>
}

export type SignupAction =
	| IUpdateFieldAction
| IUpdateCustomFieldAction
| IUpdateSubscriberGroupsFieldAction

export const updateSignupFieldAction = (fieldName: string, value: string) => {
	return {
		type: SignupActionTypes.UpdateField,
		fieldName,
		value,
	}
}

export const updateCustomSignupFieldAction = (fieldName: string, value: Array<{code: string, value: string}>) => {
	return {
		type: SignupActionTypes.UpdateCustomField,
		fieldName,
		value,
	}
}

export const updateSubscriberGroupsFieldAction = (value: Array<string>) => {
	return {
		type: SignupActionTypes.UpdateSubscriberGroupsField,
		value,
	}
}