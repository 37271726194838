import { Grid } from '@mui/material';
import React, {useEffect, useState} from 'react';
import useWindowSize from "../Hooks/UseWindowSize";
import {EGiftCard, EGiftCardSnippetProfile} from "../Helpers/Types";

type SelectDesignProps = {
    handleChange: (field: string, value: any) => void
    state: EGiftCard
    config?: EGiftCardSnippetProfile
}

const style = {
    // width: '100px',  // Set the width of the thumbnail
    // height: '100px', // Set the height of the thumbnail
    borderRadius: '8px', // Slightly round the corners
    height: '75px',
    cursor: 'pointer',
};

const previewStyle = {
    width: '80%',  // Set the width of the thumbnail
    maxWidth: '500px',
    borderRadius: '8px' // Slightly round the corners
};

export default function DesignSelection(props: SelectDesignProps) {
    const {width} = useWindowSize();
    const [showMore, setShowMore] = useState(false);
    const [showAll, setShowAll] = useState(false);
    const [selected, setSelected] = useState(props.state.ImageUrl);

    useEffect(() => {
        setSelected(props.state.ImageUrl)
    }, [props.state.ImageUrl]);

    useEffect(() => {
        if (!width)
            return;
        
        if (width < 450 && (props.config?.ImageOptions.length ?? 0) > 12) 
            setShowMore(true)
        else if (width < 375 && (props.config?.ImageOptions.length ?? 0) > 9)
            setShowMore(true)
        else if (showMore)
            setShowMore(false)
    }, [showMore, width]);
    
    const setSelectedImage = (url: string) => {
        setSelected(url)
        props.handleChange('ImageUrl', url)
    }
    
    const mapImageOption = (imageUrl: string, index: number) => {
        if (!showAll && index > 11 && showMore)
            return <></>
        
        return (
            <Grid item xs={6} sm={3} lg={2} key={index}>
                <img src={imageUrl} alt={`EGift Option ${index}`} className={'bLoyal-image-thumbnail'} onClick={() => setSelectedImage(imageUrl)} />
            </Grid>
        )
    }
    
    return (
        <>
            <h3>{props.config?.DesignSectionLabel}</h3>
            <Grid container columnSpacing={2} rowSpacing={1}>
                {props.config?.ImageOptions.map(mapImageOption)}
            </Grid>
            {showMore && <button className='bl-snippet-button' onClick={() => setShowAll(!showAll)} >{showAll ? 'Show less' : 'Show more'}</button>}
            <br />
            {selected && <div className={'bLoyal-center'}><img src={selected} alt={'Selected gift image'} style={previewStyle}/></div>}
        </>
    )
}