import React, { Fragment } from 'react';

type ListProps = {
    data: Array<any>
    displayFunction?: Function
}

export default function List(props: ListProps) {
    return (
        <div >
            {props.data.map((item, index) => props.displayFunction ? props.displayFunction(item, index) : <Fragment key={index}>{item}</Fragment>)}
        </div>
    )
}