import React, {SyntheticEvent} from "react";
import {Dialog, DialogContent, DialogTitle, DialogActions} from "@mui/material";

type ConfirmLoseChangesDialogProps = {
    cancel: Function,
    continue: Function,
    open: boolean,
    quantity: number
    isAddToCart?: boolean,
}

export default function ConfirmLoseChangesDialog(props: ConfirmLoseChangesDialogProps) {

    const close = (e: SyntheticEvent) => {
        e.preventDefault();
        props.cancel();
    }

    const confirm = (e: SyntheticEvent) => {
        e.preventDefault()
        props.continue()
    }

    return (
        <Dialog className={'bLoyal-dialog'} maxWidth='sm' fullWidth={true} open={props.open}>
            <DialogTitle>{props.quantity === 1 || !props.isAddToCart ? 'Gift card details not completed' : 'Confirm gift card order'}</DialogTitle>
            <DialogContent className='dialog-body'>
                {`${props.quantity === 1 || !props.isAddToCart ? 'You have not entered all the required information for this gift card. Please add the required information and try again' : 'The details for the current gift card are not complete. If you have added other recipients and want to proceed to purchase anyway, please confirm. If you want to go back or continue editing the current gift card, please click cancel.'}`}
            </DialogContent>
            <DialogActions>
                <button style={{float: 'left'}} onClick={close}>
                    {props.quantity === 1 || !props.isAddToCart ? 'Ok' : 'Cancel'}
                </button>
                {props.quantity !== 1 && props.isAddToCart && <button onClick={confirm}>Confirm</button>}
            </DialogActions>
        </Dialog>
    )
}