import {useQuery} from 'react-query';
import {client} from './ApiClient';
import {mapServiceUrls} from '../Helpers/Functions';

const getCustomerSalesTransactions = (key: any, urls: any, domain: string, deviceCode: string, sessionKey: string, recordNumber: string, pageSize: string) => {
	return client(mapServiceUrls(urls).WebSnippetsApiUrl, `api/v4/${domain}/${deviceCode}/Customers/SalesTransactions?sessionKey=${sessionKey}&startingRecordNumber=${recordNumber}&pageSize=${pageSize}`);
}

export default function useCustomerSalesTransactions(urls: any, domain: string, deviceCode: string, sessionKey: string, recordNumber: string, pageSize: string) {
	const {status, data, error, isFetching } = useQuery(['SalesTrans', sessionKey, recordNumber, pageSize], (key) => {
		return getCustomerSalesTransactions(key, mapServiceUrls(urls), domain, deviceCode, sessionKey, recordNumber, pageSize)
		
	}, { enabled: urls, staleTime: 1000*60*5 });
	const salesTransStatus = status;
	// @ts-ignore
	const salesTrans = data ? data.data : undefined;
	const salesTransError = error;
	return {salesTransStatus, salesTrans, salesTransError};
}
