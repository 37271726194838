import {useQuery} from 'react-query';
import {client} from './ApiClient';
import {mapServiceUrls} from '../Helpers/Functions';

const getCart = (key: any, urls: any, domain: string, deviceCode: string, cartUid: string, cartExternalId: string, cartSourceExternalId: string) => {
	return client(urls.LoyaltyEngineApiUrl, `api/v4/${domain}/${deviceCode}/carts?cartUid=${cartUid}&cartExternalId=${cartExternalId}&cartSourceExternalId=${cartSourceExternalId}`);
}

export default function useGetCart(urls: any, domain: string, deviceCode: string, cartUid: string, cartExternalId: string, cartSourceExternalId: string, enabled: boolean) {
	const {status, data, error, refetch } = useQuery(['Cart', cartUid, cartExternalId, cartSourceExternalId], (key) => getCart(key, mapServiceUrls(urls), domain, deviceCode, cartUid, cartExternalId, cartSourceExternalId), { enabled: urls && enabled, staleTime: 1000*60*60 });
	const cartStatus = status;
	const cart = data ? data.data : undefined;
	const cartError = error;
	return {cartStatus, cart, cartError, refetch};
}
