import React, {Fragment, useEffect, useMemo, useReducer, useState} from 'react';
import {
	ICountry,
	ICustomField,
	ICustomFieldType,
	IQuickSignupField,
	ISelectOption,
	ISignupState,
	IState,
	ISyntheticEvent,
	SnippetType
} from './Helpers/Types';
import useSnippetConfig from './Utils/UseSnippetConfig';
import {useSnippetContext} from './Utils/UseSnippetContext';
import useServiceUrls from './Utils/UseServiceUrls';
import TextInput from './TextInput';
import useCountries from './Utils/UseCountries';
import useStates from './Utils/UseStates';
import {SignupAction, SignupActionTypes, updateCustomSignupFieldAction, updateSignupFieldAction} from './SignupActions';
import EmailInput from './EmailInput';
import {doesCountryHaveStates, formatDate, mapServiceUrls} from './Helpers/Functions';
import UseSignupCustomer from './Utils/UseSignupCustomer';
import {useDeviceSessionContext} from './Utils/UseDeviceSessionProfileContext';
import NumberInput from './NumberInput';
import SelectInput from './SelectInput';
import { v4 as uuid } from 'uuid'

export const giftRecipientState: ISignupState = {
	SubscriberGroups: [],
	BirthdayMonth1: '',
	BirthdayMonth2: '',
	FirstName2: '',
	LastName2: '',
	LoyaltyCardNumber: '',
	Password: '',
	FirstName: '',
	LastName: '',
	CompanyName: '',
	Address1: '',
	Address2: '',
	City: '',
	State: '',
	PostalCode: '',
	Country: '',
	EmailAddress: '',
	Phone1: '',
	MobilePhone: '',
	BirthDate: '',
	CustomFields: []
};

export const giftRecipientReducer = (state = giftRecipientState, action: SignupAction): ISignupState => {
	switch (action.type) {
		case SignupActionTypes.UpdateCustomField:
			if (state.CustomFields.filter(field => field.Key === action.fieldName).length > 0){
				let newArray = state.CustomFields.map(field => {
					if (field.Key === action.fieldName)
						return { Key: action.fieldName, Value: action.value }
					return field;
				})
				return {...state, CustomFields: newArray}
			}
			let fields = state.CustomFields
			fields.push({Key: action.fieldName, Value: action.value})
			return {...state, CustomFields: fields}
		case SignupActionTypes.UpdateField:
			if (action.fieldName === 'Zip')
				return {
				...state,
					PostalCode: action.value
				}
			
			return {
				...state,
				[action.fieldName]: action.value
			};
		default:
			return state;
	}
};

type GiftMembershipRecipientProps = {
	snippetCode: string,
	customFieldDefinitions: Array<any>
	nextStep: (e: ISyntheticEvent) => void
	prevStep: (e: ISyntheticEvent) => void
	updateField: (field: string, value: any) => void
	customFields: object
	updateCustomFields: (value: object) => void
}

export default function GiftMembershipRecipient(props: GiftMembershipRecipientProps) {
	const mapCustomFieldValues = (customFields: object) => {
		if (!customFields)
			return [];

		let values = [];
		for (const field in customFields) {
			// @ts-ignore
			values.push({Key: field, Value: customFields[field]})
		}
		return values;
	}
	// @ts-ignore
	const {profile} = useDeviceSessionContext();
	let existingGift = JSON.parse(sessionStorage.getItem('blGiftRecipient') ?? '{"Customer": "Empty"}')
	let existingUid = '';
	let initialState: ISignupState = {...giftRecipientState, Country: profile.DefaultCountry, CustomFields: mapCustomFieldValues(props.customFields)}
	if (existingGift.Customer !== "Empty") {
		initialState.Address1 = existingGift.Address.Address1;
		initialState.Address2 = existingGift.Address.Address2;
		initialState.City = existingGift.Address.City;
		initialState.State = existingGift.Address.State;
		initialState.PostalCode = existingGift.Address.PostalCode;
		initialState.Country = existingGift.Address.Country;
		initialState.FirstName = existingGift.FirstName;
		initialState.LastName = existingGift.LastName;
		initialState.FirstName2 = existingGift.FirstName2;
		initialState.LastName2 = existingGift.LastName2;
		initialState.EmailAddress = existingGift.EmailAddress;
		initialState.MobilePhone = existingGift.MobilePhone;
		initialState.BirthDate = formatDate(existingGift.BirthDate) ?? '';
		initialState.Phone1 = existingGift.Phone1;
		initialState.CompanyName = existingGift.CompanyName;
		initialState.BirthdayMonth1 = existingGift.BirthdayMonth1;
		initialState.BirthdayMonth2 = existingGift.BirthdayMonth2;
		initialState.LoyaltyCardNumber = existingGift.LoyaltyCardNumber;
		initialState.CustomFields = mapCustomFieldValues(existingGift.CustomFields);
		existingUid = existingGift.Uid;
		
	}
	const [state, dispatch] = useReducer(giftRecipientReducer, initialState);
	// @ts-ignore
	const { domain, deviceCode } = useSnippetContext();
	const { serviceUrls } = useServiceUrls(domain);
	const { SnippetConfig: config } = useSnippetConfig(serviceUrls, domain, deviceCode, props.snippetCode);
	const [signupCustomer, { data, isSuccess, isError, error, reset }] = UseSignupCustomer();

	const { countries, countriesStatus } = useCountries(serviceUrls, domain, deviceCode);
	const { states } = useStates(serviceUrls, domain, deviceCode, state.Country, countries && doesCountryHaveStates(countries, state.Country));

	const [countryOptions, setCountryOptions] = useState<Array<ISelectOption>>([]);
	const [stateOptions, setStateOptions] = useState<Array<ISelectOption>>([]);
	
	const SnippetConfig = useMemo(() => config?.Signup, [config])

	useEffect(() => {
		if (!countries)
			return;

		const countryList = countries.map((country: ICountry) => {
			return {
				value: country.Code,
				text: country.Name
			};
		});

		setCountryOptions(countryList);
	}, [countries]);

	useEffect(() => {
		if (!states)
			return;

		const stateList = states.map((state: IState) => {
			return {
				value: state.Code,
				text: state.Name
			};
		});

		setStateOptions(stateList);
	}, [states]);

	useEffect(() => {
		if (isSuccess) {
			props.updateField('GiftRecipient', data.data.Customer);
			props.updateField('GiftRecipientSessionKey', data.data.SessionKey);
			props.nextStep({target: {}, preventDefault: () => {}, persist: () => {}})
		}
	}, [isSuccess, data]);

	const submit = (event: ISyntheticEvent) => {
		const mapCustomFieldsToObject = (fields: Array<any>) => {
			let fieldsObject: object = {}
			fields.forEach((field: any) => {
				// @ts-ignore
				fieldsObject[field.Key] = field.Value
			})
			return fieldsObject;
		}

		props.updateCustomFields(mapCustomFieldsToObject(state.CustomFields))
		event.preventDefault();
		if (existingUid !== '') {
			props.nextStep({
				target: {}, preventDefault: () => {}, persist: () => {}
			})
			return
		}
		signupCustomer({ urls: mapServiceUrls(serviceUrls), deviceCode, signupState: state, domain, session: '' });
	};

	const handleChange = (fieldName: string, value: string) => {
		// @ts-ignore
		dispatch(updateSignupFieldAction(fieldName, value));
	};

	const handleCustomFieldChange = (fieldName: string, value: string) => {
		// @ts-ignore
		dispatch(updateCustomSignupFieldAction(fieldName, value))
	}

	const mapCustomField = (field: ICustomField, index: number) => {
		if (!props.customFieldDefinitions.some(d => d.Code === field.Code))
			return null;


		const mapListOptions = (options: Array<any>) => {
			return options.map((option: any) => {
				return {
					text: option.Name,
					value: option.Value,
				}
			})
		}
		
		let value = state.CustomFields.find((curr: any) => curr.Key === field.Code)
		switch (field.FieldType) {

			case ICustomFieldType.Int:
			case ICustomFieldType.Decimal:
				return (
					<Fragment key={index}>
						<NumberInput key={index} id={field.Code} value={value?.Value ?? ''} label={field.Name}
						             handleChange={handleCustomFieldChange}/>
						<br/>
					</Fragment>
				)
			case ICustomFieldType.ValidatedList:
				return (
					<Fragment key={index}>
						<SelectInput key={index} options={mapListOptions(field.ValidValues)} label={field.Name} current={value?.Value ?? ""} fieldName={field.Code} handleChange={handleCustomFieldChange} disabled={existingUid !== ''} />
					</Fragment>
				)
			case ICustomFieldType.Money:
			case ICustomFieldType.Date:
			case ICustomFieldType.Unknown:
			case ICustomFieldType.Time:
			case ICustomFieldType.DateTime:
			case ICustomFieldType.Bool:
			case ICustomFieldType.Byte:
			case ICustomFieldType.Blob:
			case ICustomFieldType.List:
			case ICustomFieldType.MultiSelectValidatedList:
			case ICustomFieldType.String:
			default:

				return (
					<Fragment key={index}>

						<TextInput key={index} id={field.Code} value={value?.Value ?? ''} label={field.Name}
						           handleChange={handleCustomFieldChange} disabled={existingUid !== ''}/>
						<br/>
					</Fragment>
				)
		}
	}

	const mapSignupField = (field: IQuickSignupField, index: number) => {
		if (!field.IsIncluded && !field.IsRequired)
			return;

		// @ts-ignore
		let value = field.Name === "Zip" ? state.PostalCode : state[field.Name]
		let inputField: any;
		const label = field.Editable;
		switch (field.Name) {
			case 'Password':
				return <></>
			case 'EmailAddress':
				inputField = <EmailInput key={index} required={field.IsRequired} id={field.Name} value={value} label={label} handleChange={handleChange} disabled={existingUid !== ''}/>;
				break;
			case 'Country':
				return (
					<Fragment key={index}>
						<SelectInput key={index} current={value} disabled={existingUid !== ''} fieldName={field.Name} handleChange={handleChange} options={countryOptions}
						             label={label} width={182}/>
					</Fragment>
				);
			case 'State':
				if (state.Country === '') {
					return;
				}
				const selected: ICountry = countries.find((curr: ICountry) => curr.Code === state.Country);
				if (selected?.HasStates)
					return (
						<Fragment key={index}>
							<SelectInput key={index} current={value} disabled={existingUid !== ''} fieldName={field.Name} handleChange={handleChange}
							             options={stateOptions} label={label} width={182}/>
						</Fragment>
					);
				else
					inputField = <TextInput key={index} required={field.IsRequired} id={field.Name} value={value} label={label} handleChange={handleChange} disabled={existingUid !== ''}/>;
				break;
			default:
				inputField = <TextInput key={index} required={field.IsRequired} id={field.Name} value={value} label={label} handleChange={handleChange} disabled={existingUid !== ''}/>;
		}
		return (
			<Fragment key={index}>
				{inputField}
				<br/>
			</Fragment>
		);
	};
	
	if (isError) {
		alert(error);
		reset();
	}

	return (
		<>
			{SnippetConfig &&
            <form onSubmit={submit}>
				{countriesStatus === 'success' &&
                <div className='bLoyal-center bLoyal-form'>
                    <div className='bLoyal-center bLoyal-input-holder'>
						{SnippetConfig.QuickSignupFields.map(mapSignupField)}
	                    {profile.ClubMembershipCustomFields.map(mapCustomField)}
                    </div>
                </div>
				}
                <br/>
                <button className='bl-snippet-button' id='back' onClick={props.prevStep}>Back</button>
                <button className='bl-snippet-button' id='continue' type='submit'>Continue</button>
            </form>
			}
		</>
	)
		;
}