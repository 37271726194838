import React, {Fragment, useEffect, useReducer, useState} from 'react';
import {
	ICountry,
	ICustomer,
	ICustomField, ICustomFieldType,
	IInputConfig,
	IMemberContactState,
	ISelectOption,
	IState,
	ISyntheticEvent
} from './Helpers/Types';
import TextInput from './TextInput';
import {CustomerAction, CustomerActionTypes, updateCustomFieldAction, updateFieldAction} from './CustomerActions';
import {useSnippetContext} from './Utils/UseSnippetContext';
import {useAuth} from './Utils/UseAuth';
import useServiceUrls from './Utils/UseServiceUrls';
import useSaveMember from './Utils/UseSaveMember';
import {doesCountryHaveStates, mapServiceUrls} from './Helpers/Functions';
import useCountries from './Utils/UseCountries';
import SelectInput from './SelectInput';
import useStates from './Utils/UseStates';
import LoadingSpinner from './LoadingSpinner';
import useDeviceSessionProfile from "./Utils/UseDeviceSessionProfile";
import NumberInput from "./NumberInput";
import {useDeviceSessionContext} from './Utils/UseDeviceSessionProfileContext';

export const customerState: IMemberContactState = {
	Customer: {
		Id: -1,
		Uid: '',
		ExternalId: null,
		PartitionUid: null,
		PartitionCode: 'All',
		Code: '-1',
		FirstName: '',
		LastName: '',
		FirstName2: null,
		LastName2: null,
		Address: {
			Address1: '',
			Address2: '',
			City: '',
			State: '',
			PostalCode: '',
			Country: ''
		},
		AddressModified: false,
		AddressValidated: false,
		CurrentLoyaltyPoints: 0,
		CurrentLoyaltyCurrency: 0.0,
		CompanyName: null,
		Phone1: '',
		MobilePhone: '',
		EmailAddress: '',
		FacebookId: '',
		TwitterId: null,
		MobileDeviceId: null,
		LoyaltyRedemptionDisabled: false,
		LoyaltyAccrualDisabled: false,
		LoyaltyCardNumber: '',
		GuestCustomer: false,
		EditAtPOS: true,
		WebAccount: true,
		Verified: false,
		TaxExempt: false,
		BirthDate: null,
		LoyaltyPointsAccounts: [],
		LoyaltyCurrencyAccounts: [],
		Type: null,
		PriceLevel: null,
		TransactionCount: 0,
		TotalSales: 0.0000,
		ClubMemberships: [],
		SubscriberGroupMemberships: [],
		LoyaltytProgramMemberships: [],
		LoyaltyProgramMemberships: [],
		SignupChannelUid: '',
		SignupStoreUid: '',
		SignupStoreCode: '',
		SignupStoreExternalId: null,
		NoEmail: 'False',
		Salutation: null,
		ParentCustomerUid: null,
		ParentCustomerCode: null,
		ParentExternalId: null,
		ReferralCustomer1Uid: null,
		ReferralCustomer1Code: null,
		ReferralCustomer2Uid: null,
		ReferralCustomer2Code: null,
		CustomCode1: null,
		CustomCode2: null,
		CustomCode3: null,
		CustomCode4: null,
		CustomCode5: null,
		CustomCode6: null,
		CustomCode7: null,
		CustomCode8: null,
		AlertCount: 0,
		Created: null,
		CreatedLocal: null
	},
	HasChanged: false,
	CustomFields: [],
};

export const customerReducer = (state = customerState, action: CustomerAction): IMemberContactState => {
	switch (action.type) {
		case CustomerActionTypes.UpdateCustomField:
			if (state.CustomFields.filter(field => field.Key === action.fieldName).length > 0){
				let newArray = state.CustomFields.map(field => {
					if (field.Key === action.fieldName)
						return { Key: action.fieldName, Value: action.value }
					return field;
				})
				return {...state, CustomFields: newArray}
			}
			let fields = state.CustomFields
			fields.push({Key: action.fieldName, Value: action.value})
			return {...state, CustomFields: fields}
		case CustomerActionTypes.UpdateField:
			const field = action.fieldName;
			if (field === 'Address1' || field === 'Address2' || field === 'City' || field === 'State' || field === 'Zip' || field === 'Country') {
				if (field === 'Zip')
					return {
						...state,
						Customer: {
							...state.Customer,
							Address: {
								...state.Customer.Address,
								PostalCode: action.value
							}
						},
						HasChanged: true
					};

				return {
					...state,
					Customer: {
						...state.Customer,
						Address: {
							...state.Customer.Address,
							[action.fieldName]: action.value
						}
					},
					HasChanged: true
				};
			}

			return {
				...state,
				Customer: {
					...state.Customer,
					[action.fieldName]: action.value
				},
				HasChanged: true
			};
		default:
			return state;
	}
};

type MemberContactInfoProps = {
	fields: Array<IInputConfig>
	customFieldDefinitions: Array<any>
	customer: ICustomer | null
	nextStep: (e: ISyntheticEvent) => void
	prevStep: (e: ISyntheticEvent) => void
	customFields: object
	updateCustomFields: (value: object) => void
}

export default function MemberContactInfo(props: MemberContactInfoProps) {
	const mapCustomFieldValues = (customFields: object) => {
		if (!customFields)
			return [];
		
		let values = [];
		for (const field in customFields) {
			// @ts-ignore
			values.push({Key: field, Value: customFields[field]})
		}
		return values;
	}
	
	// @ts-ignore
	const {profile} = useDeviceSessionContext();
	let initialState = { ...customerState, CustomFields: mapCustomFieldValues(props.customFields) };
	if (props.customer !== null) {
		initialState = {
			...initialState,
			Customer: {
				...customerState.Customer,
				...props.customer
			}
		};
	}
	if (initialState.Customer.Address.Country === '' || initialState.Customer.Address.Country === null)
		initialState.Customer.Address.Country = profile.DefaultCountry;
	if (initialState.Customer.Address.State === null)
		initialState.Customer.Address.State = '';
		
	const [state, dispatch] = useReducer(customerReducer, initialState);

	// @ts-ignore
	const { domain, deviceCode } = useSnippetContext();
	// @ts-ignore
	const { session, setSession } = useAuth();
	const { serviceUrls } = useServiceUrls(domain);
	const { deviceSessionProfile } = useDeviceSessionProfile(serviceUrls, domain, deviceCode)
	const { countries, countriesStatus } = useCountries(serviceUrls, domain, deviceCode);
	const { states } = useStates(serviceUrls, domain, deviceCode, state.Customer.Address.Country, countries && doesCountryHaveStates(countries, state.Customer.Address.Country));
	const [saveMember, {status, data, reset}] = useSaveMember();

	const [countryOptions, setCountryOptions] = useState<Array<ISelectOption>>([]);
	const [stateOptions, setStateOptions] = useState<Array<ISelectOption>>([]);

	useEffect(() => {
		if (!countries)
			return;

		const countryList = countries.map((country: ICountry) => {
			return {
				value: country.Code,
				text: country.Name
			};
		});

		setCountryOptions(countryList);
	}, [countries]);

	useEffect(() => {
		if (!states)
			return;

		const stateList = states.map((state: IState) => {
			return {
				value: state.Code,
				text: state.Name
			};
		});

		setStateOptions(stateList);
	}, [states]);

	useEffect(() => {
		if (status === 'success') {
			if (data) {
				setSession(session, data.data.Customer);
				props.nextStep({target: {}, preventDefault: () => {}, persist: () => {}})
				reset();
			}
		}
	}, [data, status, props])

	const submit = (e: ISyntheticEvent) => {
		const mapCustomFieldsToObject = (fields: Array<any>) => {
			let fieldsObject: object = {}
			fields.forEach((field: any) => {
				// @ts-ignore
				fieldsObject[field.Key] = field.Value
			})
			return fieldsObject;
		}
		
		props.updateCustomFields(mapCustomFieldsToObject(state.CustomFields))
		if (state.HasChanged) {
			e.preventDefault();
			saveMember({ urls: mapServiceUrls(serviceUrls), deviceCode, domain, session, member: state.Customer });
		}
		else 
			props.nextStep(e);
	};

	const handleChange = (fieldName: string, value: string) => {
		dispatch(updateFieldAction(fieldName, value));
	};
	
	const handleCustomFieldChange = (fieldName: string, value: string) => {
		dispatch(updateCustomFieldAction(fieldName, value))
	}
	
	const mapCustomField = (field: ICustomField, index: number) => {
		const fieldConfig = props.customFieldDefinitions.find(d => d.Code === field.Code)
		
		if (!fieldConfig)
			return null;
		
		const mapListOptions = (options: Array<any>) => {
			return options.map((option: any) => {
				return {
					text: option.Name,
					value: option.Value,
				}
			})
		}
		
		let value = state.CustomFields.find((curr: any) => curr.Key === field.Code)
		switch (field.FieldType) {

			case ICustomFieldType.Int:
			case ICustomFieldType.Decimal:
				return (
					<Fragment key={index}>
						<NumberInput id={field.Code} value={value?.Value ?? ''} label={field.Name}
								   handleChange={handleCustomFieldChange}/>
						<br/>
					</Fragment>
				)
			case ICustomFieldType.ValidatedList:
				return (
					<Fragment key={index}>
						<SelectInput options={mapListOptions(field.ValidValues)} label={field.Name} current={value?.Value ?? ""} fieldName={field.Code} handleChange={handleCustomFieldChange} disabled={false} required={fieldConfig.IsRequired} />
					</Fragment>
				)
			case ICustomFieldType.Money:
			case ICustomFieldType.Date:
			case ICustomFieldType.Unknown:
			case ICustomFieldType.Time:
			case ICustomFieldType.DateTime:
			case ICustomFieldType.Bool:
			case ICustomFieldType.Byte:
			case ICustomFieldType.Blob:
			case ICustomFieldType.List:
			case ICustomFieldType.MultiSelectValidatedList:
			case ICustomFieldType.String:
			default:

				return (
					<Fragment key={index}>

						<TextInput id={field.Code} value={value?.Value ?? ''} label={field.Name}
								   handleChange={handleCustomFieldChange}/>
						<br/>
					</Fragment>
				)
		}
	}

	const mapContactField = (field: IInputConfig, index: number) => {
		if (!field.IsIncluded)
			return;

		// @ts-ignore
		let value = state.Customer[field.FieldName];
		if (field.FieldName === 'Zip') {
			value = state.Customer.Address.PostalCode;
		}
		if (!value) {
			// @ts-ignore
			value = state.Customer.Address[field.FieldName];
		}

		if (field.FieldName === 'Country') {
			return (
				<Fragment key={index}>
					<SelectInput current={value} disabled={false} fieldName={field.FieldName} handleChange={handleChange} options={countryOptions}
					             label={field.Label} required={field.IsRequired}/>
				</Fragment>
			);
		}

		if (field.FieldName === 'State') {
			if (state.Customer.Address.Country === '') {
				return;
			}
			const selected: ICountry = countries.find((curr: ICountry) => curr.Code === state.Customer.Address.Country);
			if (selected?.HasStates)
				return (
					<Fragment key={index}>
						<SelectInput current={value} disabled={false} fieldName={field.FieldName} handleChange={handleChange}
						             options={stateOptions} label={field.Label} required={field.IsRequired}/>
					</Fragment>
				);

		}
		
		if (field.FieldName === 'BirthDate') {
			if (value !== undefined && value.includes('T')) {
				let date = new Date(value);
				let options = {year: "numeric", month: "2-digit", day: "2-digit"}
				// @ts-ignore
				value = date.toLocaleDateString('en-US', options);
			}
			return (
				<Fragment key={index}>

					<TextInput id={field.FieldName} value={value ?? ''} label={field.Label}
							   handleChange={handleChange} required={field.IsRequired}/>
					<br/>
				</Fragment>
			);
		}

		return (
			<Fragment key={index}>

				<TextInput id={field.FieldName} value={value ?? ''} label={field.Label}
				           handleChange={handleChange} required={field.IsRequired}/>
				<br/>
			</Fragment>
		);
	};

	return (
		<form onSubmit={submit}>
			{countriesStatus === 'success' &&
            <div className='bLoyal-center bLoyal-form'>
                <div className='bLoyal-center bLoyal-input-holder'>
					{props.fields.map(mapContactField)}
					{deviceSessionProfile.ClubMembershipCustomFields.map(mapCustomField)}
                </div>
            </div>
			}
			<br/>
			<button className='bl-snippet-button' id='back' onClick={props.prevStep}>Back</button>
			<button className='bl-snippet-button' id='continue' type='submit'>Continue</button>
			{status === 'loading' &&
			<LoadingSpinner />
			}
		</form>
	);
}