import {queryCache, useMutation} from 'react-query';
import {client} from './ApiClient';
import {v4 as uuid} from 'uuid'

const saveShippingAddress = ({urls, session, domain, deviceCode, address}: {urls: any; session: string; domain: string; deviceCode: string; address: any}) => {
	return client(urls.LoyaltyEngineApiUrl, `api/v4/${domain}/${deviceCode}/sessions/${session}/shippingaddresses/commands/save`, {
		body: {
			...address,
			Uid: uuid(),
			ShippingAddressUid: address.Uid,
			ValidateAddress: false
		}
	});
}

export default function useSaveShippingAddress() {
	// @ts-ignore
	return useMutation(saveShippingAddress, {
		// onMutate: ({address}: {urls: any; session: string; domain: string; deviceCode: string; address: any}) => {
		// 	// @ts-ignore
		// 	const previousAddresses = queryCache.getQueryData('ShippingAddresses')
		// 	queryCache.setQueryData('ShippingAddresses', (old: any) => {
		// 		const updated = old.data.map((curr: any) => {
		// 			if (curr.Uid === address.Uid)
		// 				return address
		// 			return curr
		// 		})
		// 		return { ...old, data: updated };
		// 	})
		//	
		// 	return () => queryCache.setQueryData('ShippingAddresses', previousAddresses);
		// },
		onSuccess: () => {
			queryCache.invalidateQueries('ShippingAddresses')
		},
		// onError: (err, data, rollback: Function) => rollback()
	})
}