

import { useMutation} from 'react-query';
import {client} from './ApiClient';

const referCustomer = ({urls, domain, deviceCode, sessionKey, email, firstName, lastName, message}: {urls: any; domain: string; deviceCode: string; sessionKey: string; email: string; firstName: string; lastName: string; message: string;}) => {
    return client(urls.LoyaltyEngineApiUrl, `api/v4/${domain}/${deviceCode}/sessions/${sessionKey}/customerreferrals/commands/refer`, {
        body: {
            EmailAddress: email,
            FirstName: firstName,
            LastName: lastName,
            Message: message
        }
    });
}

export default function useReferCustomer() {
    // @ts-ignore
    return useMutation(referCustomer)
}