import React from 'react';
import {ISyntheticEvent, MembershipType} from './Helpers/Types';
import {FormControlLabel, Radio, RadioGroup} from '@material-ui/core';

type ClubMembershipTypeProps = {
	type: MembershipType,
	setType: (type: MembershipType) => void
	snippetConfig: any
}

export default function ClubMembershipType(props: ClubMembershipTypeProps) {
	const handleChange = (event: ISyntheticEvent) => {
		props.setType(event.target.value);
	};

	return (
		<div className='bLoyal-center form'>
			<h1>{props.snippetConfig.AskMembershipTypeQuestion}</h1>
			<RadioGroup aria-label="productSelector" name="products" value={props.type} onChange={handleChange}>
				<FormControlLabel value={MembershipType.Standard} control={<Radio/>} label={props.snippetConfig.StandardMembershipTypeCaption}/>
				<FormControlLabel value={MembershipType.Gift} control={<Radio/>} label={props.snippetConfig.GiftMembershipTypeCaption}/>
			</RadioGroup>
		</div>
	);
}