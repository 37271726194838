import React, {useEffect, useState} from 'react';
import TextInput from "../TextInput";
import EmailInput from "../EmailInput";
import useReferCustomer from "../Utils/UseReferCustomer";
import {IInputConfig, IReferralSnippetConfig, ISyntheticEvent, WebSnippetProfile} from "../Helpers/Types";
import useServiceUrls from "../Utils/UseServiceUrls";
import {useSnippetContext} from "../Utils/UseSnippetContext";
import {getButtonText, getDisplayValue, mapServiceUrls} from "../Helpers/Functions";
import {useAuth} from "../Utils/UseAuth";
import LoadingSpinner from "../LoadingSpinner";
import MultilineTextInput from "../MultilineTextInput";
import SnippetHeader from "../Helpers/SnippetHeader";
import SnippetFooter from "../Helpers/SnippetFooter";

type ReferralSnippetProps = {
    snippetConfig: WebSnippetProfile
}

export default function ReferralSnippet(props: ReferralSnippetProps) {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    // @ts-ignore
    const {session, customer } = useAuth();
    // @ts-ignore 
    const { domain, deviceCode } = useSnippetContext();
    const { serviceUrls } = useServiceUrls(domain);
    const [refer, {  isLoading, data, isError, error, status, reset }] = useReferCustomer();

    useEffect(() => {
        if (status === 'success') {
            clearFields()
            if (props.snippetConfig.OnSuccessRedirectUrl)
                window.setTimeout(function () {
                    window.location.assign(props.snippetConfig.OnSuccessRedirectUrl)
                }, (props.snippetConfig.OnSuccessRedirectDelay ?? 0) * 1000)
        }
    }, [props.snippetConfig, status])
    
    const clearFields = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setMessage('');
    }
    
    const submit = (e: ISyntheticEvent) => {
        e.preventDefault();
        refer({urls: mapServiceUrls(serviceUrls), domain, deviceCode, sessionKey: session, email, firstName, lastName, message})
    }
    
    const handleChange = (fieldName: string, value: string) => {
        if (status === 'success')
            reset();
        
        switch (fieldName) {
            case 'referral-firstname':
                setFirstName(value);
                break;
            case 'referral-lastname':
                setLastName(value);
                break;
            case 'referral-email':
                setEmail(value);
                break;
            case 'referral-message':
                setMessage(value);
                break;
            default:
        }
    }

    const getLabel = (fieldName: string): string => {
        return props.snippetConfig.CustomerReferral?.Fields.find((input: IInputConfig) => input.FieldName === fieldName)?.Label ?? getDisplayValue(fieldName);
    }

    return (
        <div className='bLoyal-center' >
            <SnippetHeader logoUrl={props.snippetConfig.LogoUrl} title={props.snippetConfig.Title} message={props.snippetConfig.Message} snippetCode={props.snippetConfig.Code}/>
            <div className='bLoyal-center bLoyal-input-holder'>
                <form onSubmit={submit}>
                    <TextInput id='referral-firstname' handleChange={handleChange} value={firstName} label={getLabel("FirstName")}/>
                    <TextInput id='referral-lastname' handleChange={handleChange} value={lastName} label={getLabel("LastName")} />
                    <EmailInput id='referral-email' handleChange={handleChange} value={email} label={getLabel("Email")} required={true}/>
                    <MultilineTextInput id='referral-message' handleChange={handleChange} value={message} label={getLabel("Message")} />
                    <button className='bl-snippet-button' id='submit-referral' type='submit'>{getButtonText(props.snippetConfig.Buttons, "Send Referral")}</button>
                </form>
            </div>
            {status === 'loading' &&
            <LoadingSpinner />
            }
            {status === 'success' &&
            <p className='message'>{props.snippetConfig.OnSuccessMessage}</p>
            }
            {status == 'error' &&
                // @ts-ignore
            <p className='message bLoyal-error-text'>{error}</p>
            }
            <SnippetFooter footer={props.snippetConfig.Footer} snippetCode={props.snippetConfig.Code}/>
        </div>
    )
}