import {ILESignupCommand, ISignupState} from "./Types";
import {v4 as uuid} from "uuid";

const mapCustomFieldsToObject = (customFields: Array<any>): object => {
    if (customFields.length === 0)
        return {};
    
    let fields = {};

    customFields = customFields.map((field: any) => {
        if (field.Key === undefined)
            return {
                [field.code]: field.value
            }
        else
            return {
                [field.Key]: field.Value,
            }
    })

    const reducer = (acc: any, currentValue: any) => {
        return {
            ...acc,
            ...currentValue,
        }
    }

    fields = customFields.reduce(reducer);
    return fields;
}

export const mapSignupStateToLESignupCommand = (state: ISignupState): ILESignupCommand => {
    return {
        Uid: uuid(),
        Address: {
            Address1: state.Address1 ?? '',
            Address2: state.Address2 ?? '',
            City: state.City ?? '',
            State: state.State ?? '',
            Country: state.Country ?? '',
            PostalCode: state.PostalCode ?? ''
        },
        BirthDate: state.BirthDate ?? '',
        CompanyName: state.CompanyName ?? '',
        CustomFields: mapCustomFieldsToObject(state.CustomFields),
        EmailAddress: state.EmailAddress ?? '',
        FirstName: state.FirstName ?? '',
        FirstName2: state.FirstName2 ?? '',
        GroupsToJoin: state.SubscriberGroups,
        LastName: state.LastName ?? '',
        LastName2: state.LastName2 ?? '',
        LoyaltyCardNumber: state.LoyaltyCardNumber ?? '',
        MobilePhone: state.MobilePhone ?? '',
        Password: state.Password ?? '',
        Phone1: state.Phone1 ?? '',
    }
}