
export function onSuccess(config: any) {
	if (config.RedirectOnLoginSuccess) {
		window.location.assign(config.SuccessUrlOrSnippet)
	}
	if (config.ShowMessageOnLoginSuccess)
		return config.SuccessMessage;
	
	return null;
}

export function onError(config: any) {
	if (config.RedirectOnLoginError)
		window.location.assign(config.ErrorUrlOrSnippet)
	
	return null
}