import {Status} from './Helpers/Types';
import {Action} from 'typesafe-actions';

export enum WizardActionTypes {
	editFormField = 'wizard/editFormField',
	changeStatus = 'wizard/changeStatus',
	changePageNum = 'wizard/changePageNum'
}

export interface EditFormFieldAction extends Action<WizardActionTypes.editFormField> {
	fieldName: string,
	value: any
}

export interface ChangeStatusAction extends Action<WizardActionTypes.changeStatus> {
	status: Status
}

export interface ChangePageNumAction extends Action<WizardActionTypes.changePageNum> {
	num: number,
}

export type WizardAction =
	| EditFormFieldAction
	| ChangeStatusAction
	| ChangePageNumAction

export const editFormField = (fieldName: string, value: any) => {
	return {
		type: WizardActionTypes.editFormField,
		fieldName,
		value,
	}
}

export const changeStatus = (status: Status) => {
	return {
		type: WizardActionTypes.changeStatus,
		status
	}
}

export const changePageNum = (num: number) => {
	return {
		type: WizardActionTypes.changePageNum,
		num,
	}
}