import {useMutation} from 'react-query';
import {client} from './ApiClient';

const recordEngagement = ({urls, domain, deviceCode, token}: {urls: any; domain: string; deviceCode: string; token: string;}) => {
    return client(urls.LoyaltyEngineApiUrl, `api/v4/${domain}/${deviceCode}/snippets/engagements/commands/recordtoken`, {
        body: {
            EngagementToken: token
        }
    });
}

export default function useRecordEngagement() {
    // @ts-ignore
    return useMutation(recordEngagement)
}