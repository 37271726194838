import React, {useMemo} from 'react';
import {ChoiceResponse, ClubSignupSnippetProfile} from "../Helpers/Types";
import GiftChoice from "../ClubPreference/Sections/GiftChoice";
import RadioSelection from "../ClubPreference/Sections/RadioSelection";

type ClubMembershipGiftPageProps = {
    snippetProfile: ClubSignupSnippetProfile,
    updateField: (field: string, value: any) => void
    isGift: ChoiceResponse
}

export default function ClubMembershipGiftPage(props: ClubMembershipGiftPageProps) {

    const radioOptions = useMemo(() => [{label: props.snippetProfile.GiftMembershipTypeText, value: ChoiceResponse.Yes}, {label: props.snippetProfile.StandardMembershipTypeText, value: ChoiceResponse.No}], [])
    return (
        <RadioSelection  fieldName={'giftChoice'} value={props.isGift} handleChange={props.updateField} helpText={''} label={props.snippetProfile.MembershipSelectionTypeText} options={radioOptions}/>
    )
}