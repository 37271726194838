import {Action} from 'typesafe-actions';

export enum CustomerActionTypes {
    UpdateField = 'Customer/UpdateField',
    UpdateCustomField = 'Customer/UpdateCustomField'
}

export interface IUpdateFieldAction extends Action<CustomerActionTypes.UpdateField> {
    fieldName: string,
    value: string,
}

export interface IUpdateCustomFieldAction extends Action<CustomerActionTypes.UpdateCustomField> {
    fieldName: string,
    value: string
}

export type CustomerAction =
    | IUpdateFieldAction
    | IUpdateCustomFieldAction

export const updateFieldAction = (fieldName: string, value: string) => {
    return {
        type: CustomerActionTypes.UpdateField,
        fieldName,
        value,
    }
}

export const updateCustomFieldAction = (fieldName: string, value: string) => {
    return {
        type: CustomerActionTypes.UpdateCustomField,
        fieldName,
        value,
    }
}