import React, {useEffect, useState} from 'react';
import {logout} from './Utils/AuthClient';
import useClubMembership from './Utils/UseClubMembership';
import {useAuth} from './Utils/UseAuth';
import {useSnippetContext} from './Utils/UseSnippetContext';
import useServiceUrls from './Utils/UseServiceUrls';
import {
	DeliveryType,
	ISyntheticEvent,
	IWizardFormData,
	IWizardPage,
	MembershipType,
	PaymentSource,
	ProductPreferenceType
} from './Helpers/Types';
import ClubMembershipType from './ClubMembershipType';
import ClubSignupWizard from './ClubSignupWizard';
import LoadingSpinner from './LoadingSpinner';

type ClubManagementProps = {
	snippetConfig: any,
}

export default function ClubManagement(props: ClubManagementProps) {
	// @ts-ignore
	const { session, customer } = useAuth();
	// @ts-ignore
	const { domain, deviceCode, clubCode } = useSnippetContext();
	const { serviceUrls } = useServiceUrls(domain);
	const [isClubError, setClubError] = useState(false);
	
	const club = clubCode === '' && props.snippetConfig.Clubs.length === 1 ? props.snippetConfig.Clubs[0] : props.snippetConfig.Clubs.find((club: any) => club.Code.toLowerCase() === clubCode.toLowerCase());
	// @ts-ignore
	const [type, setType] = useState<MembershipType>(MembershipType[sessionStorage.getItem('blMembershipType') ?? MembershipType.Standard]);
	const emptyMembership: IWizardFormData = {
		Fulfillment: { ShippingAddressUid: '', PickupLocationUid: '', DeliveryType: DeliveryType.Ship }, Payment: { PaymentMethodUid: '', Source: PaymentSource.Default }, ProductPreferenceType: ProductPreferenceType.Default, MemberProductPreferenceType: ProductPreferenceType.Default, ProductPreferences: [], CustomFields: {}
	}
	let currentMembership: any = null;
	let newMember = false;
	if (club && customer.ClubMemberships.length > 0)
		currentMembership = customer.ClubMemberships.find((membership: any) => membership.Club.Uid === club.Uid);
	
	if (currentMembership == null)
		newMember = true;
		
	const { membership, membershipStatus, membershipError } = useClubMembership(serviceUrls, domain, deviceCode, session, currentMembership?.Uid);

	const [isTypeSet, setIsTypeSet] = useState(sessionStorage.getItem('blMembershipTypeSet') === 'true');
	let map: Array<IWizardPage> = [];
	if (props.snippetConfig.IncludeMemberContactInfo)
		map.push({
			Title: 'Member Contact Info',
			Type: 'contact'
		});
	if (props.snippetConfig.IncludeShippingPreferences)
		map.push({
			Title: 'Shipping Preferences',
			Type: 'shipping'
		});
	if (props.snippetConfig.IncludeProductPreferences)
		map.push({
			Title: 'Product Preferences',
			Type: 'products'
		});
	if (props.snippetConfig.IncludePaymentPreferences)
		map.push({
			Title: 'Payment Preferences',
			Type: 'payments'
		});
	const [pageMap, setPageMap] = useState<Array<any>>(map);
	
	useEffect(() => {
		if (clubCode === '' && props.snippetConfig.Clubs.length > 1) {
			console.error('You must provide a club code by including ClubCode in the data-bloyal-snippet-args attribute when your snippet is configured to use more than one club')
			setClubError(true)
		}
	}, [props.snippetConfig, clubCode])

	useEffect(() => {
		sessionStorage.setItem('blMembershipTypeSet', isTypeSet.toString());
	}, [isTypeSet]);

	useEffect(() => {
		sessionStorage.setItem('blMembershipType', type);
	}, [type]);

	useEffect(() => {
		let map: Array<any> = [];
		if (type === MembershipType.Standard) {
			if (props.snippetConfig.IncludeMemberContactInfo)
				map.push({
					Title: 'Member Contact Info',
					Type: 'contact'
				});
			if (props.snippetConfig.IncludeShippingPreferences)
				map.push({
					Title: 'Shipping Preferences',
					Type: 'shipping'
				});
			if (props.snippetConfig.IncludeProductPreferences)
				map.push({
					Title: 'Product Preferences',
					Type: 'products'
				});
			if (props.snippetConfig.IncludePaymentPreferences)
				map.push({
					Title: 'Payment Preferences',
					Type: 'payments'
				});
		}
		else {
			map.push({
				Title: 'Gift Membership Recipient',
				Type: 'giftRecipient'
			});
			if (props.snippetConfig.IncludeShippingPreferences)
				map.push({
					Title: 'Shipping Preferences',
					Type: 'giftShipping'
				});
			if (props.snippetConfig.IncludeProductPreferences)
				map.push({
					Title: 'Product Preferences',
					Type: 'products'
				});
			if (props.snippetConfig.IncludePaymentPreferences)
				map.push({
					Title: 'Payment Preferences',
					Type: 'payments'
				});
		}
		setPageMap(map);

	}, [type]);
	
	if (isClubError)
		return (<><h1>Club signup is not available at this time</h1><h2>{props.snippetConfig.SnippetConfigurationErrorMessage}</h2></>)

	const changeType = (type: MembershipType) => {
		setType(type);
	};

	const nextStep = (e: ISyntheticEvent) => {
		e.preventDefault();
		setIsTypeSet(true);
	};

	const backToTypeSelection = () => {
		setIsTypeSet(false);
	};

	if (isTypeSet && !club) {
		alert(`This snippet is not available for club ${clubCode}.`)
	}

	if (props.snippetConfig.GiftMembershipsEnabled)
		return <>
			{!isTypeSet &&
            <form onSubmit={nextStep}>
                <ClubMembershipType snippetConfig={props.snippetConfig} type={type} setType={changeType}/>
                <br/>
                <button className='bl-snippet-button' id='continue' type='submit'>Continue</button>
            </form>
			}
			{membershipStatus === 'loading' &&
            <LoadingSpinner/>
			}
			{!newMember && (membership || !currentMembership) && !membershipError && isTypeSet && club &&
            <ClubSignupWizard clubUid={club.Uid} membershipType={type} pageMap={pageMap} config={props.snippetConfig} clubMembership={type === MembershipType.Standard ? membership ?? emptyMembership : emptyMembership} customer={customer}
                    backToTypeSelection={backToTypeSelection}/>
			}
			{newMember && isTypeSet && club &&
			<ClubSignupWizard clubUid={club.Uid} membershipType={type} pageMap={pageMap} config={props.snippetConfig} clubMembership={emptyMembership} customer={customer}
							  backToTypeSelection={backToTypeSelection}/>
			}
			{isTypeSet && !club &&
			<p className='bLoyal-error-text'>Please check your configuration or integration.</p>
			}
			{membershipError &&
            <p className='bLoyal-error-text'>There was an error completing the request.</p>
			}
		</>
	else
		return <>
			{membershipStatus === 'loading' &&
            <LoadingSpinner/>
			}
			{!newMember && (membership || !currentMembership) && !membershipError &&
            	<ClubSignupWizard clubUid={club.Uid} membershipType={MembershipType.Standard} pageMap={pageMap} config={props.snippetConfig} clubMembership={membership ?? emptyMembership}
                              customer={customer}
                              backToTypeSelection={backToTypeSelection}/>
			}
			{newMember &&
				<ClubSignupWizard clubUid={club.Uid} membershipType={MembershipType.Standard} pageMap={pageMap} config={props.snippetConfig} clubMembership={emptyMembership}
							  customer={customer}
							  backToTypeSelection={backToTypeSelection}/>
			}
			{membershipError &&
            <p className='bLoyal-error-text'>There was an error completing the request.</p>
			}
		</>;
}