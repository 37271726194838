import {useQuery} from 'react-query';
import {client, logout} from './ApiClient';
import {mapServiceUrls} from '../Helpers/Functions';

const getProductsByCode = (key: any, urls: any, session: string, domain: string, deviceCode: string, lookupCodes: any) => {
	let uidString = '';
	lookupCodes.forEach((uid: string, index: number) => {
		if (index === 0)
			uidString += uid;
		else
			uidString += ',' + uid;
	})
	return client(urls.LoyaltyEngineApiUrl, `api/v4/${domain}/${deviceCode}/sessions/${session}/products?codes=${uidString}`);
}

export default function useProducts(urls: any, session: string, domain: string, deviceCode: string, lookupCodes: any) {
	const {status, data, error, isFetching} = useQuery('products', (key) => getProductsByCode(key, mapServiceUrls(urls), session, domain, deviceCode, lookupCodes), {enabled: urls, staleTime: 1000*60*5});
	const productsStatus = status;
	const products = data ? data.data : undefined;
	const productsError = error;
	if (error === 'Expired key')
		logout();

	return { productsStatus, products, productsError, isProductFetching: isFetching };
}
