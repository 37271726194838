import React, {Fragment, useMemo} from 'react';
import {ClubMembershipCustomFieldConfig, CustomFieldDisplayType, ICustomFieldType} from "../Helpers/Types";
import NumberInput from "../NumberInput";
import SelectInput from "../SelectInput";
import TextInput from "../TextInput";
import RadioSelection from "../ClubPreference/Sections/RadioSelection";

type CustomFieldsPageProps = {
    deviceProfile: any
    handleChange: (field: string, value: any) => void
    customFieldConfigs: Array<ClubMembershipCustomFieldConfig>
    customFields: Array<{code: string, value: string}>
    displayType: CustomFieldDisplayType
    pageText: string
}

export default function CustomFieldsPage(props: CustomFieldsPageProps) {
    const clubMembershipCustomFields = useMemo(() => props.deviceProfile.ClubMembershipCustomFields, [props.deviceProfile])

    const handleCustomFieldChange = (field: string, value: any) => {
        let fields = props.customFields.slice()
        let found = false
        fields = fields.map((f) => {
            if (f.code === field) {
                found = true
                return {
                    code: field,
                    value: value,
                }
            }

            return f
        })

        if (!found)
            fields.push({code: field, value: value})

        props.handleChange('CustomFields', fields)
    }
    
    const mapCustomField = (fieldConfig: ClubMembershipCustomFieldConfig, index: number) => {
        const field = clubMembershipCustomFields.find((d: any) => d.Code === fieldConfig.Code)

        if (!field)
            return null;

        const mapListOptions = (options: Array<any>) => {
            return options.map((option: any) => {
                return {
                    text: option.Name,
                    value: option.Value,
                }
            })
        }

        const mapRadioOptions = (options: Array<any>) => {
            return options.map((option: any) => {
                return {
                    label: option.Name,
                    value: option.Value,
                }
            })
        }

        let value = props.customFields?.find((curr: any) => curr.code === fieldConfig.Code)?.value
        switch (field.FieldType) {
            case ICustomFieldType.Int:
            case ICustomFieldType.Decimal:
                return (
                    <Fragment key={index}>
                        {/*@ts-ignore*/}
                        <NumberInput id={field.Code} value={value ?? ''} label={field.Name}
                                     handleChange={handleCustomFieldChange} required={fieldConfig.Required}/>
                        <br/>
                    </Fragment>
                )
            case ICustomFieldType.ValidatedList:
                if (props.displayType === CustomFieldDisplayType.Radio)
                    return (
                        <Fragment key={index}>
                            <RadioSelection fieldName={field.Code} value={value} handleChange={handleCustomFieldChange}
                                            helpText={field.HelpText} label={field.Name}
                                            options={mapRadioOptions(field.ValidValues)}/>
                        </Fragment>
                    )

                return (
                    <Fragment key={index}>
                        <SelectInput options={mapListOptions(field.ValidValues)} label={field.Name}
                                     current={value ?? ""} fieldName={field.Code} handleChange={handleCustomFieldChange}
                                     disabled={false} required={fieldConfig.Required}/>
                    </Fragment>
                )
            case ICustomFieldType.Money:
            case ICustomFieldType.Date:
            case ICustomFieldType.Unknown:
            case ICustomFieldType.Time:
            case ICustomFieldType.DateTime:
            case ICustomFieldType.Bool:
            case ICustomFieldType.Byte:
            case ICustomFieldType.Blob:
            case ICustomFieldType.List:
            case ICustomFieldType.MultiSelectValidatedList:
            case ICustomFieldType.String:
            default:
                return (
                    <Fragment key={index}>

                        <TextInput id={field.Code} value={value ?? ''} label={field.Name}
                                   handleChange={handleCustomFieldChange} required={fieldConfig.Required}/>
                        <br/>
                    </Fragment>
                )
        }
    }
    
    return (
        <div className={'bLoyal-input-container bLoyal-center'}>
            <p>{props.pageText}</p>
            {props.customFieldConfigs.map(mapCustomField)}
        </div>
    )
}