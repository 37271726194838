import {useQuery} from 'react-query';
import {client, logout} from './ApiClient';
import {mapServiceUrls} from '../Helpers/Functions';

const getPickupLocations = (key: any, urls: any, session: string, domain: string, deviceCode: string) => {

	return client(urls.LoyaltyEngineApiUrl, `api/v4/${domain}/${deviceCode}/sessions/${session}/pickuplocations`);
}

export default function usePickupLocations(urls: any, session: string, domain: string, deviceCode: string) {
	const {status, data, error, isFetching} = useQuery('PickupLocations', (key) => getPickupLocations(key, mapServiceUrls(urls), session, domain, deviceCode), {enabled: urls, staleTime: 1000*60*5});
	const pickupLocationStatus = status;
	const pickupLocations = data ? data.data : undefined;
	const pickupLocationError = error;
	const isPickupLocationFetching = isFetching
	if (error === 'Expired key')
		logout();

	return { pickupLocationStatus, pickupLocations, pickupLocationError };
}
