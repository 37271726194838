import {useQuery} from 'react-query';
import {client} from './ApiClient';
import {mapServiceUrls} from '../Helpers/Functions';

const getCountries = (key: any, urls: any, domain: string, deviceCode: string) => client(urls.LoyaltyEngineApiUrl, `api/v4/${domain}/${deviceCode}/countries`);

export default function useCountries(urls: any, domain: string, deviceCode: string) {
	const {status, data, error, isFetching } = useQuery('Countries', (key) => getCountries(key, mapServiceUrls(urls), domain, deviceCode), {enabled: urls, staleTime: 1000*60*30});
	const countriesStatus = status;
	const countries = data ? data.data : undefined;
	const countriesError = error;
	const countriesFetching = isFetching;
	return {countriesStatus, countries, countriesError, countriesFetching};
}
