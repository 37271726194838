import {useQuery} from 'react-query';
import {client, logout} from './ApiClient';
import {mapServiceUrls} from '../Helpers/Functions';

const getGiftCardBalance = (key: any, urls: any, cardNumber: string, domain: string, deviceCode: string) => {
	return client(urls.LoyaltyEngineApiUrl, `api/v4/${domain}/${deviceCode}/giftcards/balance/${cardNumber}`);
}

export default function useGetGiftCardBalance(urls: any, cardNumber: string, domain: string, deviceCode: string) {
	const {status, data, error, isFetching, refetch} = useQuery(['GiftCardBalance', cardNumber], (key) => getGiftCardBalance(key, mapServiceUrls(urls), cardNumber, domain, deviceCode), {enabled: false, staleTime: 1000*60*5});
	const balanceResponse = data ? data.data : undefined;
	// @ts-ignore
	if (error === 'Expired key')
		logout();

	return { status, balanceResponse, error, isFetching, refetch };
}
