import {useQuery} from 'react-query';
import {client} from './ApiClient';
import {Settings} from '../Settings';

const getServiceUrls = (key: any, domain: string) => client(Settings.DomainServiceUrl, 'api/v4/serviceurls/' + domain);

export default function useServiceUrls(domain: string) {
	const { status, data, error, isFetching } = useQuery(['ServiceUrls', domain], getServiceUrls, {
		staleTime: 1000*60*5
	});
	const serviceUrlsStatus = status;
	const serviceUrls = data ? data.data : undefined;
	const serviceUrlsError = error;
	const isServiceUrlsFetching = isFetching;
	return {serviceUrlsStatus, serviceUrls, serviceUrlsError, isServiceUrlsFetching}
}