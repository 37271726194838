import React, {useEffect, useState} from 'react';
import './App.css';
import {useAuth} from './Utils/UseAuth';
import {ISyntheticEvent, IWizardPage, IWizardPageMap, LoginType} from './Helpers/Types';
import Login from './Login';
import {onError, onSuccess} from './Utils/LoginFunctions';
import SignupSnippet from './SignupSnippet';
import useResolveCustomer from './Utils/UseResolveSessionCustomer';
import {useSnippetContext} from './Utils/UseSnippetContext';
import useServiceUrls from './Utils/UseServiceUrls';
import LoadingSpinner from "./LoadingSpinner";
import {logout} from "./Utils/AuthClient";
import CustomerSignupApp from "./CustomerSignupApp";
import SignupWrapper from "./SignupWrapper";
import LegacyLogin from "./LegacyLogin";
import LoginHandler from "./LoginHandler";

// let theme = createMuiTheme({
// 	palette: {
// 		primary: {
// 			main: '#92c13d'
// 		},
// 		secondary: {
// 			main: '#393939'
// 		}
// 	}
// });
//
// theme = responsiveFontSizes(theme);

// export function login(username: string, password: string) {
// 	if (username === 'bad')
// 		return Promise.reject('bad password');
//
// 	return Promise.resolve(username);
// }

// const getConfigFromApi = () => client('http://localhost:55253', 'api/v4/RCTest1/MyRewards/SnippetConfig/test')

let pageMap: IWizardPageMap = {
	Pages: new Array<Array<IWizardPage | null>>(), StartPage: {
		Title: 'Forgot to set start page', Type: 'basic', Rows: [
			{
				Cols: [
					{
						FieldName: 'FirstName',
						Label: 'First Name'
					}
				]
			}
		]
	}
};
let page1: IWizardPage = {
	Title: 'Shipping Preferences',
	Type: 'shipping',
};
let page2: IWizardPage = {
	Title: 'Product Selection',
	Type: 'products',
};
pageMap.Pages[0] = [null, page2];
pageMap.Pages[1] = [page1, null];
pageMap.StartPage = page1;

type AuthenticationAppProps = {
	snippetConfig: any,
	authenticatedComponent: React.ReactNode,
}

function AuthenticationApp(props: AuthenticationAppProps) {
	// @ts-ignore
	const {session, customer, setSession} = useAuth();
	
	const [showSignup, setShowSignup] = useState(false);
	const [signupSnippet, setSignupSnippet] = useState('');
	// @ts-ignore
	const { domain, deviceCode, useLogout } = useSnippetContext();
	const { serviceUrls } = useServiceUrls(domain);
	const { sessionCustomer, sessionCustomerStatus } = useResolveCustomer(serviceUrls, session, domain, deviceCode, customer)
	const [sessionValidated, setSessionValidated] = useState(false)
	
	useEffect(() => {
		if (sessionCustomer) {
			setSession(session, sessionCustomer)
		}
	}, [sessionCustomer, session, setSession])
	
	useEffect(() => {
		if (session && customer)
			setSessionValidated(true)
	},[session, customer])
	
	useEffect(() => {
		if (session === 'undefined' || session === undefined) {
			logout()
		}
	})

	const setSignupSnippetCode = (code: string) => {
		setShowSignup(true);
		setSignupSnippet(code);
	}
	
	const logoutUser = (e: ISyntheticEvent) => {
		e.preventDefault();
		logout()
	} 

	if (showSignup)
		return (
			<SignupWrapper snippetCode={signupSnippet} setShowSignup={setShowSignup} />
		)
	
	if (props.snippetConfig.LoginType === LoginType.NotRequired)
		return (
			<div id='bloyal-snippet-container' className="bLoyal-snippet">
				{props.authenticatedComponent}
			</div>
		)
	
	return (
		<>
			{sessionCustomerStatus === 'loading' &&
				<LoadingSpinner />
			}
			{sessionCustomerStatus !== 'loading' &&
			<div id='bloyal-snippet-container' className="bLoyal-snippet">
				{session && customer ? 
					<>
						{useLogout &&
						<button className='bl-snippet-button' id='logout' onClick={logoutUser}>Log out</button>
						}
						{props.authenticatedComponent}
					</>
					: <LoginHandler onSuccess={onSuccess} onError={onError} snippetCode={props.snippetConfig.LoginSnippetCode} setSignupSnippet={setSignupSnippetCode} loginType={props.snippetConfig.LoginType} remoteLoginUrl={props.snippetConfig.LoginUrl} />
					}
			</div>
			}
		</>
	);
	
}

export default AuthenticationApp;
