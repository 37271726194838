import React, {SyntheticEvent} from "react";
import {Dialog, DialogContent, DialogTitle, DialogActions} from "@mui/material";

type ConfirmLoseChangesDialogProps = {
    open: boolean,
    message: string
}

export default function SuccessDialog(props: ConfirmLoseChangesDialogProps) {

    return (
        <Dialog className={'bLoyal-dialog'} maxWidth='sm' fullWidth={true} open={props.open && props.message !== ''}>
            <DialogTitle ></DialogTitle>
            <DialogContent className='dialog-body'>
                {props.message}
            </DialogContent>
        </Dialog>
    )
}