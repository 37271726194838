import React, {useEffect, useState} from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import TableInput from './TableInput';
import {ProductPreference, QuantityType} from './Helpers/Types';
import {getPercentOffPrice, getUnitPriceDisplay} from "./Helpers/Functions";

type ProductSelectionProps = {
	products: Array<any>
	setQuantity: (value: number) => void
	setPrice: (value: number) => void
	getValue: Function
	valuesUpdated: () => void
}

const useStyles = makeStyles({
	table: {
		minWidth: 400
	},
	input: {
		width: 60
	},
});

interface Row {
	Title: string,
	Quantity: number,
	Price: number,
	Type: string,
}

export default function ProductSelection(props: ProductSelectionProps) {
	const classes = useStyles();
	let totalQuantity: number = 0;
	let totalPrice: number = 0.00;
	const [products, setProducts] = useState(props.products.map((product: any) => {
		return { ...product, Quantity: product.SelectedQuantity ?? 0 }
	}));

	useEffect(() => {
		let newProds: Array<Row> = [...products]

		let selectedProducts: Array<ProductPreference> = []
		newProds.forEach((prod: any) => {
			if (prod.Quantity === 0)
				return;
			const temp = {
				ProductUid: prod.Uid,
				Quantity: prod.Quantity,
			}
			selectedProducts.push(temp)
		})
		props.getValue(selectedProducts)
	}, [])
	
	const updateQuantity = (num: number, rowNum: number) => {
		let value: number = num
		if (isNaN(value))
			value = 0
		
		let newProds: Array<Row> = [...products]
		newProds[rowNum] = {
			...products[rowNum],
			Quantity: value
		}
		
		let quantity = 0;
		let price = 0.00;
		newProds.forEach((product: any) => {
			quantity += product.Quantity;
			price += getPercentOffPrice(product);
		})
		props.setQuantity(quantity);
		price = parseFloat(price.toFixed(2))
		props.setPrice(price);
		setProducts(newProds);
		
		let selectedProducts: Array<ProductPreference> = []
		newProds.forEach((prod: any) => {
			if (prod.Quantity === 0)
				return;
			const temp = {
				ProductUid: prod.Uid,
				Quantity: prod.Quantity,
			}
			selectedProducts.push(temp)
		})
		props.valuesUpdated()
		props.getValue(selectedProducts);
	}
	
	if (products.length > 0) {
		products.forEach((product: any) => {
			totalQuantity = totalQuantity + product.Quantity;
			totalPrice += getPercentOffPrice(product);
			totalPrice = parseFloat(totalPrice.toFixed(2));
		})
	}
	

	// @ts-ignore
	return (
		<div className='bLoyal-center' style={{ width: '75%', minWidth: '400px' }}>
            <TableContainer>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>Item</strong></TableCell>
							<TableCell align='right'><strong>Unit Price</strong></TableCell>
                            <TableCell align="right"><strong>Quantity</strong></TableCell>
	                        <TableCell align='right'><strong>Price</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
						{products.map((product: any, index: number) => (
							<TableRow key={product.Name}>
								<TableCell component='th' scope='row'>
										   {product.ProductUrl ? <a href={product.ProductUrl} target='_blank' rel={'noopener noreferrer'}>{product.Name}</a> : <>{product.Name}</>}
								</TableCell>
								{/*@ts-ignore*/}
								<TableCell align='right' dangerouslySetInnerHTML={{__html: getUnitPriceDisplay(product)}}></TableCell>
								<TableCell align='right' >
									{product.QuantityType === QuantityType.Options &&
                                    <TableInput options={product.QuantityValue} className={classes.input} id={product.Name + '-quantity'} value={product.Quantity} label='' getValue={updateQuantity} rowNum={index} type='options'/>
									}
									{product.QuantityType !== QuantityType.Options &&
                                    <TableInput className={classes.input} id={product.Name + '-quantity'} value={isNaN(product.Quantity) ? 0 : product.Quantity} label='' getValue={updateQuantity} rowNum={index} type='number'/>
									}
								</TableCell>
								<TableCell align='right'>{`$${getPercentOffPrice(product).toFixed(2)}`}</TableCell>
							</TableRow>
						))}
						<TableRow>
							<TableCell><strong>Totals:</strong></TableCell>
							<TableCell></TableCell>
							<TableCell align='right'><strong>{totalQuantity > 0 ? totalQuantity : 0}</strong></TableCell>
							<TableCell align='right'><strong>{`$${(totalPrice > 0 ? totalPrice : 0).toFixed(2)}`}</strong></TableCell>
						</TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
		</div>
	);
}