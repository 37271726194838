import React, {useEffect, useMemo, useState} from 'react';
import {useSnippetContext} from "../Utils/UseSnippetContext";
import {useAuth} from "../Utils/UseAuth";
import useServiceUrls from "../Utils/UseServiceUrls";
import useGetClubs from "../Utils/UseGetClubs";
import useDeviceSessionProfile from "../Utils/UseDeviceSessionProfile";
import {TrackedCard, WebSnippetProfile} from "../Helpers/Types";
import List from "../Helpers/List";
import useTrackedCards from "../Utils/UseTrackedCards";
import LoadingSpinner from "../LoadingSpinner";
import TextInput from "../TextInput";
import useSaveAndRegisterTrackedCard from "../Utils/UseSaveTrackedCard";

type RegisterCardSnippetProps = {
    snippetProfile: WebSnippetProfile | null
}

export default function RegisterCardSnippet(props: RegisterCardSnippetProps) {
    // @ts-ignore
    const { domain, deviceCode, clubCode } = useSnippetContext();
    // @ts-ignore
    const { session, customer } = useAuth();
    const { serviceUrls } = useServiceUrls(domain);
    const { deviceSessionProfileStatus, deviceSessionProfile, deviceSessionProfileError} = useDeviceSessionProfile(serviceUrls, domain, deviceCode)
    const { trackedCardsStatus, trackedCards, trackedCardsError} = useTrackedCards(serviceUrls, domain, deviceCode, session)
    const snippetProfile = useMemo(() => props.snippetProfile?.RegisterCard, [props.snippetProfile])
    const [selectedCard, setSelectedCard] = useState('')
    const [addCard, setAddCard] = useState(false)
    const [cardNumber, setCardNumber] = useState('')
    
    const [saveTrackedCard, {status, error}] = useSaveAndRegisterTrackedCard()
    
    useEffect(() => {
        if (status === 'success') {
            setAddCard(false)
            setCardNumber('')
        }
    }, [status])
    
    const mapCard = (card: TrackedCard, index: number) => {
        return (
            <div key={index} className={`bLoyal-list-item ${selectedCard === card.Uid ? 'selected' : ''}`} onClick={() => setSelectedCard(card.Uid ?? '')}>
                <div>{`${card.Code} - $${card.Balance}`}</div>
            </div>
        )
    }
    
    const toggleAddCard = () => {
        setAddCard(!addCard)
    }
    
    const saveCard = () => {
        saveTrackedCard({urls: serviceUrls, domain, deviceCode, cardNumber, session, customerUid: customer.Uid})
    }
    
    const deleteCard = () => {
        
    }
    
    const handleChange = (field: string, value: string) => {
        setCardNumber(value)
    }
    
    return (
        <div className={'bLoyal-center'} style={{width: '35%', minWidth: '400px'}}>
            {trackedCards &&
                <>
                    <List data={trackedCards} displayFunction={mapCard} />
                    {!addCard &&
                        <>
                            <button className='bl-snippet-button' onClick={toggleAddCard}>{snippetProfile?.AddButtonLabel}</button>
                            <button className='bl-snippet-button' onClick={deleteCard} disabled={selectedCard === ''}>{snippetProfile?.DeleteButtonLabel}</button>
                        </>
                    }
                </>
            }
            {addCard && 
                <>
                    <TextInput id={'RegisteredCardNumber'} value={cardNumber} label={snippetProfile?.CardNumberLabel ?? "Card Number"} handleChange={handleChange} />
                    <button className='bl-snippet-button' onClick={saveCard}>{snippetProfile?.AddButtonLabel}</button>
                </>
            }
            {(trackedCardsStatus === 'loading' || status === 'loading') && <LoadingSpinner />}
            {status === 'error' && <p className={'bLoyal-error-text'}>{error}</p>}
        </div>
    )
}