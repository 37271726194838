import React, {useEffect, useState} from 'react';
import queryString from "query-string";
import {WebSnippetProfile} from "./Helpers/Types";
import useRecordEngagement from "./Utils/UseRecordEngagement";
import {useSnippetContext} from "./Utils/UseSnippetContext";
import {useStyleContext} from "./Utils/UseStyleContext";
import useServiceUrls from "./Utils/UseServiceUrls";
import {getParameterCaseInsensitive, isStringHtml, mapServiceUrls} from "./Helpers/Functions";

type RecordEngagementSnippetProps = {
    snippetConfig: WebSnippetProfile
}

export default function RecordEngagementSnippet(props: RecordEngagementSnippetProps) {
    const [engagementToken, setEngagementToken] = useState('')
    const [returnUrl, setReturnUrl] = useState(props.snippetConfig.OnSuccessRedirectUrl)
    const [receipts, setReceipts] = useState<Array<string>>([])
    const [mutate, {status, error, data}] = useRecordEngagement()
    // @ts-ignore
    const { domain, deviceCode } = useSnippetContext();
    const { serviceUrls } = useServiceUrls(domain);
    const [isErrorHtml, setIsErrorHtml] = useState(false)
    const [isRecordingHtml, setIsRecordingHtml] = useState(false)
    const [isSuccessHtml, setIsSuccessHtml] = useState(false)
    const [badToken, setBadToken] = useState(false)
    
    useEffect(() => {
        const isHtml = isStringHtml(props.snippetConfig.OnErrorMessage)
        setIsErrorHtml(isHtml)
    }, [props.snippetConfig.OnErrorMessage])    
    
    useEffect(() => {
        const isHtml = isStringHtml(props.snippetConfig.RecordEngagement?.RecordingMessage ?? '')
        setIsRecordingHtml(isHtml)
    }, [props.snippetConfig.RecordEngagement?.RecordingMessage])
    
    useEffect(() => {
        const isHtml = isStringHtml(props.snippetConfig.OnSuccessMessage)
        setIsSuccessHtml(isHtml)
    }, [props.snippetConfig.OnSuccessMessage])

    useEffect(() => {
        const queryString = require('query-string');
        const parsed = queryString.parse(window.location.search);
        const token = getParameterCaseInsensitive(parsed, 'bl_et')  ?? getParameterCaseInsensitive(parsed, 'engagementtoken');
        if (!token) {
            setBadToken(true)
            console.error('Invalid engagement token. Please use query parameter bl_et to pass in a valid token')
        } else {
            setEngagementToken(token)
        }
        setReturnUrl(getParameterCaseInsensitive(parsed, 'bl_rurl') ?? getParameterCaseInsensitive(parsed, 'returnurl'))
    }, []);
    
    useEffect(() => {
        if (engagementToken && serviceUrls)
            mutate({urls: mapServiceUrls(serviceUrls), domain, deviceCode, token: engagementToken})
    }, [engagementToken, domain, deviceCode, serviceUrls])

    useEffect(() => {
        if (status === 'success') {
            setReceipts(data?.data.ReceiptMessage)
            if (returnUrl)
                window.setTimeout(function () {
                    let url = returnUrl.includes("http") ? returnUrl : `https://${returnUrl}?bl_sm=${data?.data.ReciptMessage.join(',') ?? ''}`
                    window.location.assign(url)
                }, (props.snippetConfig.OnSuccessRedirectDelay ?? 0) * 1000)
        }
    }, [status])

    useEffect(() => {
        if (status === 'error') {
            if (props.snippetConfig.OnErrorRedirectUrl)
                window.setTimeout(function () {
                    let url = props.snippetConfig.OnErrorRedirectUrl.includes("http") ? props.snippetConfig.OnErrorRedirectUrl : `https://${props.snippetConfig.OnErrorRedirectUrl}?bl_ec=${error}&bl_em=${error}`
                    window.location.assign(url)
                }, (props.snippetConfig.OnErrorRedirectDelay ?? 0) * 1000)
        }
    }, [status])
    
    const mapReceiptMessage = (message: string, index: number) => {
        const isHtml = isStringHtml(message);
        if (isHtml) {
            return (
                <p className='bl-snippet-receipt' key={index} dangerouslySetInnerHTML={ { __html: message}} />
            )
        }
        return (<p className='bl-snippet-receipt' key={index}>{message}</p>)
    }
    
    return (
        <div className='bLoyal-center bl-container'>
            <h1 className='bl-snippet-title'>{props.snippetConfig.Title}</h1>
            <p className='bl-snippet-body'>{props.snippetConfig.Message}</p>
            {status === 'loading' && !isRecordingHtml &&
                <p>{props.snippetConfig.RecordEngagement?.RecordingMessage}</p>
            }
            {status === 'loading' && isRecordingHtml && <p className='bl-snippet-recording-engagement' dangerouslySetInnerHTML={ { __html: props.snippetConfig.RecordEngagement?.RecordingMessage ?? ''}} />}
            {status === 'success' &&
                <>
                    {!isSuccessHtml && <p className='bl-snippet-success'>{props.snippetConfig.OnSuccessMessage}</p>}
                    {isSuccessHtml && <p className='bl-snippet-success' dangerouslySetInnerHTML={ { __html: props.snippetConfig.OnSuccessMessage}} />}
                    {receipts.map(mapReceiptMessage)}
                </>
            }
            {status === 'error' || badToken && !isErrorHtml &&
                // @ts-ignore
                <p className='bLoyal-error-text bl-snippet-error' >{props.snippetConfig.OnErrorMessage}</p>
            }
            {status === 'error' || badToken && isErrorHtml &&
            <p className='bl-snippet-error' dangerouslySetInnerHTML={ { __html: props.snippetConfig.OnErrorMessage}} />
            }
        </div>
    )
}