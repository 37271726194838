import React, {useEffect, useMemo, useState} from 'react';
import {ClubEditType, WebSnippetProfile} from "../Helpers/Types";
import useServiceUrls from "../Utils/UseServiceUrls";
import {useSnippetContext} from "../Utils/UseSnippetContext";
import SnippetFooter from "../Helpers/SnippetFooter";
import Table from "bloyal-component-library/dist/Tables/Table";
import {useAuth} from "../Utils/UseAuth";
import useSnippetConfig from "../Utils/UseSnippetConfig";
import EditClubModal from "./EditClubModal";
import ClubSignupSnippet from "../ClubSignup/ClubSignupSnippet";
import LoadingSpinner from "../LoadingSpinner";
import SnippetHeader from "../Helpers/SnippetHeader";
import useCloseClub from "../Utils/UseCloseClub";
import useGetClubs from "../Utils/UseGetClubs";
import useClubMemberships from "../Utils/UseClubMemberships";
import useInactivateClub from "../Utils/UseInactivateClub";

type ClubListSnippetProps = {
    snippetProfile: WebSnippetProfile | null
}

export default function ClubListSnippet(props: ClubListSnippetProps) {
    // @ts-ignore
    const { domain, deviceCode, clubCode } = useSnippetContext();
    // @ts-ignore
    const { session, customer } = useAuth();
    const { serviceUrls } = useServiceUrls(domain);
    const { clubsStatus, clubs, clubsError } = useGetClubs(serviceUrls, domain, deviceCode)
    const { memberships, membershipsStatus, membershipsError } = useClubMemberships(serviceUrls, domain, deviceCode, session);
    const snippetProfile = useMemo(() => props.snippetProfile?.ClubList, [props.snippetProfile])
    const [clubSnippetCode, setClubSnippetCode] = useState("")
    const { SnippetConfigStatus, SnippetConfig } = useSnippetConfig(serviceUrls, domain, deviceCode, clubSnippetCode);
    
    const [membershipCode, setMembershipCode] = useState("")
    const [showEditOptions, setShowEditOptions] = useState(false)
    const [missingSignupSnippet, setMissingSignupSnippet] = useState(false)
    const [closeClub, { status, error, reset }] = useCloseClub();
    const [inactivateClub, { status: inactivateStatus, error: inactivateError }] = useInactivateClub();
    
    const club = useMemo(() => clubs?.find((c: any) => c.Code === membershipCode), [clubs, membershipCode])
    const data = useMemo(() => memberships?.filter((m: any) => snippetProfile?.Clubs.some(c => c.Code === m.Club.Code)) ?? [], [memberships])

    useEffect(() => {
        if (clubSnippetCode === '')
            return
        
        if (SnippetConfigStatus === 'success' && !SnippetConfig) {
            if (clubSnippetCode === 'Clubsignup')
                setMissingSignupSnippet(true)
            else 
                setClubSnippetCode('Clubsignup')
        }
            
    }, [SnippetConfigStatus, SnippetConfig]);
    
    const editMembership = (code: string) => {
        setMissingSignupSnippet(false)
        setShowEditOptions(true)
        setMembershipCode(code)
        setClubSnippetCode(`${code}signup`)
    }
    
    const backToTable = () => {
        setMembershipCode('')
        setClubSnippetCode('')
    }
    
    const close = () => {
        setShowEditOptions(false)
        setMembershipCode('')
        setClubSnippetCode('')
    }
    
    const closeClubMembership = () => {
        close()
        closeClub({deviceCode, domain, session, urls: serviceUrls, clubUid: club.Uid, customerUid: customer.Uid})
    }
    
    const getMembershipUid = () => {
        const membership = memberships?.find((m: any) => m.Club.Code === membershipCode)
        return membership.Uid
    }

    const getMembershipStatus = () => {
        const membership = memberships?.find((m: any) => m.Club.Code === membershipCode)
        return membership.Status
    }
    
    const inactivateClubMembership = (months?: string) => {
        const membershipUid = getMembershipUid()
        close();
        inactivateClub({deviceCode, domain, session, urls: serviceUrls, clubUid: club.Uid, customerUid: customer.Uid, months: months ?? '', enableLoyalty: false, membershipUid})
    }
    
    const next = (editType: ClubEditType, months?: string) => {
        if (editType === ClubEditType.Update) {
            setShowEditOptions(false)
        } else if (editType === ClubEditType.Close) {
            closeClubMembership()
        } else {
            inactivateClubMembership(months)
        }
    }

    const columns = useMemo(() => {
        const cols = [
            {
                Header: "Club",
                accessor: 'ActivationDate',
                Cell: (row: any) => {
                    return (<p className='bloyal-table-cell'>{row.row.original.Club.Name}</p>)
                }
            },
            {
                Header: "Membership Status",
                accessor: 'Status',
                Cell: (row: any) => {
                    return (
                        <p className='bloyal-table-cell'>{row.row.original.Status === 'LoyaltyOnly' ? 'Suspended' : row.row.original.Status}</p>)
                }
            },
        ]
    
        if (snippetProfile?.AllowSuspend || snippetProfile?.AllowUpdate || snippetProfile?.AllowClose)
            cols.push({
                Header: "",
                accessor: 'Uid',
                Cell: (row: any) => <button onClick={() => editMembership(row.row.original.Club.Code)}>Edit</button>
            })
    
        return cols
    }, [])

    return (
        <>
            {snippetProfile && props.snippetProfile && customer &&
                <div className='bLoyal-center' style={{padding: '0 10px'}}>
                    {membershipCode && showEditOptions && <EditClubModal profile={snippetProfile} close={close} next={next} label={getMembershipStatus() === 'Active' ? 'Update' : 'Reactivate'}/>}
                    {membershipCode && SnippetConfig && !showEditOptions && <ClubSignupSnippet snippetProfile={SnippetConfig} clubCode={membershipCode} back={backToTable}/>}
                    {membershipCode && SnippetConfigStatus === 'loading' && <LoadingSpinner />}
                    {(!membershipCode || showEditOptions || missingSignupSnippet) &&
                        <>
                            <SnippetHeader logoUrl={props.snippetProfile.LogoUrl} title={props.snippetProfile.Title} message={props.snippetProfile.Message} snippetCode={props.snippetProfile.Code}/>
                            <Table columns={columns} data={data} />
                            <SnippetFooter footer={props.snippetProfile.Footer} snippetCode={props.snippetProfile.Code}/>
                            {missingSignupSnippet ? <p className={'bLoyal-error-text'}>No club signup snippet found.</p> : null}
                        </>
                    }
                    {(status === 'loading' || membershipsStatus === 'loading' || inactivateStatus === 'loading') && <LoadingSpinner />}
                </div>
            }
        </>
    )
}