import {useMutation} from 'react-query';
import {client} from './ApiClient';
import {ClubPreferences} from "../Helpers/Types";

const setClubPreferences = ({urls, domain, deviceCode, cartUid, cartExternalId, cartSourceExternalId, lineUid, preferences}: {urls: any; domain: string; deviceCode: string; cartUid: string; cartExternalId: string; cartSourceExternalId: string; lineUid: string; preferences: ClubPreferences}) => {
    return client(urls.LoyaltyEngineApiUrl, `api/v4/${domain}/${deviceCode}/carts/commands/setclubpreferences`, {
        body: {
            CartUid: cartUid,
            CartExternalId: cartExternalId,
            CartSourceExternalId: cartSourceExternalId,
            LineUid: lineUid,
            ...preferences
        }
    });
}

export default function useSetClubPreferences() {
    // @ts-ignore
    return useMutation(setClubPreferences)
}