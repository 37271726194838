import {useQuery} from 'react-query';
import {client} from './ApiClient';
import {mapServiceUrls} from '../Helpers/Functions';

const getClubs = (key: any, urls: any, domain: string, deviceCode: string) => {
	return client(urls.LoyaltyEngineApiUrl, `api/v4/${domain}/${deviceCode}/clubs`);
}

export default function useGetClubs(urls: any, domain: string, deviceCode: string) {
	const {status, data, error } = useQuery(['Clubs'], (key) => getClubs(key, mapServiceUrls(urls), domain, deviceCode), { enabled: urls, staleTime: 1000*60*60 });
	const clubsStatus = status;
	const clubs = data ? data.data : undefined;
	const clubsError = error;
	return {clubsStatus, clubs, clubsError};
}
