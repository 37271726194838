import React, {Fragment, SyntheticEvent, useEffect, useReducer, useState} from 'react';
import {
	IAddressState,
	ICountry,
	ICustomer,
	IInputConfig,
	ISelectOption,
	IShippingAddress,
	IState,
	ISyntheticEvent
} from './Helpers/Types';
import {AddressAction, AddressActionTypes, copyAddress, updateFieldAction} from './AddressActions';
import useSaveShippingAddress from './Utils/UseSaveShippingAddress';
import useServiceUrls from './Utils/UseServiceUrls';
import {doesCountryHaveStates, flattenAddress, formatDate, languageCode, mapServiceUrls} from './Helpers/Functions';
import {useSnippetContext} from './Utils/UseSnippetContext';
import useCountries from './Utils/UseCountries';
import useStates from './Utils/UseStates';
import SelectInput from "./SelectInput";
import TextInput from "./TextInput";
import {useDeviceSessionContext} from './Utils/UseDeviceSessionProfileContext';
import {v4 as uuid} from 'uuid'
import {mapInputField} from "./Helpers/ComponentFunctions";

export const addressState: IAddressState = {
	ShippingAddress: {
		Uid: '',
		CustomerUid: '',
		ExternalId: '',
		Title: '',
		IsPrimary: false,
		FirstName: '',
		LastName: '',
		Company: '',
		Address: {
			Address1: '',
			Address2: '',
			City: '',
			State: '',
			PostalCode: '',
			Country: '',
		},
		EmailAddress: '',
		Phone1: '',
		Phone2: '',
		MobilePhone: '',
		FaxNumber: '',
		BirthDate: '',
		Instructions: '',
		IsClubAddress: false
	}
};

	export const addressReducer = (state = addressState, action: AddressAction): IAddressState => {
	switch (action.type) {
		case AddressActionTypes.CopyAddress:
			let birthDate = formatDate(action.customer.BirthDate ? action.customer.BirthDate : '') ?? '';
			
			let address = {
				ShippingAddress: {
					...state.ShippingAddress,
					CustomerUid: action.customer.Uid,
					FirstName: action.customer.FirstName,
					LastName: action.customer.LastName,
					Company: action.customer.CompanyName ? action.customer.CompanyName : '',
					EmailAddress: action.customer.EmailAddress,
					Phone1: action.customer.Phone1,
					MobilePhone: action.customer.MobilePhone,
					BirthDate: birthDate,
					Address: {
						...state.ShippingAddress.Address,
						...action.customer.Address,
					}
				}
			};
			if (address.ShippingAddress.Address.Country === null)
				address.ShippingAddress.Address.Country = '';
			if (address.ShippingAddress.Address.State === null)
				address.ShippingAddress.Address.State = '';
			return address;
		case AddressActionTypes.UpdateField:
			const field = action.fieldName;
			if (field === 'Address1' || field === 'Address2' || field === 'City' || field === 'State' || field === 'PostalCode' || field === 'Country')
				return {
					...state,
					ShippingAddress: {
						...state.ShippingAddress,
						Address: {
							...state.ShippingAddress.Address,
							[action.fieldName]: action.value
						}
					}
				}
			
			return {
				...state,
				ShippingAddress: {
					...state.ShippingAddress,
					[action.fieldName]: action.value
				}
			};
		default:
			return state;
	}
};

type AddressProps = {
	address: IShippingAddress | null,
	toggleEdit: (type: string) => void,
	sessionKey: string,
	customer: ICustomer
	fields: Array<IInputConfig>
	isGift?: boolean
	onSuccessMessage?: string
}

export default function Address(props: AddressProps) {
	// @ts-ignore
	const {profile} = useDeviceSessionContext()
	let initialState = { ...addressState, ShippingAddress: { ...addressState.ShippingAddress} };
	if (props.address !== null) {
		initialState = {
			...initialState,
			ShippingAddress: {
				...addressState.ShippingAddress,
				...props.address,
				BirthDate: formatDate(props.address.BirthDate) ?? ''
			}
		};
	}
	if (initialState.ShippingAddress.Address.Country === '' || initialState.ShippingAddress.Address.Country === null)
		initialState.ShippingAddress.Address.Country = profile.DefaultCountry; 
	const [state, dispatch] = useReducer(addressReducer, initialState);
	// @ts-ignore
	const { domain, deviceCode } = useSnippetContext();
	const {serviceUrls } = useServiceUrls(domain);
	const [mutate, {status, data}] = useSaveShippingAddress();

	const { countries, countriesStatus } = useCountries(serviceUrls, domain, deviceCode);
	const { states } = useStates(serviceUrls, domain, deviceCode, state.ShippingAddress.Address.Country, countries && doesCountryHaveStates(countries, state.ShippingAddress.Address.Country));

	const [countryOptions, setCountryOptions] = useState<Array<ISelectOption>>([]);
	const [stateOptions, setStateOptions] = useState<Array<ISelectOption>>([]);
	
	useEffect(() => {
		if (status === 'success') {
			window.setTimeout(function () {
				props.toggleEdit(data.data.ShippingAddress);
			}, (1500))
		}
	}, [status])

	useEffect(() => {
		if (!countries)
			return;

		const countryList = countries.map((country: ICountry) => {
			return {
				value: country.Code,
				text: country.Name
			};
		});

		setCountryOptions(countryList);
	}, [countries]);

	useEffect(() => {
		if (!states)
			return;

		const stateList = states.map((state: IState) => {
			return {
				value: state.Code,
				text: state.Name
			};
		});

		setStateOptions(stateList);
	}, [states]);

	const handleChange = (fieldName: string, value: string) => {
		if (fieldName === 'addressTitle') {
			// @ts-ignore
			dispatch(updateFieldAction('Title', value));
		}
		else {
			// @ts-ignore
			dispatch(updateFieldAction(fieldName, value));
		}
	};

	const saveAddress = (event: ISyntheticEvent) => {
		event.preventDefault();
		mutate({ urls: mapServiceUrls(serviceUrls), session: props.sessionKey, domain, deviceCode, address: state.ShippingAddress });
	};
	
	const cancel = (event: SyntheticEvent) => {
		event.preventDefault()
		props.toggleEdit('')
	}
	
	const copyFromCustomer = (e: ISyntheticEvent) => {
		e.preventDefault();
		// @ts-ignore
		dispatch(copyAddress(props.customer));
	}

	const mapContactField = (field: IInputConfig, index: number) => {
		if (!field.IsIncluded)
			return;

		return mapInputField(field, index, flattenAddress(state.ShippingAddress), stateOptions, countryOptions, countries, states, handleChange)
	};

	return (
		<div className='bLoyal-form bLoyal-center'>
			<button className='bl-snippet-button' onClick={copyFromCustomer} >Copy from Contact</button>
			{props.address?.IsClubAddress && <p className={'message'}>This address is currently used on a club.</p>}
			<form onSubmit={saveAddress}>
				<br />
				{countriesStatus === 'success' &&
				<div className='bLoyal-center bLoyal-form'>
					<div className='bLoyal-center bLoyal-input-holder'>
						{props.fields.map(mapContactField)}
					</div>
				</div>
				}
				<br/>
				<button className='bl-snippet-button' onClick={cancel}>Cancel</button>
				<button className='bl-snippet-button' type='submit'>Save</button>
			</form>
			{status === 'success' &&
				<p className='message'>{props.onSuccessMessage ?? "Success"}</p>
			}
		</div>
	);
}