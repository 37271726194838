import React, {useEffect, useState} from 'react';
import {InputLabel, MenuItem, Select} from '@material-ui/core';
import {useStyleContext} from "./Utils/UseStyleContext";
import {SnippetTheme} from "./Helpers/Types";

type SelectInputProps = {
    options: { text: string, value: string }[],
    label: string,
    current: string,
    fieldName: string,
    handleChange: Function,
    disabled: boolean,
    width?: number,
    required?: boolean,
    isTableInput?: boolean,
    removeWidth?: boolean,
    invalid?: boolean
}

export default function SelectInput(props: SelectInputProps) {
    const [value, setValue] = useState(props.current)
    // @ts-ignore
    const {theme} = useStyleContext()

    const handleChange = (event: any) => {
        const value = event.target.value as string
        setValue(value);
        props.handleChange(props.fieldName, value, event)
    };

    useEffect(() => {
        setValue(props.current)
    }, [props, value])

    switch (theme) {
        case SnippetTheme.MaterialUI:
            return (
                <div style={{width: props.width ? `${props.width}px` : '100%'}} className='bLoyal-center'>
                    {value !== '' &&
                        <InputLabel shrink={value !== ''} htmlFor={props.fieldName} style={{
                            width: '100%',
                            marginTop: "5px",
                            marginBottom: "0px",
                            float: 'left',
                            textAlign: 'left'
                        }}>{props.label}</InputLabel>
                    }
                    <Select
                        id={props.fieldName}
                        style={{width: '100%', marginTop: value === '' ? '15px' : '0px', marginBottom: '5px'}}
                        value={value}
                        onChange={handleChange}
                        disabled={props.disabled}
                        displayEmpty
                        className={props.invalid === true ? 'bLoyal-invalid-input' : ''}
                    >
                        <MenuItem value=''><em style={{color: 'rgba(0, 0, 0, 0.54)', float: 'left'}}>{props.label}</em></MenuItem>
                        {props.options.map(option => {
                            return (
                                <MenuItem key={`${option.value}-${option.text}`}
                                          value={option.value}>{option.text}</MenuItem>
                            )
                        })}
                    </Select>
                </div>
            );
        default:
            return (<div style={{width: props.width ? `${props.width}px` : '100%'}}
                         className={props.isTableInput ? '' : 'bLoyal-center'}>
                <label style={props.removeWidth ? {} : {width: '100%'}}>{props.label + (props.required ? "* " : " ")}
                    <select id={props.fieldName} onChange={handleChange} disabled={props.disabled}
                            style={{width: props.width ? `${props.width}px` : '100%'}} value={value}
                            required={props.required} className={props.invalid === true ? 'bLoyal-invalid-input' : ''}>
                        <option value={''} disabled>{"Select " + props.label}</option>
                        {props.options.map((option, index) => {
                            return (
                                <option key={index} value={option.value}>{option.text}</option>
                            )
                        })}
                    </select>
                </label>
            </div>)
    }
}