import React, {MouseEventHandler, SyntheticEvent, useState} from 'react';
import {Dialog, DialogActions, DialogTitle, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import {DialogContent} from "@mui/material";
import {ClubEditType, ClubListSnippetProfile, ISyntheticEvent} from "../Helpers/Types";
import {checkValidDate, isEmpty} from "../Helpers/Functions";
import SelectInput from "../SelectInput";

type EditClubModalProps = {
    close: MouseEventHandler
    next: (value: ClubEditType, restartDate?: string) => void
    label: string
    profile: ClubListSnippetProfile
}

const monthOptions = () => {
    return [
        {
            text: '1',
            value: '1'
        },
        {
            text: '2',
            value: '2'
        },
        {
            text: '3',
            value: '3'
        },
        {
            text: '4',
            value: '4'
        },
        {
            text: '5',
            value: '5'
        },
        {
            text: '6',
            value: '6'
        },
        {
            text: '7',
            value: '7'
        },
        {
            text: '8',
            value: '8'
        },
        {
            text: '9',
            value: '9'
        },
        {
            text: '10',
            value: '10'
        },
        {
            text: '11',
            value: '11'
        },
        {
            text: '12',
            value: '12'
        },
    ]
}

export default function EditClubModal(props: EditClubModalProps) {
    const [value, setValue] = useState(props.profile.AllowUpdate ? ClubEditType.Update : props.profile.AllowSuspend ? ClubEditType.Suspend : ClubEditType.Close)
    const [months, setMonths] = useState('1')
    const [missingMonths, setMissingMonths] = useState(false)

    const handleChange = (event: ISyntheticEvent) => {
        if (missingMonths)
            setMissingMonths(false)
        
        setValue(event.target.value);
    };
    
    const handleInputChange = (field: string, value: string) => {
        if (missingMonths)
            setMissingMonths(false)
        
        setMonths(value)
    }
    
    const next = (event: SyntheticEvent) => {
        event.preventDefault();
        if (value !== ClubEditType.Suspend)
            props.next(value)
        else {
            if (months)
                props.next(value, months)
            else
                setMissingMonths(true)
        }
    }
    
    return (
        <Dialog maxWidth='md' open={true} fullWidth={true} scroll='paper'>
            <DialogTitle>Edit Club Membership</DialogTitle> 
            <DialogContent>
                <RadioGroup aria-label="clubEditSelector" name="editClubType" value={value} onChange={handleChange}>
                    {props.profile.AllowUpdate ? <FormControlLabel value={ClubEditType.Update} control={<Radio/>} label={props.label === 'Update' ? isEmpty(props.profile.UpdateLabel) ? 'Update' : props.profile.UpdateLabel : isEmpty(props.profile.ReactivateLabel) ? 'Reactivate' : props.profile.ReactivateLabel}/> : null}
                    {props.profile.AllowSuspend ? <FormControlLabel value={ClubEditType.Suspend} control={<Radio/>} label={isEmpty(props.profile.SuspendLabel) ? 'Suspend' : props.profile.SuspendLabel}/> : null}
                    {props.profile.AllowClose ? <FormControlLabel value={ClubEditType.Close} control={<Radio/>} label={isEmpty(props.profile.CloseLabel) ? 'Close' : props.profile.CloseLabel}/> : null}
                </RadioGroup>
                {value === ClubEditType.Suspend ? <div className={'bLoyal-input-container'}><SelectInput options={monthOptions()} label={"Months to suspend for"} current={months} fieldName={'Months'} handleChange={handleInputChange} disabled={false} required={false} /> </div> : null}
                {missingMonths ? <p className={'bLoyal-error-text'}>Please choose a number of months to suspend your membership for</p> : null}
            </DialogContent> 
            <DialogActions>
                <button className='bl-snippet-button' onClick={props.close}>
                    Cancel
                </button>
                <button className='bl-snippet-button' onClick={next}>
                    Next
                </button>
            </DialogActions>
        </Dialog>
    )
}