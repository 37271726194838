import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {ICountry, IInputConfig, ISyntheticEvent, LoginType} from './Helpers/Types';
import TextInput from './TextInput';
import PasswordField from './PasswordField';
import useServiceUrls from './Utils/UseServiceUrls';
import {getDisplayValue, mapServiceUrls} from './Helpers/Functions';
import useSnippetConfig from './Utils/UseSnippetConfig';
import {useSnippetContext} from './Utils/UseSnippetContext';
import LoadingSpinner from './LoadingSpinner';
import useLogin from './Utils/UseLogin';
import {useAuth} from './Utils/UseAuth';
import ResetPassword from "./ResetPassword";
import {parse} from "query-string";
import SnippetHeader from "./Helpers/SnippetHeader";
import SnippetFooter from "./Helpers/SnippetFooter";
import EmailInput from "./EmailInput";
import SelectInput from "./SelectInput";

type LoginProps = {
	onSuccess: (config: any) => string | null
	onError: (config: any) => string | null
	snippetCode: string
	setSignupSnippet: Function
	loginType: LoginType
	remoteLoginUrl?: string
}

export default function Login(props: LoginProps) {
	//@ts-ignore
	const { domain, deviceCode, returnUrlParam, loginUrl } = useSnippetContext();
	const [remoteLogin, ] = useState(props.loginType === LoginType.ConfiguredUrl || props.loginType === LoginType.Undefined && props.remoteLoginUrl);
	//@ts-ignore
	const { setSession, session } = useAuth();
	const { serviceUrls } = useServiceUrls(domain);
	const [login, {  isLoading, data, isError, error, isSuccess }] = useLogin();
	const { SnippetConfig } = useSnippetConfig(serviceUrls, domain, deviceCode, props.snippetCode);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [showMessage, setShowMessage] = useState(false);
	const [message, setMessage] = useState('');
	const [showResetPassword, setShowResetPassword] = useState(false);
	const [validLogin, setValidLogin] = useState(false)
	const [loginError, setLoginError] = useState('')
	const [loginQueryUrl, setLoginQueryUrl] = useState('')

	useEffect(() => {
		const params = parse(window.location.search)
		if (params.bl_lurl) {
				// @ts-ignore
				setLoginQueryUrl(params.bl_lurl)
		}
	}, [])

	useEffect(() => {
		if (!loginQueryUrl  && !loginUrl && !props.remoteLoginUrl && remoteLogin) {
			setValidLogin(false)
			setLoginError('No Login url provided with login type set to external url. Please provide a login url as a query param, on the snippet element or in the configuration')
		}
		setValidLogin(true)
	}, [loginQueryUrl, loginUrl, props.remoteLoginUrl, remoteLogin])
	
	useEffect(() => {
		if (remoteLogin && !session && validLogin) {
			let url = props.remoteLoginUrl ? props.remoteLoginUrl : loginUrl ? loginUrl : loginQueryUrl
			const isValid = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi.test(url)
			if (!isValid) {
				if (url.includes('localhost'))
					window.location.assign(`${url}?${returnUrlParam}=${window.location.protocol + '//' + window.location.host + window.location.pathname}`);
				else {
					setValidLogin(false)
					setLoginError('The url provided is not a valid url')
				}
			} else {
				url = url.includes('http') ? url : `https://${url}`
				window.location.assign(`${url}?${returnUrlParam}=${window.location.protocol + '//' + window.location.host + window.location.pathname}`);
			}
		}
	}, [remoteLogin, session, validLogin])
	
	
	const config = useMemo(() => SnippetConfig?.Login ?? SnippetConfig?.LegacyLogin, [SnippetConfig])

	function onSuccess(data: any) {
		const value = props.onSuccess(config);
		if (value === null) {
			setSession(data.data.SessionKey, data.data.Customer);
			return;
		}
		setMessage(value);
		setShowMessage(true);
	}

	function onError(data: any) {
		props.onError(config);
	}

	const submit = (e: ISyntheticEvent) => {
		e.preventDefault();
		login({ urls: mapServiceUrls(serviceUrls), domain, deviceCode, password, email });
	};

	const signup = (e: ISyntheticEvent) => {
		e.preventDefault();
		props.setSignupSnippet(config.SignupSnippetCode);
	};
	
	const closeResetPassword = () => {
		setShowResetPassword(false);
	}

	useEffect(() => {
		if (data)
			onSuccess(data);

		if (error)
			onError(error);
	}, [data, error]);


	
	if (!validLogin)
		return (
			<p className='bLoyal-error-text'>
				{`Configuration Error: ${loginError}`}
			</p>
		)
	
	if (!remoteLogin && !props.snippetCode)
		return (
			<p className='bLoyal-error-text'>
				{`Configuration Error: You must set a snippet code or change the login type to configured url`}
			</p>
		)
	
	const handleChange = (field: string, value: string) => {
		switch (field) {
			case 'Password':
				setPassword(value)
				break;
			case 'EmailAddress':
				setEmail(value)
				break;
		}
	}

	const mapField = (field: IInputConfig, index: number) => {
		if (!field.IsIncluded && !field.IsRequired)
			return;

		let value = field.FieldName === 'EmailAddress' ? email : password
		let inputField: any;
		const label = field.Label ? field.Label : getDisplayValue(field.FieldName);
		switch (field.FieldName) {
			case 'Password':
				inputField = <>
					<PasswordField required={true} id={field.FieldName} value={''} label={label}
								   handleChange={handleChange}/>
				</>;
				break;
			case 'EmailAddress':
				inputField = <EmailInput required={field.IsRequired} id={field.FieldName} value={value} label={label} handleChange={handleChange}/>;
				break;
			default:
				inputField = <TextInput required={field.IsRequired} id={field.FieldName} value={value} label={label} handleChange={handleChange}/>;
		}
		return (
			<Fragment key={index}>
				{inputField}
				<br/>
			</Fragment>
		);
	};

	return (
		<>
			{showResetPassword && <ResetPassword emailAddress={email} back={closeResetPassword} />}
			{!showResetPassword &&
				<div className='bLoyal-center login-div'>
					{!remoteLogin &&
					<>
						{!config &&
						<LoadingSpinner/>
						}
						{config &&
						<>
							{!showMessage &&
							<>
								<SnippetHeader logoUrl={SnippetConfig.LogoUrl} title={SnippetConfig.Title} snippetCode={SnippetConfig.Code} message={SnippetConfig.Message} />
								<form onSubmit={submit}>
									{config.LoginFields.map(mapField)}
									<button className='bl-snippet-button' id={'login-button'} disabled={!email || !password}
											type='submit'>{config.LoginButtonText}</button>
									<br/>
									{config.AllowSignup && <button className='bl-snippet-button' id={'signup-button'} onClick={signup}>{config.SignupButtonText}</button>}
								</form>
								<button className='button-as-link' onClick={() => setShowResetPassword(true)}>Reset
									Password
								</button>
							</>
							}
							{showMessage &&
							<p>{message}</p>
							}
						</>
						}
	
						{isLoading &&
						<LoadingSpinner/>
						}
					</>
					}
					{isError &&
					<p style={{color: 'red'}}>Login Error: {error}</p>
					}
					<SnippetFooter snippetCode={SnippetConfig.Code} footer={SnippetConfig.Footer} />
				</div>
			}
		</>
	);
}