import React from 'react';
import SelectInput from './SelectInput';
import {IShippingAddress, ISyntheticEvent} from './Helpers/Types';
import AddressPreview from './AddressPreview';
import {MDBCol, MDBRow} from 'mdbreact';

type AddressSelectorProps = {
	disabled: boolean,
	options: { text: string, value: string }[],
	value: string,
	handleChange: Function,
	editAddress: (type: string) => void,
	address: IShippingAddress,
	editing: boolean
}

export default function AddressSelector(props: AddressSelectorProps) {

	const handleChange = (fieldName: string, value: string) => {
		props.handleChange('ShippingAddress', value);
	};

	const editAddress = (event: ISyntheticEvent, type: string) => {
		event.preventDefault();
		props.editAddress(type);
	};

	return (
		// <div className={`shipping-select`}>
		<>
				<MDBCol md='10'>
					<SelectInput
						options={props.options}
						label="Choose your shipping address"
						current={props.value}
						handleChange={handleChange}
						fieldName='ShippingAddress'
						disabled={props.disabled}
					/>
				</MDBCol>
				<MDBCol md='12'>
					<button className='bl-snippet-button' disabled={props.disabled} onClick={e => editAddress(e, 'new')}>New</button>
					<button className='bl-snippet-button' disabled={props.value === '' || props.disabled} onClick={e => editAddress(e, 'edit')}>Edit</button>
				</MDBCol>
			<br/>
			{props.value !== '' && !props.editing &&
            <AddressPreview address={props.address} disabled={false}/>
			}
		</>
		// </div>
	);
}