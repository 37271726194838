import {LoginActionTypes} from '../LoginActions';
import {Dayjs} from "dayjs";

export interface ISyntheticEvent {
	preventDefault: Function
	persist: Function
	target: any

}

export interface ILoginState {
	Config: ILoginConfig,
	LoginResponseStatus: ResponseStatus,
	LoginResponse: IBLResponse | null,
	CurrentAction: LoginActionTypes
}

export interface ILoginConfig {
	OnSuccessAction: OnSuccessAction,
	OnSuccessValue: string,
	OnErrorAction: OnErrorAction,
	OnFailureValue: string
}

export enum OnSuccessAction {
	Message = 'Message',
	Redirect = 'Redirect',
	Snippet = 'Snippet',
}

export enum OnErrorAction {
	Redirect = 'Redirect',
	Snippet = 'Snippet',
}

export enum ResponseStatus {
	NoRequest = 'NoRequest',
	Success = 'Success',
	Error = 'Error',
}

export enum Status {
	Loading = 'Loading',
	InUse = 'InUse',
	Confirmation = 'Confirmation',
	Error = 'Error',
	Success = 'Success',
}

export interface IWizardState {
	FormData: IWizardFormData,
	Status: Status,
	PageNum: number,
	HasSkippedMemberContact: boolean,
	Membership: any,
	HasSetPayment: boolean,
	MembershipType: MembershipType,
	GiftRecipient: any,
	GiftRecipientSessionKey: string,
	HasSetDefaultQuantities: boolean
}

export interface IWizardFormData {
	Fulfillment: IFulfillmentData,
	Payment: IPaymentData,
	ProductPreferences: Array<ProductPreference>,
	ProductPreferenceType: ProductPreferenceType,
	MemberProductPreferenceType: ProductPreferenceType,
	CustomFields: object,
}

export interface IBLResponse {
	status: string,
	message?: string,
	data?: any,
}

interface IWizardCol {
	FieldName: string,
	Label: string,
}

interface IWizardPageRow {
	Cols: Array<IWizardCol>
}

export interface IWizardPage {
	Title: string,
	Type: string,
	Rows?: Array<IWizardPageRow>
}

export interface IWizardPageMap {
	Pages: Array<Array<IWizardPage | null>>
	StartPage: IWizardPage
}

export interface IAddressState {
	[x: string]: any,

	ShippingAddress: IShippingAddress,
}

export interface IShippingAddress {
	Uid: string | null,
	CustomerUid: string,
	Address: IAddress,
	ExternalId: string,
	Title: string,
	IsPrimary: boolean,
	FirstName: string,
	LastName: string,
	Company: string,
	EmailAddress: string,
	Phone1: string,
	Phone2: string,
	MobilePhone: string,
	FaxNumber: string,
	BirthDate: string,
	Instructions: string,
	IsClubAddress: boolean
}

export interface IAddress {
	Address1: string,
	Address2: string,
	City: string,
	State: string,
	PostalCode: string,
	Country: string,
}

export interface IInputConfig {
	FieldName: string,
	IsRequired: boolean,
	IsIncluded: boolean,
	Label: string,
}

export enum DeliveryType {
	None = 'None',
	Pickup = 'Pickup',
	Ship = 'Ship',
}

export interface IShippingState {
	OrderType: DeliveryType,
	ShippingAddressUid: string,
	InventoryLocationUid: string,
}

export interface IQuickSignupField {
	Name: string,
	IsRequired: boolean,
	IsIncluded: boolean,
	GroupValue: number,
	Editable: string,
	Type: string,
	Code: string,
}

export interface ISignupState {
	FirstName: string,
	FirstName2: string,
	LastName: string,
	LastName2: string,
	EmailAddress: string,
	MobilePhone: string,
	Password: string,
	Address1: string,
	Address2: string,
	City: string,
	State: string,
	PostalCode: string,
	Country: string,
	CompanyName: string,
	Phone1: string,
	LoyaltyCardNumber: string,
	BirthDate: string,
	BirthdayMonth1: string,
	BirthdayMonth2: string,
	VerifyPassword?: string,
	CustomFields: Array<any>
	SubscriberGroups: Array<string>
	Zip?: string
}

export interface ILESignupCommand {
	Uid: string,
	FirstName: string | null,
	FirstName2: string | null,
	LastName: string | null,
	LastName2: string | null,
	EmailAddress: string | null,
	MobilePhone: string | null,
	Password: string | null,
	Address: IAddress | null,
	CompanyName: string | null,
	Phone1: string | null,
	LoyaltyCardNumber: string | null,
	BirthDate: string | null,
	CustomFields: object
	GroupsToJoin: Array<string>
}

export interface IClubMembership {
	Club: {
		Uid: string,
		ExternalId: string,
		Code: string,
		Name: string
	},
	Status: string,
	StartDate: Date,
	ActivationDate: Date | null,
	ExpirationDate: Date | null,
	CloseDate: Date | null,
}

export interface ICustomer {
	Id: number,
	Uid: string,
	ExternalId: string | null,
	PartitionUid: string | null,
	PartitionCode: string,
	Code: string,
	FirstName: string,
	LastName: string,
	FirstName2: string | null,
	LastName2: string | null,
	Address: IAddress,
	AddressModified: boolean,
	AddressValidated: boolean,
	CurrentLoyaltyPoints: number,
	CurrentLoyaltyCurrency: number,
	CompanyName: string | null,
	Phone1: string,
	MobilePhone: string,
	EmailAddress: string,
	FacebookId: string,
	TwitterId: string | null,
	MobileDeviceId: string | null,
	LoyaltyRedemptionDisabled: boolean,
	LoyaltyAccrualDisabled: boolean,
	LoyaltyCardNumber: string,
	GuestCustomer: boolean,
	EditAtPOS: boolean,
	WebAccount: boolean,
	Verified: boolean,
	TaxExempt: boolean,
	BirthDate: Date | null,
	LoyaltyPointsAccounts: Array<any>,
	LoyaltyCurrencyAccounts: Array<any>,
	Type: {
		Uid: string,
		ExternalId: string,
		Code: string,
		Name: string
	} | null,
	PriceLevel: {
		Uid: string,
		Code: string,
		Name: string
	} | null,
	TransactionCount: number,
	TotalSales: number,
	ClubMemberships: Array<IClubMembership>,
	SubscriberGroupMemberships: Array<any>,
	LoyaltytProgramMemberships: Array<any>,
	LoyaltyProgramMemberships: Array<any>,
	SignupChannelUid: string,
	SignupStoreUid: string,
	SignupStoreCode: string,
	SignupStoreExternalId: string | null,
	NoEmail: string,
	Salutation: string | null,
	ParentCustomerUid: string | null,
	ParentCustomerCode: string | null,
	ParentExternalId: string | null,
	ReferralCustomer1Uid: string | null,
	ReferralCustomer1Code: string | null,
	ReferralCustomer2Uid: string | null,
	ReferralCustomer2Code: string | null,
	CustomCode1: string | null,
	CustomCode2: string | null,
	CustomCode3: string | null,
	CustomCode4: string | null,
	CustomCode5: string | null,
	CustomCode6: string | null,
	CustomCode7: string | null,
	CustomCode8: string | null,
	AlertCount: number,
	Created: Date | null,
	CreatedLocal: Date | null
}

export interface IMemberContactState {
	Customer: ICustomer,
	HasChanged: boolean,
	CustomFields: Array<any>
}

export enum MembershipType {
	Gift = 'Gift',
	Standard = 'Standard'
}

export enum ClubEditType {
	Suspend = "Suspend",
	Close = "Close",
	Update = "Update"
}

export type AvailableCoupon = {
	QuantityAvailable: number | null
	QuantityApplied: number
	Uid: string
	ExternalId: string
	Code: string
	Name: string
	Type: CouponType
}

export enum CouponType {
	Undefined = "Undefined",
	Standard = "Standard",
	Awarded = "Awarded"
}

export interface ICountry {
	Uid: string,
	Code: string,
	Name: string,
	HasStates: boolean,
}

export interface IState {
	Uid: string,
	CountryUid: string,
	Code: string,
	Name: string,
}

export interface ISelectOption {
	text: string,
	value: string,
	checked?: boolean
}

export interface IPaymentType {
	EnumValue: string,
	Enumkey: string,
	IsDisplay: boolean
}

export type BillingAddress = {
	BillingName: string,
	BillingAddress1: string,
	BillingAddress2: string,
	BillingCity: string,
	BillingState: string,
	BillingPostalCode: string,
	BillingCountry: string,
}

export type PaymentBillingAddress = {
	FirstName: string,
	LastName: string,
	Address: IAddress,
}

export enum QuantityType {
	Unlimited = 'Unlimited',
	Max = 'Max',
	Options = 'Options',
}

export enum PaymentType {
	Undefined = '',
	GiftCard = 'GiftCard',
	CreditCard = 'CreditCard',
}

export enum PaymentSource {
	Default = 'Default',
	Sponsor = 'Sponsor',
}

export enum ProductPreferenceType {
	Undefined = 'Undefined',
	Default = 'Default',
	Replace = 'Replace',
	Append = 'Append'
}

export type EGiftCard = {
	Id: string
	ImageUrl: string
	RecipientName: string
	RecipientEmail: string
	Message: string
	SenderName: string
	SenderEmail: string
	SendDate: Dayjs
	Amount: string
	CustomAmount?: number
}

export interface ProductPreference {
	ProductUid: string,
	Quantity: number,
}

export interface IFulfillmentData {
	DeliveryType: DeliveryType,
	ShippingAddressUid: string,
	PickupLocationUid: string,
}

export interface IPaymentData {
	Source: PaymentSource,
	PaymentMethodUid: string
}

export type CartPayment = {
	Uid: string,
	TenderUid: string,
	TenderExternalId: string,
	TenderCode: string,
	MethodUid: string
	Amount: number,
	Captured: boolean,
	AuthCode: string,
	TransactionCode: string,
	PaymentToken: string,
	Info: PaymentInfo,
	AmountAuthorized?: number,
	AuthorizationSubscriptionUid: string
	
}

export type PaymentInfo = {
	CreditCard: CreditCardInfo,
	GiftCard: GiftCardInfo,
	ACH: ACHInfo,
}

export type CreditCardInfo = {
	Type: CreditCardType,
	Name: string,
	Mask: string,
	Expiration: string,
	Token: string
}

export type GiftCardInfo = {
	Mask: string
}

export type ACHInfo = {
	Type: BankAccountType,
	Name: string,
	AccountMask: string,
	RoutingNumber: string
}

export enum PasswordState {
	Unsubmitted = 'Unsubmitted',
	Matching = 'Matching',
	Unmatching = 'Unmatching',
}

export interface ICustomField {
	Uid: string,
	ExternalId: string,
	Code: string,
	Name: string,
	HelpText: string,
	Description: string,
	FieldType: ICustomFieldType,
	Scope: ICustomEntityScope,
	ValidValues: Array<any>
}

export enum ICustomFieldType
{
	Unknown = "Unknown",
	Int = "Int",
	String = "String",
	Decimal = "Decimal",
	Money = "Money",
	Date = "Date",
	Time = "Time",
	DateTime = "DateTime",
	Bool = "Bool",
	Byte = "Byte",
	Blob = "Blob", // 0x0000000A
	List = "List", // 0x0000000F
	ValidatedList = "ValidatedList", // 0x00000010
	MultiSelectValidatedList = "MultiSelectValidatedList", // 0x00000011
}

export enum ICustomEntityScope
{
	None = "None",
	AnyEntity = "AnyEntity",
	Custom = "Custom",
	Customer = "Customer",
	Campaign = "Campaign",
	ClubMembership = "ClubMembership",
	CustomerList = "CustomerList",
	Club = "Club",
	GroupMembership = "GroupMembership",
	Event = "Event",
	Newsletter = "Newsletter",
	Order = "Order",
	Product = 'Product',
	SalesTransaction = 'SalesTransaction',
	SubscriberGroup = 'SubscriberGroup',
}

export enum BankAccountType {
	Unknown = "Unknown",
	Checking = "Checking",
	Savings = "Savings"
}

export enum CreditCardType {
	Unknown = 'Unknown',
	AmericanExpress = 'AmericanExpress',
	Visa = 'Visa',
	Mastercard = 'Mastercard',
	Discover = 'Discover'
}

export enum ShippingPreferenceType {
	Shipping = "Shipping",
	Pickup = "Pickup",
	Option = "Option",
}

export enum SnippetTheme {
	Unknown = "Unknown",
	Default = "Default",
	MaterialUI = "MaterialUI"
}

export enum SnippetType {
	ClubSignup = "ClubSignup",
	Signup = "Signup",
	Login = "Login",
	Alert = "Alert",
	CustomerReferrals = "CustomerReferrals",
	AccountManagement = "AccountManagement",
	ShippingAddresses = "ShippingAddresses",
	CustomerDetails = "CustomerDetails",
	Dashboard = "Dashboard",
	RecordEngagement = "RecordEngagement",
	ClubPreferences = 14,
	GiftCardBalance = 15,
	PaymentMethods = "PaymentMethods",
	EGiftCard = 16,
	RegisterCard = 19,
	ClubList = 18,
	PurchaseHistory = "PurchaseHistory",
}

export enum LoginType {
	Undefined = "Undefined",
	NotRequired = "NotRequired",
	ConfiguredUrl = "ConfiguredUrl",
	ConfiguredSnippet = "ConfiguredSnippet",
	ExternalQueryUrl = "ExternalQueryUrl",
	ExternalQuerySnippet = "ExternalQuerySnippet",
	ExternalElementUrl = "ExternalElementUrl",
	ExternalElementSnippet = "ExternalElementSnippet"
}
 
export type WebSnippetProfile = {
	Uid: string
	ExternalId: string
	Code: string
	Name: string
	Type: SnippetType
	IsValid: boolean
	Theme: SnippetTheme
	LogoUrl: string
	Title: string
	Message: string
	Footer: string
	OnSuccessRedirectUrl: string
	OnSuccessMessage: string
	OnSuccessRedirectDelay: number
	OnErrorRedirectUrl: string
	OnErrorMessage: string
	OnErrorRedirectDelay: number
	LoginType: LoginType
	LoginSnippetCode: string
	LoginUrl: string
	Buttons: Array<ButtonConfig>
	
	CustomerDashboard?: CustomerDashboardSnippetProfile
	CustomerReferral?: CustomerReferralSnippetProfile
	RecordEngagement?: RecordEngagementSnippetProfile
	ClubPreferences?: ClubPreferenceSnippetProfile
	GiftCardBalance?: GiftCardBalanceSnippetProfile
	EGiftCard?: EGiftCardSnippetProfile
	PaymentMethods?: PaymentMethodSnippetProfile
	ShippingAddresses?: ShippingAddressesSnippetProfile
	CustomerDetails?: CustomerDetailsSnippetProfile
	AccountManagement?: AccountManagementSnippetProfile
	PurchaseHistory?: PurchaseHistorySnippetProfile
	ClubList?: ClubListSnippetProfile
	RegisterCard?: RegisterCardSnippetProfile
	
	ClubSignup: ClubSignupSnippetProfile
	Login?: LoginSnippetProfile
	Signup: any
	LegacyLogin: any,
	LegacyClubSignup: any
}

export type RegisterCardSnippetProfile = {
	PrimaryButtonLabel: string,
	DeleteButtonLabel: string,
	AddButtonLabel: string,
	CardNumberLabel: string,
}

export type ClubListSnippetProfile = {
	Clubs: Array<ClubSummary>
	ClubsScope: ClubsScope
	AllowUpdate: boolean
	AllowSuspend: boolean
	AllowClose: boolean
	CloseLabel: string
	SuspendLabel: string
	UpdateLabel: string
	ReactivateLabel: string
}

export type PurchaseHistorySnippetProfile = {
	DefaultDisplay: PurchaseHistoryDisplay
	PurchasesScope: PurchaseHistoryScope
	PurchaseColumns: Array<TableColumn>
	Fields: Array<IInputConfig>
}

export type TableColumn = {
	Name: string,
	Label: string
}

export enum PurchaseHistoryDisplay {
	AllSales = "AllSales",
	Orders = "Orders",
}

export enum PurchaseHistoryScope {
	All = "All",
	Brand = "Brand",
	Store = "Store"
}

export type ClubSignupSnippetProfile = {
	Products: Array<ClubSignupProductConfiguration>
	ProductPreferenceType: ProductPreferenceType
	Clubs: Array<ClubSummary>
	ClubsScope: ClubsScope
	MinimumQuantity: number
	MinimumPrice: number
	AllowGift: boolean
	GiftSignupSnippetCode: string
	GiftRecipientPageName: string,
	IncludeMemberDetails: boolean,
	MemberDetailsSnippetCode: string
	MemberDetailsPageName: string
	CapturePaymentMethod: boolean
	PaymentMethodSnippetCode: string
	PaymentMethodPageName: string,
	CaptureDeliveryDetails: boolean
	ShippingAddressesSnippetCode: string
	AllowStorePickup: boolean
	InventoryLocationUids: Array<string>
	AllowShipping: boolean
	ShippingAddressPageName: string,
	IncludeProductPreferences: boolean,
	ProductPreferencesPageName: string,
	MembershipSelectionPageName: string
	MembershipSelectionTypeText: string,
	StandardMembershipTypeText: string,
	GiftMembershipTypeText: string,
	GiftMembershipSignupSnippetCode: string
	MembershipCustomFields: Array<ClubMembershipCustomFieldConfig>
	CustomFieldsDisplayType: CustomFieldDisplayType,
	CustomFieldsPageName: string,
	CustomFieldsPageText: string,
	ProductPreferenceCaption: string
	LoyaltyCardMaxLength: number | null
	LoyaltyCardMinLength: number
	LoyaltyCardCharacterType: CharacterTypeOptions
	ZeroFillLoyaltyCard: boolean
	BackLabel: string,
	NextLabel: string,
	SaveLabel: string
}

export enum ClubsScope {
	All = "All",
	Scoped = "Scoped"
}

export type ClubMembershipCustomFieldConfig = {
	Name: string,
	Required: boolean
	Code: string
}

export type ClubSummary = {
	Code: string,
	Name: string,
	Uid: string,
}

export type ClubSignupProductConfiguration = {
	Quantity: string,
	DefaultQuantity: string
	Code: string,
	Order: number,
	Name: string,
	Id: string
}

export type AccountManagementSnippetProfile = {
	ShowDashboard: boolean
	DashboardSnippetCode: string
	DashboardTabText: string
	ShowCustomerDetails: boolean
	CustomerDetailsSnippetCode: string
	CustomerDetailsTabText: string
	ShowShippingAddresses: boolean
	ShippingAddressesSnippetCode: string
	ShippingAddressesTabText: string
	ShowPaymentMethods: boolean
	PaymentMethodSnippetCode: string
	PaymentMethodsTabText: string
	ShowTabs: boolean
}

export type CustomerDetailsSnippetProfile = {
	CustomerFields: Array<IInputConfig>
	CustomFieldsDisplayType: CustomFieldDisplayType
	SaveButtonText: string
}

export type ShippingAddressesSnippetProfile = {
	AddressFields: Array<IInputConfig>
	AddButtonText: string,
	EditButtonText: string,
	DeleteButtonText: string,
	ShipText: string,
	PickupText: string,
}

export type PaymentMethodSnippetProfile = {
	PaymentTypes: Array<PaymentType>,
	AllowCartPayments: boolean,
	AddPaymentLabel: string
}

export type EGiftCardSnippetProfile = {
	EGiftFields: Array<IInputConfig>
	AddToCartLabel: string
    DefaultProductCode: string
    RemoveRecipientLabel: string
	AddRecipientLabel: string
	DesignSectionLabel: string
	GiftAmountSectionLabel: string
	RecipientSectionLabel: string
	SenderSectionLabel: string
	ImageOptions: Array<string>
	AmountOptions: Array<string>
	UseCartPrice: boolean
	MinValue: number
	MaxValue: number | null
}

export type GiftCardBalanceSnippetProfile = {
	InputLabel: string,
	ButtonLabel: string,
	BalanceMessage: string,
	CardNotFoundMessage: string
}

export type ButtonConfig = {
	ButtonName: string,
	Text: string
}

export type RecordEngagementSnippetProfile = {
	RecordingMessage: string
}

export type ClubPreferenceSnippetProfile = {
	ClubDetailsPageName: string,
	ShippingAddressPageName: string,
	GiftRecipientPageName: string,
	PaymentMethodPageName: string,
	GiftRecipientPageText: string,
	ClubDetailsPageText: string,
	ShippingAddressPageText: string
	AllowGift: boolean
	GiftSignupSnippetCode: string
	CapturePaymentMethod: boolean
	PaymentMethodSnippetCode: string
	CaptureDeliveryDetails: boolean
	ShippingAddressesSnippetCode: string
	AllowShipping: boolean
	AllowStorePickup: boolean
	GiftLabel: string
	GiftHelpText: string
	ClubTermLabel: string
	AllowCustomStartDate: boolean
	StartDateLabel: string
	MaxMonthsAheadForStartDate: number
	AllowCustomBillingPeriod: boolean
	BillingPeriodLabel: string
	WeeklyPaymentOptionLabel: string
	EveryOtherWeekPaymentOptionLabel: string
	EveryFourWeeksPaymentOptionLabel: string
	MonthlyPaymentOptionLabel: string
	EveryOtherMonthPaymentOptionLabel: string
	QuarterlyPaymentOptionLabel: string
	SemiAnnuallyPaymentOptionLabel: string
	AnnuallyPaymentOptionLabel: string
	AllowPaymentStrategy: boolean
	PaymentStrategyLabel: string
	CustomFieldsDisplayType: CustomFieldDisplayType
	NextLabel: string,
	BackLabel: string,
	SaveLabel: string
}

export enum CustomFieldDisplayType {
	Radio = "Radio",
	Select = "Select"
}

export type LoginSnippetProfile = {
	Id: number
	IsLogin: boolean
	LoginBtnText: string
	IsSignup: boolean
	SignupBtnText: string
	BodyText: string
	LoginHelperText: string
	SignupHelpText: string
	UsageId: string
	SnippetMasterId: number
	CustomerSignupSnippetCode: string
	IsReturnSuccessUrl: boolean
	SuccessUrlOrSnippet: string
	SuccessSnippetClubCode: string
	IsReturnSuccessMessage: boolean
	SuccessMessage: string
	
	IsReturnErrorUrl: boolean
	ErrorUrlOrSnippet: string
	ErrorSnippetClubCode: string
}

export type SignupSnippetProfile = {
	
}


export type RadioOption = {
	label: string
	value: string | boolean | ShippingFrequencies
}

export type ClubPreferences = {
	FirstShippingDate?: Date,
	ShippingFrequency?: ClubBillingPeriod,
	PaymentOption?: ClubPaymentStrategy,
	Gift: boolean,
	CustomerUid?: string,
	PaymentMethodUid?: string,
	ShippingAddressUid?: string
	ClubTerm?: number,
	CustomFields: any
}

export enum ShippingFrequencies {
	Monthly = "Monthly",
	EveryOtherMonth = "EveryOtherMonth",
	Quarterly = "Quarterly"
}

export enum ClubBillingPeriod {
	Undefined = "Undefined",
	Weekly = "Weekly",
	EveryOtherWeek = "EveryOtherWeek",
	Every4Weeks = "Every4Weeks",
	Monthly = "Monthly",
	EveryOtherMonth = "EveryOtherMonth",
	Quarterly = "Quarterly",
	SemiAnnually = "SemiAnnually",
	Annually = "Annually",
}

export enum ClubPaymentStrategy {
	SinglePayment = "SinglePayment",
	PerShipment = "PerShipment"
}

export enum ChoiceResponse {
	Yes = "Yes",
	No = "No"
}

export type Club = {
	Uid: string,
	ExternalId: string,
	Code: string,
	Name: string,
	DisablePaymentMethod: boolean,
	DisableShippingAddress: boolean,
	ClubTermOptions: string,
	ClubTermAmount: number,
	RenewalTermOptions: string,
	RenewalTermAmount: number,
	PreferenceCustomFieldOptions: Array<CustomFieldOption>,
	ClubMemberBillingPeriodOptions: string
}

export type CustomFieldOption = {
	CustomFieldCode: string,
	CustomFieldValues: null | Array<string>
}

export interface IReferralSnippetConfig {
	Fields: Array<IInputConfig>
	Message: string,
	Title: string,
	OnSuccessMessage: string,
	OnSuccessRedirectUrl: string,
	OnSuccessRedirectDelay: number | null,
	LoginSnippetCode: string,
	LoginUrl: string,
}

export type CustomerReferralSnippetProfile = {
	Fields: Array<IInputConfig>
}

export type SubscriberGroupConfig = {
	Name: string
	Display: boolean
	Subscribe: boolean
	Uid: string
}

export type LegacySubscriberGroupConfig = {
	Name: string
	IsDisplay: boolean
	IsSubscribe: boolean
	Uid: string
}

export type CustomerDashboardSnippetProfile = {
	DisplayReferralSnippet: boolean,
	ReferralSnippetCode: string,
	ReferralSnippetButtonText: string,
	DisplayQRCode: boolean,
	QRCustomerField: string
	CouponHeaderLabel: string,
	LoyaltyAccountIndicators: Array<LoyaltyAccountIndicator>
}

export type SelectOption = {
	text: string
	value: string
}

export type LoyaltyAccountIndicator = {
	Type: AccountIndicatorType,
	ClassUid: string,
	ProgressBar: ProgressBarProfile,
	Label: string
}

export enum AccountIndicatorType {
	Value = "Value",
	ProgressBar = "ProgressBar"
}

export enum CharacterTypeOptions {
	AlphaNumeric = "AlphaNumeric",
	Alpha = "Alpha",
	Numeric = "Numeric"
}

export type ProgressBarProfile = {
	Maximum: number,
	Checkpoints: Array<ProgressBarCheckpoint>,
	Color: string,
	ClassName: string,
	Style: string,
	UseIcons: boolean
}

export type ProgressBarCheckpoint = {
	Name: string,
	Label: string,
	Value: number,
	Details: string,
}

export type TrackedCard = {
	Code: string,
	CustomerUids: Array<string>,
	ExternalId: string,
	Revision: number,
	SubscriptionUid: string,
	Uid: string
	Balance: number
}