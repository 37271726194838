import React from 'react';
import {Settings} from '../Settings';

function useSafeDispatch(dispatch: Function) {
	const mounted = React.useRef(false);
	React.useLayoutEffect(() => {
		mounted.current = true;
		return () => {
			mounted.current = false;
		};
	}, []);
	return React.useCallback(
		(...args) => (mounted.current ? dispatch(...args) : void 0),
		[dispatch]
	);
}

const initialState = { status: 'idle', data: null, error: null };

function useAsync() {
	const [{ status, data, error }, setState] = React.useReducer(
		(s: any, a: any) => ({ ...s, ...a }),
		initialState
	);

	const safeSetState = useSafeDispatch(setState);

	const run = React.useCallback(
		promise => {
			if (!promise || !promise.then) {
				throw new Error(
					`The argument passed to useAsync().run must be a promise. Maybe a function that's passed isn't returning anything?`
				);
			}
			safeSetState({ status: 'pending' });
			return promise.then(
				(data: any) => {
					safeSetState({ data, status: 'resolved' });
					return data;
				},
				(error: any) => {
					safeSetState({ status: 'rejected', error });
					return error;
				}
			);
		},
		[safeSetState]
	);

	const runWithServiceUrls = React.useCallback((promise, url) => {
			let local = sessionStorage.getItem('ServiceUrls');
			let localUrls: any = null;
			if (local !== null) {
				localUrls = JSON.parse(local);
			}
			localUrls = mapServiceUrls(localUrls);
			if (localUrls) {
				safeSetState({ status: 'pending' });
				return promise(localUrls[url]).then(
					(data: any) => {
						safeSetState({ data, status: 'resolved' });
						return data;
					},
					(error: any) => {
						safeSetState({ status: 'rejected', error });
						return error;
					}
				)
			}

			safeSetState({ status: 'pending' });
			window.fetch(Settings.DomainServiceUrl + '/api/v4/serviceurls/ryanchdev')
				.then(async r => {
					const response = await r.json();
					if (response.status === 'success') {
						let urls = { ...response.data };
						urls = mapServiceUrls(urls);
						sessionStorage.setItem('ServiceUrls', JSON.stringify(urls));
						promise.then((func: Function) => {
							func(localUrls[url])
						})
					}
					else {
						return Promise.reject('Unable to get Service Urls: ' + response.message);
					}
				});
		},
		[safeSetState]);

	const mapServiceUrls = (urls: any) => {
		const definedUrls = { ...Settings.ServiceUrls };
		return {
			...urls,
			...definedUrls
		};
	};

	const setData = React.useCallback(data => safeSetState({ data }), [
		safeSetState
	]);
	const setError = React.useCallback(error => safeSetState({ error }), [
		safeSetState
	]);
	const reset = React.useCallback(() => safeSetState(initialState), [
		safeSetState
	]);

	return {
		// using the same names that react-query uses for convenience
		isIdle: status === 'idle',
		isLoading: status === 'pending',
		isError: status === 'rejected',
		isSuccess: status === 'resolved',

		runWithServiceUrls,
		setData,
		setError,
		error,
		status,
		data,
		run,
		reset
	};
}

export {useAsync};
