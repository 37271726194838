import React, {useEffect} from 'react';

type SnippetHeaderProps = {
    title: string,
    message: string,
    logoUrl: string,
    snippetCode: string
}

export default function SnippetHeader(props: SnippetHeaderProps) {
    useEffect(() => {
        const title = document.getElementById(`bl-snippet-title-${props.snippetCode}`)
        const body = document.getElementById(`bl-snippet-body-${props.snippetCode}`)
        if (title)
            title.innerHTML = props.title
        if (body)
            body.innerHTML = props.message
    }, [])
    
    return (
        <>
            {props.logoUrl && <img src={props.logoUrl} alt="Logo" style={{maxWidth: 'min(75%, 1000px)', maxHeight: '200px', marginRight: '25px', marginLeft: '25px'}} />}
            <h1 id={`bl-snippet-title-${props.snippetCode}`}></h1>
            <p id={`bl-snippet-body-${props.snippetCode}`}></p>
        </>
    )
}