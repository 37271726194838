import React, {useEffect, useState} from 'react';
import App from "./App";
import LoadingSpinner from "./LoadingSpinner";

type PreProcessWrapperProps = {
    snippetCode: string
}

export default function PreProcessWrapper(props: PreProcessWrapperProps) {
    const [showMessage, setShowMessage] = useState(false)
    // @ts-ignore
    const [done, setDone] = useState(typeof blPreProcess !== 'function')
    const [error, setError] = useState(false)
    
    useEffect(() => {
        setTimeout(() => setShowMessage(true), 4000)
    }, [])

    useEffect(() => {
        async function callPreProcess() {
            if (!done) {
                try {
                    console.log('before Pre Process function')
                    // @ts-ignore
                    await window["blPreProcess"]();
                    console.log('after Pre Process function')
                    setDone(true);
                } catch (e) {
                    setError(true)
                    setDone(true)
                    console.error(e)
                }
            }
        }

        callPreProcess()
    }, [])
    
    return (
        <>
        {done && !error &&
            <div className='bl-snippet-container'>
                <App snippetCode={props.snippetCode} />
            </div>
        }
        {done && error &&
            <p className='bLoyal-center bLoyal-error-text'>The blPreProcess function you have provided has thrown an error. More details may be found in the console.</p>
        }
        {!done &&
            <LoadingSpinner />
        }
        {!done && showMessage &&
            <p className='bLoyal-center'>Waiting on external pre-process function...</p>
        }
        </>
    )
}