import {useQuery} from 'react-query';
import {client, logout} from './ApiClient';
import {mapServiceUrls} from '../Helpers/Functions';

const resolveSessionCustomer = (key: any, urls: any, session: string, domain: string, deviceCode: string) => {
	return client(urls.LoyaltyEngineApiUrl, `api/v4/${domain}/${deviceCode}/sessions/${session}/customer`);
}

export default function useResolveSessionCustomer(urls: any, session: string, domain: string, deviceCode: string, customer: any) {
	const {status, data, error, isFetching} = useQuery(['Customer', session], (key) => resolveSessionCustomer(key, mapServiceUrls(urls), session, domain, deviceCode), {enabled: (urls && session !== '' && session !== undefined && !customer && session !== null), staleTime: 1000*60*5, retry: false});
	const sessionCustomerStatus = status;
	const sessionCustomer = data ? data.data : undefined;
	const sessionCustomerError = error;
	if (error === "Expired key")
		logout();

	return { sessionCustomerStatus, sessionCustomer, sessionCustomerError, isCustomerFetching: isFetching };
}
