import {useQuery} from 'react-query';
import {client} from './ApiClient';
import {mapServiceUrls} from '../Helpers/Functions';

const getStates = (key: any, urls: any, domain: string, deviceCode: string, countryUid: string) => client(urls.LoyaltyEngineApiUrl, `api/v4/${domain}/${deviceCode}/states?countryCode=${countryUid}`);

export default function useStates(urls: any, domain: string, deviceCode: string, countryUid: string, enabled: boolean) {
	const {status, data, error, isFetching } = useQuery(['States', countryUid], (key) => getStates(key, mapServiceUrls(urls), domain, deviceCode, countryUid), {enabled: urls && enabled, staleTime: 1000*60*30});
	const statesStatus = status;
	const states = data ? data.data : undefined;
	const statesError = error;
	const statesFetching = isFetching;
	return {statesStatus, states, statesError, statesFetching};
}
