import {useQuery} from 'react-query';
import {client, logout} from './ApiClient';
import {mapServiceUrls} from '../Helpers/Functions';

const getShippingAddresses = (key: any, urls: any, session: string, domain: string, deviceCode: string) => {
	return client(urls.LoyaltyEngineApiUrl, `api/v4/${domain}/${deviceCode}/sessions/${session}/shippingaddresses`);
}

export default function useShippingAddress(urls: any, session: string, domain: string, deviceCode: string, disabled: boolean = false) {
	const {status, data, error, isFetching } = useQuery('ShippingAddresses', (key) => getShippingAddresses(key, mapServiceUrls(urls), session, domain, deviceCode), {enabled: urls && !disabled, staleTime: 1000*60*5});
	const shippingAddressesStatus = status;
	const shippingAddresses = data;
	const shippingAddressesError = error;
	const shippingAddressesFetching = isFetching;
	if (error === 'Expired key')
		logout();
	return {shippingAddressesStatus, shippingAddresses, shippingAddressesError, shippingAddressesFetching};
}
