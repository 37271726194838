import {useMutation} from 'react-query';
import {client} from './ApiClient';
import { v4 as uuid } from 'uuid';
import {mapSignupStateToLESignupCommand} from "../Helpers/Maps";
import {ISignupState} from "../Helpers/Types";

const setCustomerToCart = ({urls, domain, deviceCode, cartUid, cartExternalId, cartSourceExternalId, customerUid}: {urls: any; domain: string; deviceCode: string; cartUid: string; cartExternalId: string; cartSourceExternalId: string; customerUid: string}) => {
	let params = `${cartUid ? `?cartUid=${cartUid}` : ''}`
	if (cartExternalId)
		params = `${params === '' ? '?' : '&'}externalId=${cartExternalId}`
	
	if (cartSourceExternalId)
		params = `${params === '' ? '?' : '&'}sourceExternalId=${cartSourceExternalId}`
	return client(urls.LoyaltyEngineApiUrl, `api/v4/${domain}/${deviceCode}/carts/commands/setcustomer${params}`, {
		body: {
			Uid: customerUid,
		}
	});
}

export default function useSetCustomerToCart() {
	// @ts-ignore
	return useMutation(setCustomerToCart)
}