import React, {useEffect, useMemo, useState} from 'react';
import RadioSelection from "./RadioSelection";
import {
    ClubBillingPeriod, ClubPreferenceSnippetProfile,
    PaymentMethodSnippetProfile,
    ShippingFrequencies,
    WebSnippetProfile
} from "../../Helpers/Types";
import {getDisplayValue} from "../../Helpers/Functions";

type ClubBillingPeriodSelectionProps = {
    label: string,
    helpText: string,
    clubPaymentOptions: string,
    snippetProfile: ClubPreferenceSnippetProfile
    value: ClubBillingPeriod,
    handleChange: (field: string, value: any) => void
}

export default function ClubBillingPeriodSelection(props: ClubBillingPeriodSelectionProps) {
    const [options, setOptions] = useState<Array<{label: string, value: string}>>([])
    
    useEffect(() => {
        let optionsStrings = props.clubPaymentOptions.split(',')
        
        optionsStrings = optionsStrings.map((o) => o.trim())
        const opts = optionsStrings.map((o) => {
            switch (o) {
                case 'Weekly':
                    return {label: props.snippetProfile.WeeklyPaymentOptionLabel ?? getDisplayValue(o), value: o}
                case 'EveryOtherWeek':
                    return {label: props.snippetProfile.EveryOtherWeekPaymentOptionLabel ?? getDisplayValue(o), value: o}
                case 'Every4Weeks':
                    return {label: props.snippetProfile.EveryFourWeeksPaymentOptionLabel ?? getDisplayValue(o), value: o}
                case 'Monthly':
                    return {label: props.snippetProfile.MonthlyPaymentOptionLabel ?? getDisplayValue(o), value: o}
                case 'EveryOtherMonth':
                    return {label: props.snippetProfile.EveryOtherMonthPaymentOptionLabel ?? getDisplayValue(o), value: o}
                case 'Quarterly':
                    return {label: props.snippetProfile.QuarterlyPaymentOptionLabel ?? getDisplayValue(o), value: o}
                case 'SemiAnnually':
                    return {label: props.snippetProfile.SemiAnnuallyPaymentOptionLabel ?? getDisplayValue(o), value: o}
                case 'Annually':
                    return {label: props.snippetProfile.AnnuallyPaymentOptionLabel ?? getDisplayValue(o), value: o}
                default:
                    console.log('Option Not Found')
                    return {label: '', value: ''}
            }
        })
        setOptions(opts)
    }, [])

    return (
        <RadioSelection  fieldName={'clubBillingPeriod'} value={props.value} handleChange={props.handleChange} helpText={props.helpText} label={props.label} options={options}/>
    )
}