import React, {useMemo} from 'react';
import {Dialog, DialogActions, DialogTitle} from "@material-ui/core";
import Table from "bloyal-component-library/dist/Tables/Table";
import {Grid} from "@mui/material";
import {PurchaseHistorySnippetProfile} from "../Helpers/Types";
import ShipmentSummaries from "./ShipmentSummaries";
import {getCurrencyString} from "../Helpers/ComponentFunctions";

type OrderDetailsProps = {
    order: any
    close: () => void
    snippetProfile?: PurchaseHistorySnippetProfile
}

export default function OrderDetails(props: OrderDetailsProps) {
    const getField = (fieldName: string) => {
        return props.snippetProfile?.Fields.find(f => f.FieldName === fieldName)
    }
    
    const lineColumns = useMemo(() => [
        {
            Header: getField('ProductCode')?.Label ?? "Code",
            accessor: 'ProductCode',
            Cell: (row: any) => <p className='bloyal-table-cell bLoyal-right-align'>{`${row.row.original.ProductCode}`}</p>
        },
        {
            Header: getField('ProductName')?.Label ?? "Name",
            accessor: 'ProductName',
            Cell: (row: any) => <p className='bloyal-table-cell bLoyal-right-align'>{`${row.row.original.ProductName}`}</p>
        },
        {
            Header: getField('Quantity')?.Label ?? "Quantity",
            accessor: 'Quantity',
            Cell: (row: any) => <p className='bloyal-table-cell bLoyal-right-align'>{`${row.row.original.Quantity}`}</p>
        },
        {
            Header: getField('UnitPrice')?.Label ?? "Unit Price",
            accessor: 'Price',
            Cell: (row: any) => <p className='bloyal-table-cell bLoyal-right-align'>{`${getCurrencyString(row.row.original.Price)}`}</p>
        },
        {
            Header: getField('TotalPrice')?.Label ?? "Total Price",
            accessor: 'FullPrice',
            Cell: (row: any) => <p className='bloyal-table-cell bLoyal-right-align'>{`${getCurrencyString(row.row.original.Price * row.row.original.Quantity)}`}</p>
        },
        {
            Header: getField('Discount')?.Label ?? "Discount",
            accessor: 'Discount',
            Cell: (row: any) => <p className='bloyal-table-cell bLoyal-right-align'>{`${getCurrencyString(row.row.original.Discount * -1)}`}</p>
        },
        {
            Header: getField('ExtendedPrice')?.Label ?? "Extended Price",
            accessor: 'Net',
            Cell: (row: any) => <p className='bloyal-table-cell bLoyal-right-align'>{`${getCurrencyString((row.row.original.Price - row.row.original.Discount) * row.row.original.Quantity)}`}</p>
        },
    ], [])
    
    const orderDiscount = useMemo(() => props.order.OrderDiscount?.Amount * -1 ?? 0, [props.order])
    
    const shipping = useMemo(() => {
        let shipping = 0;
        props.order.Shipments?.forEach((s: any) => shipping += s.Charge)
        return shipping
    }, [props.order])

    const shippingDiscount = useMemo(() => {
        let discount = 0;
        props.order.Shipments?.forEach((s: any) => discount += s.Discount)
        return discount * -1
    }, [props.order])
    
    return (
        <Dialog maxWidth='lg' open={true} fullWidth={true} scroll='paper'>
            <DialogTitle>Order Details</DialogTitle>
            <Grid style={{width: '90%', maxWidth: '1000px'}} className='bLoyal-center'>
                <h4>Products</h4>
                <Table columns={lineColumns} data={props.order.Lines} alignHeaders='right'/>
                <br />
                <Grid container rowSpacing={3}>
                    <Grid item xs={12} sm={6}>
                        <ShipmentSummaries order={props.order} getField={getField} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Grid container style={{float: 'right', width: '30%', minWidth: '200px', marginRight: '20px'}}>
                            <Grid item xs={12} style={{height: '25px'}}>
                                <p style={{float: 'left'}}>{getField('Subtotal')?.Label ?? 'Subtotal'}:</p>
                                <p style={{float: 'right'}}>{getCurrencyString(props.order.TotalPrice)}</p>
                            </Grid>
                            <Grid item xs={12} style={{height: '25px'}}>
                                <p style={{float: 'left'}}>{getField('OrderDiscount')?.Label ?? 'Order Discount'}:</p>
                                <p style={{float: 'right'}}>{getCurrencyString(orderDiscount)}</p>
                            </Grid>
                            <Grid item xs={12} style={{height: '25px'}}>
                                <p style={{float: 'left'}}>{getField('Shipping')?.Label ?? 'Shipping'}:</p>
                                <p style={{float: 'right'}}>{getCurrencyString(shipping)}</p>
                            </Grid>
                            <Grid item xs={12} style={{height: '25px'}}>
                                <p style={{float: 'left'}}>{getField('ShippingDiscount')?.Label ?? 'Shipping Discount'}:</p>
                                <p style={{float: 'right'}}>{getCurrencyString(shippingDiscount)}</p>
                            </Grid>
                            <Grid item xs={12} style={{height: '25px'}}>
                                <p style={{float: 'left'}}>{getField('TotalTaxes')?.Label ?? 'Tax'}:</p>
                                <p style={{float: 'right'}}>{getCurrencyString(props.order.TotalTax)}</p>
                            </Grid>
                            <hr className={'bLoyal-purchase-amount-break'}/>
                            <Grid item xs={12} style={{height: '25px'}}>
                                <p style={{float: 'left'}}>{getField('TotalPayment')?.Label ?? 'Total'}:</p>
                                <p style={{float: 'right'}}>{getCurrencyString(props.order.TotalPayment)}</p>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <DialogActions>
                <button className='bl-snippet-button' onClick={props.close} >
                    Close
                </button>
            </DialogActions>
        </Dialog>
    )
}