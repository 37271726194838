import {useMutation} from 'react-query';
import {client} from './ApiClient';
import {ICustomer} from '../Helpers/Types';

const acknowledgeAlert = ({urls, domain, deviceCode, cartUid, cartExternalId, cartSourceExternalId, alertUid}: {urls: any; domain: string; deviceCode: string; cartUid: string; cartExternalId: string; cartSourceExternalId: string; alertUid: string}) => {
	return client(urls.LoyaltyEngineApiUrl, `api/v4/${domain}/${deviceCode}/alerts/commands/acknowledge`, {
		body: {
			CartUid: cartUid,
			CartExternalId: cartExternalId,
			CartSourceExternalId: cartSourceExternalId,
			AlertUid: alertUid
		}
	});
}

export default function useAcknowledgeAlert() {
	// @ts-ignore
	return useMutation(acknowledgeAlert)
}