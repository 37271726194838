import React, {useState} from 'react';
import {ISyntheticEvent, RadioOption} from "../../Helpers/Types";
import {FormControlLabel, Grid, Radio, RadioGroup} from "@material-ui/core";

type RadioSelectionProps = {
    label: string,
    helpText: string,
    value: any,
    fieldName: string,
    handleChange: (field: string, value: boolean) => void
    options: Array<RadioOption>
}

export default function RadioSelection(props: RadioSelectionProps) {
    const [radio, setRadio] = useState(props.value)

    const handleChange = (e: ISyntheticEvent) => {
        const value = e.target.value;
        setRadio(value);
        props.handleChange(props.fieldName, value)
    }

    const mapOption = (option: RadioOption, index: number) => {
        return (
            <FormControlLabel key={index} value={option.value} control={<Radio color='default'/>} label={option.label}  />
        )
    }

    return (
        <>
            <Grid container >
                <Grid item sm={6} xs={12} className='bLoyal-center' style={{paddingRight: '10px'}} >
                    <p>{props.label}</p>
                </Grid>
                <Grid item sm={6} xs={12} className='bLoyal-center'>
                    <RadioGroup aria-label={props.label} name="login-type" value={radio} onChange={handleChange}>
                        {props.options.map(mapOption)}
                    </RadioGroup>
                </Grid>
            </Grid>
        </>
    )
}