import {useQuery} from 'react-query';
import {client, logout} from './ApiClient';
import {mapServiceUrls} from '../Helpers/Functions';

const getCustomerSession = (key: any, urls: any, session: string, domain: string, deviceCode: string) => {
	return client(urls.LoyaltyEngineApiUrl, `api/v4/${domain}/${deviceCode}/snippets/customersession/${session}`);
}

export default function useGetCustomerSession(urls: any, session: string, domain: string, deviceCode: string) {
	const {status, data, error, isFetching} = useQuery(['CustomerSession', session], (key) => getCustomerSession(key, mapServiceUrls(urls), session, domain, deviceCode), {enabled: (urls && session !== '' && session !== undefined && session !== null), staleTime: 1000*60*5});
	const customerSession = data ? data.data : undefined;
	// @ts-ignore
	if (error === 'Expired key')
		logout();

	return { status, customerSession, error, isCustomerFetching: isFetching };
}
