import {queryCache, useMutation} from 'react-query';
import {client} from './ApiClient';
import {mapServiceUrls} from "../Helpers/Functions";

const inactivateClub = ({urls, session, domain, deviceCode, customerUid, clubUid, membershipUid, months, enableLoyalty}: {urls: any; session: string; domain: string; deviceCode: string; customerUid: string; clubUid: any; membershipUid: string; months: string; enableLoyalty: boolean;}) => {
	return client(mapServiceUrls(urls).LoyaltyEngineApiUrl, `api/v4/${domain}/${deviceCode}/sessions/${session}/clubmembership/commands/inactivate`, {
		body: {
			CustomerUid: customerUid,
			ClubUid: clubUid,
			MembershipUid: membershipUid,
			SuspensionPeriod: months,
			EnableLoyaltyBenefitsWhileInactive: enableLoyalty
		}
	});
}

export default function useInactivateClub() {
	// @ts-ignore
	return useMutation(inactivateClub, {
		onSuccess: () => {
			queryCache.invalidateQueries('ClubMembership')
		},
	})
}