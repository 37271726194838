import React from 'react';
import {CreditCardType} from "./Helpers/Types";

type PaymentMethodProps = {
	paymentMethod: any,
}

export default function PaymentMethodPreview(props: PaymentMethodProps) {
	const { Title, PaymentType, CreditCard, GiftCard } = props.paymentMethod;
	
	if (PaymentType === 'CreditCard' && (CreditCard.CardType !== CreditCardType.Unknown || CreditCard.Cardmask !== null))
		return <></>
	
	return (
		<div className={`bLoyal-center payment-preview`}>
			<p>{Title}</p>
			{PaymentType === 'CreditCard' &&
				<>
				{
					CreditCard.CardType !== CreditCardType.Unknown && CreditCard.Cardmask !== null &&
						<p>{`${CreditCard.CardType} ${CreditCard.CardMask}`}</p>
				}
				</>
			}
			{PaymentType === 'GiftCard' &&
			<p>{GiftCard.CardMask}</p>
			}
		</div>
	);
}