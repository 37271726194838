import {useQuery} from 'react-query';
import {client, logout} from './ApiClient';
import {mapServiceUrls} from '../Helpers/Functions';

const getPaymentMethods = (key: any, urls: any, session: string, domain: string, deviceCode: string) => {
	return client(urls.LoyaltyEngineApiUrl, `api/v4/${domain}/${deviceCode}/sessions/${session}/paymentmethods`);
}

export default function usePaymentMethods(urls: any, session: string, domain: string, deviceCode: string) {
	const {status, data, error, isFetching } = useQuery('PaymentMethods', (key) => getPaymentMethods(key, mapServiceUrls(urls), session, domain, deviceCode), {enabled: urls, staleTime: 1000*60*5});
	const paymentMethodsStatus = status;
	const paymentMethods = data ? data.data : undefined;
	const paymentMethodsError = error;
	const paymentMethodsFetching = isFetching;
	if (error === 'Expired key')
		logout();

	return {paymentMethodsStatus, paymentMethods, paymentMethodsError, paymentMethodsFetching};
}
