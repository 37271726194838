import {Settings} from '../Settings';
import {
    ButtonConfig,
    ClubPaymentStrategy,
    ClubSignupSnippetProfile,
    CreditCardType,
    ICountry,
    IInputConfig,
    IShippingAddress,
    ISignupState,
    SelectOption
} from './Types';

export const mapServiceUrls = (urls: any) => {
	if (urls === undefined || urls === null)
		return undefined;

	const definedUrls = { ...Settings.ServiceUrls };
	return {
		...urls,
		...definedUrls
	};
};

export const checkValidDate = (date: string) => {
	if (!date)
		return true;
	// your desired pattern
	var pattern = /(\d{1}|0\d{1}|1[0-2])\/(\d|[0-2]\d{1}|3[0-1])\/(19|20)(\d{2})/
	var m = date.match(pattern);
	if (!m)
		return false;
	var d = new Date(date);
	// Now let's ensure that the date is not out of index


	if (d.getMonth()+1 == parseInt(m[1], 10) && d.getDate() ==parseInt(m[2], 10)) {
		return true;
	}
	return false;
}

export const getUnitPriceDisplay = (product: any) => {
	if (product.PercentOff > 0) {
		return `<s style="color: #949494">$${product.BasePrice.toFixed(2)}</s><p>$${(product.BasePrice * ((100 - product.PercentOff)/100)).toFixed(2)}</p>`
	} else
		return `<p>$${product.BasePrice.toFixed(2)}</p>`
}

export const getPercentOffPrice = (product: any) => {
	const price = product.PercentOff > 0 ? (product.BasePrice * ((100 - product.PercentOff)/100)) : product.BasePrice
	return product.Quantity !== undefined ? (price * product.Quantity) : price * product.SelectedQuantity
}

export const isValidPaymentMethod = (uid: string, snippetProfile?: ClubSignupSnippetProfile) => {
	if (!snippetProfile)
		return true
	
	if (!snippetProfile.CapturePaymentMethod)
		return true
	
	return !!uid;
	
	
}

export const isEmpty = (value: string) => {
	return value.length === 0
}

export const isStringHtml = (value: string) => {
	return /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/.test(value)
}

export const doesCountryHaveStates = (countries: Array<ICountry>, country: string): boolean => {
	let selectedCountry = countries?.find((curr: ICountry) => curr.Code === country);
	if (selectedCountry === undefined || selectedCountry === null)
		return false;
	else
		return selectedCountry.HasStates;
}

export const getButtonText = (configs: Array<ButtonConfig>, name: string) => {
	const config = configs.find((c) => c.ButtonName === name)
	return config?.Text ?? getDisplayValue(name);
	
}

export const getCurrencyDisplayValue = (value: number) => {
	return value.toFixed(2)
}

export const getDisplayValue = (text: string | null) => {
	if (text === null)
		return '';


	let displayString = '';
	let lastChar = '\0'
	Array.from(text).forEach((ch) => {
		if (displayString.length !== 0 && ((ch >= 'A') && (ch <= 'Z')) && (((lastChar < 'A') || (lastChar > 'Z')) && (lastChar != ' ')))
			displayString += ' ';

		displayString += ch;
		lastChar = ch;
	})

	return displayString;
}

export let languageCode = 'en-US'

export const updateLanguageCode = (profile: any) => {
	if (profile && profile.Globalization.LanguageCode !== 0 && !profile.Globalization.LanguageCode.includes('0'))
		languageCode = profile.Globalization.LanguageCode ?? 'an-AU'
}

export const updateLanguageCodeFromBrowser = (code: string) => {
    if (code)
        languageCode = code
}

export const formatDate = (inputDate: any): string | null => {
	if (inputDate === undefined || inputDate === null)
		return null

	let options = {year: "numeric", month: "2-digit", day: "2-digit"}
	if (inputDate.includes('T')) {
		let date = new Date(inputDate);
		// @ts-ignore
		return date.toLocaleDateString('en-US', options);
	}
	
	var date = new Date(inputDate.replace(/-/g, '\/'));
	if (!isNaN(date.getTime())) {
		const month = date.getUTCMonth() + 1 // Months use 0 index.
		const day = date.getUTCDate()
		// return `${month < 10 ? `0${month}` : month}/${day < 10 ? '0' : ''}${day}/${date.getFullYear()}`;
		console.log(languageCode)
		// @ts-ignore
		return date.toLocaleDateString(languageCode, options)
	}

	return inputDate.toLocaleString();
}

export let savedPayments: Array<string> = []

export const addPayment = (token: string) => {
	savedPayments.push(token)
}

export const hasPaymentToken = (token: string) => {
	return savedPayments.includes(token)
}

export const clearPayments = () => {
	savedPayments = []
}

export const flattenAddress = (address: IShippingAddress) => {
	return {
		...address,
		Address1: address.Address.Address1,
		Address2: address.Address.Address2,
		City: address.Address.City,
		State: address.Address.State,
		PostalCode: address.Address.PostalCode,
		Country: address.Address.Country,
	}
}

export const createOptions = (max: number) => {
	let options: { text: string, value: string }[] = []
	for (let i = 1; i <= max; i++) {
		options.push({text: i.toString(), value: i.toString()})
	}
	return options
}

const monthNames = ["January","February","March","April","May","June","July","August","September","October","November","December"];

export const createStartDateOptions = (max: number) => {
	const date = new Date()
	let year = date.getFullYear()
	let month = date.getMonth()
	let options: Array<{text: string, value: string}> = []
	for(let i = 0; i < max; i++) {
		options.push({text: `${monthNames[month]} ${year}`, value: i.toString()})
		if (month === 11) {
			month = 0
			year++
		}
		else
			month++
	}
	
	return options
}

const addMonths = (date: Date, months: number) => {
	const d = date.getDate();
	date.setMonth(date.getMonth() + +months);
	if (date.getDate() != d) {
		date.setDate(0);
	}
	return date;
}

export const getStartDate = (numMonths: number) => {
	const date = new Date()
	return addMonths(date, numMonths)
}

export const getCartLine = (cart: any, lineUid: string) => {
	if (!cart || cart.Lines.length === 0 || !lineUid)
		return null
	
	let foundLine = null
	cart.Lines.forEach((line: any) => {
		console.log(line)
		if (line.Uid.toLowerCase() === lineUid.toLowerCase()) {
			foundLine = line
		}
	})
	
	return foundLine
}

export const getCartLineByProductCode = (cart: any, productCode: string) => {
	if (!cart || cart.Lines.length === 0 || !productCode)
		return null
	
	let foundLine = null
	cart.Lines.forEach((line: any) => {
		if (line.ProductCode.toLowerCase() === productCode.toLowerCase()) {
			foundLine = line
		}
	})

	return foundLine
}

export const getCustomerFullName = (customer: any) => {
	let name = ''
	if (customer.FirstName) 
		name += customer.FirstName
	
	if (name !== '')
		name += ' '
	
	if (customer.LastName)
		name += customer.LastName
	
	return  name;
} 

export const getPaymentStrategyOptions = (): Array<SelectOption> => {
	let options: Array<SelectOption> = []
	for (const type in ClubPaymentStrategy) {
		options.push({text: getDisplayValue(type), value: type})
	}
	return options
}

export const mapCustomCodes = (customFields: Array<{code: string, value: string}>) => {
	if (customFields.length === 0)
		return {};

	let fields = {};

	let codes = customFields.map((field: any) => {
		return {
			[field.code]: field.value,
		}
	})

	const reducer = (acc: any, currentValue: any) => {
		return {
			...acc,
			...currentValue,
		}
	}

	fields = codes.reduce(reducer);
	return fields;
}

export const getCustomCodesArray = (codes: object) => {
	let array: Array<{code: string, value: string}> = []
	Object.keys(codes).forEach((key) => {
		array.push({
			code: key,
		// @ts-ignore
			value: codes[key]
		})
	})
	return array
}

export const onSuccessRedirect = (redirectUrl: string, delay?: number) => {
	window.setTimeout(function () {
		let url = redirectUrl ?? window.location.host;
		url = url.includes("http") ? url : `https://${url}`
		window.location.assign(url)
	}, (delay ?? 0) * 1000)
}

export const getClubUid = (club: any, clubs: Array<any>) => {
	if (clubs.length === 0)
		return undefined
	
	let uid = ""
	clubs.forEach((c) => {
		if (c.Code === club.Code)
			uid = c.Uid
	})
	return uid;
}

export const getParameterCaseInsensitive = (object: any, key: string) => {
	const asLowercase = key.toLowerCase();
	// @ts-ignore
    return object[Object.keys(object).find(k => k.toLowerCase() === asLowercase)]
}

export const getInputField = (fields: Array<IInputConfig>, field: string) => {
	return fields.find(f => f.FieldName === field)
}

export const mapCreditCardType = (cardType: string): CreditCardType => {
	switch (cardType) {
		case 'Visa':
			return CreditCardType.Visa;
		case 'Master':
			return CreditCardType.Mastercard;
		case 'AmEx':
			return CreditCardType.AmericanExpress;
		case 'Discover':
			return CreditCardType.Discover;
		default:
			return CreditCardType.Unknown;
	}
}

export const mapCardConnectCardType = (cardType: string): CreditCardType => {
	switch (cardType) {
		case '2':
		case '5':
			return CreditCardType.Mastercard;
		case '4':
			return CreditCardType.Visa;
		case '3':
			return CreditCardType.AmericanExpress;
		case '6':
			return CreditCardType.Discover;
		default:
			return CreditCardType.Unknown;
	}
}

export const isInViewport = (element: any) => {
	const rect = element?.getBoundingClientRect();
	return (
		rect.top >= 0 &&
		rect.left >= 0 &&
		rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
		rect.right <= (window.innerWidth || document.documentElement.clientWidth)
	);
}

export const buildCustomer = (state: ISignupState) => {
	const customer = {
		ExternalId: null,
		PartitionUid: null,
		PartitionCode: 'All',
		Code: '-1',
		FirstName: state.FirstName ?? null,
		LastName: state.LastName ?? null,
		FirstName2: state.FirstName2 ?? null,
		LastName2: state.LastName2 ?? null,
		Address: {
		Address1: state.Address1 ?? null,
			Address2: state.Address2 ?? null,
			City: state.City ?? null,
			State: state.State ?? null,
			PostalCode: state.PostalCode ?? null,
			Country: state.Country ?? null
		},
		CompanyName: state.CompanyName ?? null,
		Phone1: state.Phone1 ?? null,
		MobilePhone: state.MobilePhone ?? null,
		EmailAddress: state.EmailAddress ?? null,
		FacebookId: null,
		TwitterId: null,
		MobileDeviceId: null,
		LoyaltyRedemptionDisabled: false,
		LoyaltyAccrualDisabled: false,
		LoyaltyCardNumber: '',
		GuestCustomer: false,
		EditAtPOS: true,
		WebAccount: true,
		Verified: false,
		TaxExempt: false,
		BirthDate: null,
		LoyaltyPointsAccounts: [],
		LoyaltyCurrencyAccounts: [],
		Type: null,
		PriceLevel: null,
		TransactionCount: 0,
		TotalSales: 0.0000,
		ClubMemberships: [],
		SubscriberGroupMemberships: [],
		LoyaltytProgramMemberships: [],
		LoyaltyProgramMemberships: [],
		SignupChannelUid: '',
		SignupStoreUid: '',
		SignupStoreCode: '',
		SignupStoreExternalId: null,
		NoEmail: 'False',
		Salutation: null,
		ParentCustomerUid: null,
		ParentCustomerCode: null,
		ParentExternalId: null,
		ReferralCustomer1Uid: null,
		ReferralCustomer1Code: null,
		ReferralCustomer2Uid: null,
		ReferralCustomer2Code: null,
		CustomCode1: null,
		CustomCode2: null,
		CustomCode3: null,
		CustomCode4: null,
		CustomCode5: null,
		CustomCode6: null,
		CustomCode7: null,
		CustomCode8: null,
		AlertCount: 0,
		Created: null,
		CreatedLocal: null
	}
}
