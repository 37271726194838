import React, {createContext, useState} from 'react';
import {SnippetTheme} from "../Helpers/Types";

// @ts-ignore
const StyleContext = createContext();
StyleContext.displayName = 'StyleContext';

type StyleProviderProps = {
    theme: SnippetTheme
    setTheme: Function
    children: any
}

function StyleProvider(props: StyleProviderProps) {

    const theme = props.theme;
    const setTheme = props.setTheme;

    const value = React.useMemo(() => ({ theme: theme, setTheme: setTheme }), [
        props.theme, props.setTheme
    ]);

    return <StyleContext.Provider value={value} {...props} />;
}

function useStyleContext() {
    const context = React.useContext(StyleContext);
    if (context === undefined) {
        throw new Error(`useStyleContext must be used within a StyleProvider`);
    }
    return context;
}

export {StyleProvider, useStyleContext};
