import {DeliveryType} from './Helpers/Types';
import {Action} from 'typesafe-actions';

export enum ShippingActionTypes {
	UpdateOrderType = 'Shipping/UpdateOrderType',
	UpdateShippingAddress = 'Shipping/UpdateShippingAddress',
	UpdateInventoryLocation = 'Shipping/UpdateInventoryLocation'
}

export interface IUpdateOrderTypeAction extends Action<ShippingActionTypes.UpdateOrderType> {
	orderType: DeliveryType
}

export interface IUpdateShippingAddressAction extends Action<ShippingActionTypes.UpdateShippingAddress> {
	address: string,
}

export interface IUpdateInventoryLocation extends Action<ShippingActionTypes.UpdateInventoryLocation> {
	location: string
}

export type ShippingAction =
	| IUpdateOrderTypeAction
	| IUpdateShippingAddressAction
	| IUpdateInventoryLocation


export const updateOrderType = (orderType: DeliveryType) => {
	return {
		type: ShippingActionTypes.UpdateOrderType,
		orderType,
	}
}

export const updateShippingAddress = (address: string) => {
	return {
		type: ShippingActionTypes.UpdateShippingAddress,
		address,
	}
}

export const updateInventoryLocation = (location: string) => {
	return {
		type: ShippingActionTypes.UpdateInventoryLocation,
		location,
	}
}