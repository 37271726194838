import {queryCache, useMutation} from 'react-query';
import {client} from './ApiClient';
import {ICustomer} from '../Helpers/Types';

const saveMember = ({urls, session, domain, deviceCode, member}: {urls: any; session: string; domain: string; deviceCode: string; member: any}) => {
	console.log(member)
	return client(urls.LoyaltyEngineApiUrl, `api/v4/${domain}/${deviceCode}/sessions/${session}/customers/commands/saves`, {
		body: {
			...member
		}
	});
}

export default function useSaveMember() {
	// @ts-ignore
	return useMutation(saveMember, {
		onSuccess: () => {
			queryCache.invalidateQueries('CustomerSession')
		},
	})
}