import {useQuery} from 'react-query';
import {client, logout} from './ApiClient';
import {mapServiceUrls} from '../Helpers/Functions';

const getClubMemberships = (key: any, urls: any, domain: string, deviceCode: string, session: string) => {
	return client(urls.LoyaltyEngineApiUrl, `api/v4/${domain}/${deviceCode}/sessions/${session}/clubmemberships`);
}

export default function useClubMemberships(urls: any, domain: string, deviceCode: string, session: string) {
	const {status, data, error, isFetching } = useQuery(['ClubMembership'], (key) => getClubMemberships(key, mapServiceUrls(urls), domain, deviceCode, session), { enabled: urls, staleTime: 1000*60*5 });
	const membershipsStatus = status;
	const memberships = data ? data.data : undefined;
	const membershipsError = error;
	const membershipsIsFetching = isFetching;
	if (error === 'Expired key')
		logout();

	return {membershipsStatus, memberships, membershipsError, membershipsIsFetching};
}
