import {queryCache, useMutation} from 'react-query';
import {client} from './ApiClient';
import {mapServiceUrls} from "../Helpers/Functions";

const closeClub = ({urls, session, domain, deviceCode, customerUid, clubUid}: {urls: any; session: string; domain: string; deviceCode: string; customerUid: string; clubUid: any;}) => {
	return client(mapServiceUrls(urls).LoyaltyEngineApiUrl, `api/v4/${domain}/${deviceCode}/sessions/${session}/clubmembership/commands/close`, {
		body: {
			CustomerUid: customerUid,
			ClubUid: clubUid,
		}
	});
}

export default function useCloseClub() {
	// @ts-ignore
	return useMutation(closeClub, {
		onSuccess: () => {
			queryCache.invalidateQueries('ClubMembership')
		},
	})
}