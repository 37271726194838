import {useQuery} from 'react-query';
import {client} from './ApiClient';
import {mapServiceUrls} from '../Helpers/Functions';

const getTrackedCards = (key: any, urls: any, domain: string, deviceCode: string, sessionKey: string) => {
	return client(urls.LoyaltyEngineApiUrl, `api/v4/${domain}/${deviceCode}/snippets/trackedCard/${sessionKey}`);
}

export default function useTrackedCards(urls: any, domain: string, deviceCode: string, sessionKey: string) {
	const {status, data, error, isFetching } = useQuery(['TrackedCards', sessionKey], (key) => getTrackedCards(key, mapServiceUrls(urls), domain, deviceCode, sessionKey), { enabled: urls, staleTime: 1000*60*60 });
	const trackedCardsStatus = status;
	const trackedCards = data ? data.data : undefined;
	const trackedCardsError = error;
	return {trackedCardsStatus, trackedCards, trackedCardsError};
}
