import React, {useState} from 'react';
import {AvailableCoupon, ISyntheticEvent} from "../Helpers/Types";
import QRCode from "react-qr-code";

type DashboardCouponDisplayProps = {
    coupon: AvailableCoupon
}

export default function DashboardCouponDisplay(props: DashboardCouponDisplayProps) {
    const [flipped, setFlipped] = useState(false)
    
    const flip = (e: ISyntheticEvent) => {
        e.preventDefault();
        setFlipped(!flipped)
    }
    
    return (
        <div className='bl-dashboard-coupon bLoyal-center' onClick={flip}>
            {!flipped &&
                <>
                    <p className='bl-coupon-name bLoyal-center'>{props.coupon.Name}</p>
                    <p className='bl-coupon-code bLoyal-center'>{props.coupon.Code}</p>
                    {props.coupon.QuantityAvailable !== null &&
                        <>
                            <br />
                            <p>Quantity Available: {props.coupon.QuantityAvailable}</p>
                        </>
                    }
                </>
            }
            {flipped &&
                <>
                    <p className='bl-coupon-name bLoyal-center'>{props.coupon.Name}</p>
                    {/*@ts-ignore*/}
                    <QRCode value={props.coupon.Code} size={100}/>
                    <p className='bl-coupon-code bLoyal-center' style={{marginTop: '8px'}}>{props.coupon.Code}</p>
                </>
            }
        </div>
    )
}