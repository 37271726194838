import {queryCache, useMutation} from 'react-query';
import {client} from './ApiClient';

const deletePaymentMethod = ({urls, session, domain, deviceCode, paymentUid}: {urls: any; session: string; domain: string; deviceCode: string; paymentUid: string}) => {
	return client(urls.LoyaltyEngineApiUrl, `api/v4/${domain}/${deviceCode}/sessions/${session}/PaymentMethods/${paymentUid}`, {
		customConfig: {
			method: 'DELETE'
		}
	});
}

export default function useDeletePaymentMethod() {
	// @ts-ignore
	return useMutation(deletePaymentMethod, {
		onMutate: ({paymentUid}: {urls: any; session: string; domain: string; deviceCode: string; paymentUid: string}) => {
			// @ts-ignore
			const previousPayments = queryCache.getQueryData('PaymentMethods')
			queryCache.setQueryData('PaymentMethods', (old: any) => {
                const updated = old.data.filter((curr: any) => curr.Uid !== paymentUid)
                return {...old, data: updated }
            })
			return () => queryCache.setQueryData('PaymentMethods', previousPayments);
		},
		onSuccess: () => {
			queryCache.invalidateQueries('PaymentMethods')
		},
		onError: (err, data, rollback: Function) => rollback()
	})
}