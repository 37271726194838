import React, {SyntheticEvent, useState} from 'react';
import {ISyntheticEvent, SnippetTheme} from './Helpers/Types';
import {TextField} from '@material-ui/core';
import {useStyleContext} from "./Utils/UseStyleContext";

type NumberInputProps = {
	id: string,
	value: number,
	label: string,
	getValue?: (value: number) => void
	handleChange?: (fieldName: string, value: any) => void
	className?: string
	max?: number
	min?: number
    showError?: boolean
}

export default function NumberInput(props: NumberInputProps) {
	const [value, setValue] = useState(props.value);
    const [showError, setShowError] = useState(false)
	// @ts-ignore
	const {theme} = useStyleContext();
	
	const handleChange = (event: ISyntheticEvent) => {
		event.preventDefault();
		let newValue = parseInt(event.target.value);
		if (props.max !== undefined && newValue > props.max)
			return;
        
        if (showError && isValid(newValue))
            setShowError(false)
		
		setValue(newValue);
		props.getValue?.(newValue);
		props.handleChange?.(props.id, newValue);
	};
    
    const isValid = (current: number) => {
        if (props.min === undefined && props.max === undefined)
            return true;
        
        if (props.min !== undefined && current < props.min)
            return false
        
        if (props.max !== undefined && current > props.max)
            return false
        
        return true
    }
    
    const handleBlur = (e: SyntheticEvent) => {
        if (!isValid(value) && !showError)
            setShowError(true)
        else
            setShowError(false)
    }
	
	switch (theme) {
		case SnippetTheme.MaterialUI:
			return (
                <>
                    <TextField
                        id={props.id}
                        aria-label={props.id}
                        label={props.label}
                        value={value}
                        type='number'
                        onChange={handleChange}
                        className={props.className}
                        inputProps={{min: 0, max: props.max}}
                        onBlur={handleBlur}
                    />
                    {showError && props.showError && <p>Please enter a valid custom amount.</p>}
                </>
			);
		default:
			return (
                <>
                    <label >{props.label + ""}
				        <input className={'bLoyal-number-input'} style={{width: '100px'}} type="number" id={props.id} onChange={handleChange} value={value} min={props.min ?? 0} max={props.max} onBlur={handleBlur}/>
			        </label>
                    {showError && props.showError && <p>Please enter a valid custom amount.</p>}
                </>
            )
	}
}