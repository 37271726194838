import React, {Fragment, useEffect, useMemo, useReducer, useState} from 'react';
import useSnippetConfig from './Utils/UseSnippetConfig';
import useServiceUrls from './Utils/UseServiceUrls';
import LoadingSpinner from './LoadingSpinner';
import {
    ICountry,
    ICustomFieldType,
    IQuickSignupField,
    ISelectOption,
    ISignupState,
    IState,
    ISyntheticEvent,
    PasswordState, SubscriberGroupConfig,
    SnippetType, LegacySubscriberGroupConfig
} from './Helpers/Types';
import TextInput from './TextInput';
import {
    SignupAction,
    SignupActionTypes,
    updateCustomSignupFieldAction,
    updateSignupFieldAction,
    updateSubscriberGroupsFieldAction
} from './SignupActions';
import UseSignupCustomer from './Utils/UseSignupCustomer';
import {doesCountryHaveStates, mapServiceUrls} from './Helpers/Functions';
import PasswordField from './PasswordField';
import EmailInput from './EmailInput';
import {useSnippetContext} from './Utils/UseSnippetContext';
import {useAuth} from './Utils/UseAuth';
import useDeviceSessionProfile from './Utils/UseDeviceSessionProfile';
import NumberInput from './NumberInput';
import {useDeviceSessionContext} from './Utils/UseDeviceSessionProfileContext';
import SelectInput from './SelectInput';
import useCountries from './Utils/UseCountries';
import useStates from './Utils/UseStates';
import {useStyleContext} from "./Utils/UseStyleContext";
import CheckboxInput from "./CheckboxInput";
import {v4 as uuid} from 'uuid'

export const signupState: ISignupState = {
    SubscriberGroups: [],
    BirthdayMonth1: '',
    BirthdayMonth2: '',
    FirstName2: '',
    LastName2: '',
    LoyaltyCardNumber: '',
    Password: '',
    VerifyPassword: '',
    FirstName: '',
    LastName: '',
    CompanyName: '',
    Address1: '',
    Address2: '',
    City: '',
    State: '',
    PostalCode: '',
    Country: '',
    EmailAddress: '',
    Phone1: '',
    MobilePhone: '',
    BirthDate: '',
    CustomFields: []
};

export const signupReducer = (state = signupState, action: SignupAction): ISignupState => {
    switch (action.type) {
        case SignupActionTypes.UpdateCustomField:
            if (state.CustomFields.filter(field => field.Key === action.fieldName).length > 0){
                let newArray = state.CustomFields.map(field => {
                    if (field.Key === action.fieldName)
                        return { Key: action.fieldName, Value: action.value }
                    return field;
                })
                return {...state, CustomFields: newArray}
            }
            let fields = state.CustomFields
            fields.push({Key: action.fieldName, Value: action.value})
            return {...state, CustomFields: fields}
        case SignupActionTypes.UpdateField:
            return {
                ...state,
                [action.fieldName]: action.value
            };
        case SignupActionTypes.UpdateSubscriberGroupsField:
            return {
                ...state,
                SubscriberGroups: action.value
            }
        default:
            return state;
    }
};

type LegacySignupSnippetProps = {
    snippetCode: string
    isStandalone: boolean
    setShowSignup?: Function;
}

export default function LegacySignupSnippet(props: LegacySignupSnippetProps) {
    // @ts-ignore
    const {setSession} = useAuth();
    // @ts-ignore
    const {profile} = useDeviceSessionContext();
    signupState.Country = profile.DefaultCountry;
    const [state, dispatch] = useReducer(signupReducer, signupState);
    // @ts-ignore
    const { domain, deviceCode } = useSnippetContext();
    // @ts-ignore
    const {theme, setTheme} = useStyleContext();
    const { serviceUrls } = useServiceUrls(domain);
    const { deviceSessionProfile } = useDeviceSessionProfile(serviceUrls, domain, deviceCode)
    const { SnippetConfig: config } = useSnippetConfig(serviceUrls, domain, deviceCode, props.snippetCode);
    const [signupCustomer, { data, isSuccess, isLoading, isError, error, reset }] = UseSignupCustomer();
    const [signupFields, setSignupFields] = useState<Array<IQuickSignupField>>([]);
    const [subscriberGroupFields, setSubscriberGroupFields] = useState<Array<LegacySubscriberGroupConfig>>([])
    const [passwordState, setPasswordState] = useState(PasswordState.Unsubmitted);
    const [passwordDisplayed, setPasswordDisplayed] = useState(false);

    const { countries } = useCountries(serviceUrls, domain, deviceCode);
    const { states } = useStates(serviceUrls, domain, deviceCode, state.Country, countries && doesCountryHaveStates(countries, state.Country));

    const [countryOptions, setCountryOptions] = useState<Array<ISelectOption>>([]);
    const [stateOptions, setStateOptions] = useState<Array<ISelectOption>>([]);

    const SnippetConfig = useMemo(() => config?.Signup, [config])

    useEffect(() => {
        setTheme(SnippetConfig?.Theme);
    }, [SnippetConfig])

    useEffect(() => {
        if (!countries)
            return;

        const countryList = countries.map((country: ICountry) => {
            return {
                value: country.Code,
                text: country.Name
            };
        });

        setCountryOptions(countryList);
    }, [countries]);

    useEffect(() => {
        if (!states)
            return;

        const stateList = states.map((state: IState) => {
            return {
                value: state.Code,
                text: state.Name
            };
        });

        setStateOptions(stateList);
    }, [states]);

    useEffect(() => {
        if (SnippetConfig) {
            setSignupFields(SnippetConfig.QuickSignupFields);
            setSubscriberGroupFields(SnippetConfig.SubscriberGroups)
            let groups: Array<string> = [];
            SnippetConfig.SubscriberGroups.forEach((group: any) => {
                if (group.IsSubscribe)
                    groups.push(group.Uid);
            })
            // @ts-ignore
            dispatch(updateSubscriberGroupsFieldAction(groups))
        }
    }, [SnippetConfig]);

    useEffect(() => {
        if (isSuccess) {
            if (data.data.SessionKey && domain.toLowerCase() !== 'landrys')
                setSession(data.data.SessionKey, data.data.Customer);
            
            if (props.setShowSignup)
                    props.setShowSignup(false);
            
            if (props.isStandalone)
                if (SnippetConfig.IsReturnSuccessUrl && SnippetConfig.SuccessUrlOrSnippet !== '')
                    window.location.assign(SnippetConfig.SuccessUrlOrSnippet)
        }
    }, [isSuccess, data]);

    const handleChange = (fieldName: string, value: string) => {
        // @ts-ignore
        dispatch(updateSignupFieldAction(fieldName, value));
    };

    const submit = (event: ISyntheticEvent) => {
        event.preventDefault();
        if (state.Password && state.Password !== state.VerifyPassword) {
            setPasswordState(PasswordState.Unmatching);
            return;
        }
        setPasswordState(PasswordState.Matching)
        signupCustomer({
            urls: mapServiceUrls(serviceUrls), deviceCode, signupState: state, domain, session: ''
        });
    };

    const handleCustomFieldChange = (fieldName: string, value: string) => {
        // @ts-ignore
        dispatch(updateCustomSignupFieldAction(fieldName, value))
    }

    const handleSubscriberGroupChange = (value: boolean, group: string) => {
        let currentGroups = state.SubscriberGroups;
        if (value)
            currentGroups.push(group);
        else
            currentGroups = currentGroups.filter((curr) => curr !== group)

        // @ts-ignore
        dispatch(updateSubscriberGroupsFieldAction(currentGroups))
    }

    const back = (e: ISyntheticEvent) => {
        e.preventDefault();
        props.setShowSignup?.(false);
    }

    const mapCustomField = (field: IQuickSignupField, index: number) => {
        var fieldDefinition = deviceSessionProfile.CustomerCustomFields?.find((cf: any) => cf.Code === field.Code);

        if (fieldDefinition === undefined)
            fieldDefinition = deviceSessionProfile.CustomerCustomFields?.find((cf: any) => cf.Name === field.Name);

        const mapListOptions = (options: Array<any>) => {
            return options.map((option: any) => {
                return {
                    text: option.Name,
                    value: option.Value,
                }
            })
        }

        let value = state.CustomFields.find((curr: any) => curr.Key === fieldDefinition.Code)
        switch (fieldDefinition.FieldType) {

            case ICustomFieldType.Int:
            case ICustomFieldType.Decimal:
                return (
                    <Fragment key={index}>

                        <NumberInput id={fieldDefinition.Code} value={value?.Value ?? ''} label={field.Name}
                                     handleChange={handleCustomFieldChange}/>
                        <br/>
                    </Fragment>
                )
            case ICustomFieldType.ValidatedList:
                return (
                    <Fragment key={index}>
                        <SelectInput options={mapListOptions(fieldDefinition.ValidValues)} label={field.Name} current={value?.Value ?? ""} fieldName={fieldDefinition.Code} handleChange={handleCustomFieldChange} disabled={false} required={field.IsRequired}/>
                    </Fragment>
                )
            case ICustomFieldType.Money:
            case ICustomFieldType.Date:
            case ICustomFieldType.Unknown:
            case ICustomFieldType.Time:
            case ICustomFieldType.DateTime:
            case ICustomFieldType.Bool:
            case ICustomFieldType.Byte:
            case ICustomFieldType.Blob:
            case ICustomFieldType.List:
            case ICustomFieldType.MultiSelectValidatedList:
            case ICustomFieldType.String:
            default:

                return (
                    <Fragment key={index}>

                        <TextInput id={field.Code} value={value?.Value ?? ''} label={field.Name}
                                   handleChange={handleCustomFieldChange}/>
                        <br/>
                    </Fragment>
                )
        }
    }


    const mapSignupField = (field: IQuickSignupField, index: number) => {
        if (!field.IsIncluded && !field.IsRequired)
            return;

        if (field.Type === "CustomField" || field.Type === "CustomCode") {
            return mapCustomField(field, index);
        }

        // @ts-ignore
        let value = state[field.Name];
        let inputField: any;
        const label = field.Editable;
        switch (field.Name) {
            case 'Password':
                if (!passwordDisplayed)
                    setPasswordDisplayed(true);

                inputField = <>
                    <PasswordField required={field.IsRequired} id={field.Name} value={''} label={label} handleChange={handleChange}/>
                    <br />
                    <PasswordField id={'VerifyPassword'} value={''} label={`Verify Password`} required={field.IsRequired} handleChange={handleChange}/>
                </>;
                break;
            case 'EmailAddress':
                inputField = <EmailInput required={field.IsRequired} id={field.Name} value={''} label={label} handleChange={handleChange}/>;
                break;
            case 'Country':
                return (
                    <Fragment key={index}>
                        <SelectInput current={value} disabled={false} fieldName={field.Name} handleChange={handleChange} options={countryOptions}
                                     label={label} required={field.IsRequired}/>
                    </Fragment>
                );
            case 'State':
                if (state.Country === '') {
                    return;
                }
                const selected: ICountry = countries?.find((curr: ICountry) => curr.Code === state.Country);
                if (selected?.HasStates)
                    return (
                        <Fragment key={index}>
                            <SelectInput current={value} disabled={false} fieldName={field.Name} handleChange={handleChange}
                                         options={stateOptions} label={label} required={field.IsRequired}/>
                        </Fragment>
                    );
                else
                    inputField = <TextInput required={field.IsRequired} id={field.Name} value={''} label={label} handleChange={handleChange}/>;

                break;
            default:
                inputField = <TextInput required={field.IsRequired} id={field.Name} value={''} label={label} handleChange={handleChange}/>;
        }
        return (
            <Fragment key={index}>
                {inputField}
                <br/>
            </Fragment>
        );
    };

    const mapSubscriberGroupBoxes = (group: LegacySubscriberGroupConfig, index: number) => {
        if (!group.IsDisplay)
            return;

        const value = state.SubscriberGroups.includes(group.Uid);
        return (
            <div style={{textAlign: 'left'}}>
                <CheckboxInput key={group.Uid} id={group.Uid} value={value} label={group.Name} handleChange={handleSubscriberGroupChange} />
            </div>
        )
    }

    if (isError) {
        alert(error);
        reset();
    }

    return (
        <div className='bLoyal-center form bLoyal-signup-page'>
            {!SnippetConfig &&
            <LoadingSpinner/>
            }
            {isSuccess && !SnippetConfig.IsReturnSuccessMessage &&
            <h2>Signup Complete</h2>
            }
            {isSuccess && SnippetConfig.IsReturnSuccessMessage &&
            <p>{SnippetConfig.SuccessMessage}</p>
            }
            {SnippetConfig && !isSuccess &&
            <>
                <form onSubmit={submit}>
                    <div className='bLoyal-center bLoyal-input-holder'>
                        {signupFields.map(mapSignupField)}
                        {subscriberGroupFields.some((group) => group.IsDisplay) &&
                        <h4>Subscriber Groups</h4>
                        }
                        {subscriberGroupFields.map(mapSubscriberGroupBoxes)}
                    </div>
                    {props.setShowSignup &&
                    <button className='bl-snippet-button' onClick={back}>Back</button>
                    }
                    <button className='bl-snippet-button' type='submit'>Sign up</button>
                </form>
                {passwordState === PasswordState.Unmatching &&
                <p className='bLoyal-error-text'>Passwords must match.</p>
                }
                {isLoading &&
                <LoadingSpinner/>
                }
                {isError &&
                <p className='bLoyal-error-text'>There was an error completing the request.</p>
                }
            </>
            }
        </div>
    );
}