import React, {Fragment, SyntheticEvent, useEffect, useMemo, useReducer, useState} from 'react';
import {
    CustomerDetailsSnippetProfile, CustomFieldDisplayType,
    ICountry,
    ICustomFieldType,
    IInputConfig, ISelectOption, IState, PasswordState,
    WebSnippetProfile
} from "../Helpers/Types";
import {
    doesCountryHaveStates,
    formatDate,
    getDisplayValue,
    mapServiceUrls,
    updateLanguageCode
} from "../Helpers/Functions";
import PasswordField from "../PasswordField";
import EmailInput from "../EmailInput";
import SelectInput from "../SelectInput";
import TextInput from "../TextInput";
import NumberInput from "../NumberInput";
import RadioSelection from "../ClubPreference/Sections/RadioSelection";
import useCountries from "../Utils/UseCountries";
import useStates from "../Utils/UseStates";
import {useSnippetContext} from "../Utils/UseSnippetContext";
import useServiceUrls from "../Utils/UseServiceUrls";
import {buildSignupState, signupReducer, signupState} from "../SignupSnippet";
import {useDeviceSessionContext} from "../Utils/UseDeviceSessionProfileContext";
import {useAuth} from "../Utils/UseAuth";
import {updateSignupFieldAction} from "../SignupActions";
import useSaveMember from "../Utils/UseSaveMember";
import LoadingSpinner from "../LoadingSpinner";
import SnippetHeader from "../Helpers/SnippetHeader";
import SnippetFooter from "../Helpers/SnippetFooter";
import useGetCustomerSession from "../Utils/UseGetCustomerSession";

type CustomerDetailsSnippetProps = {
    snippetProfile: WebSnippetProfile,
    saveLabel?: string,
    backLabel?: string,
    onBack?: Function
    onComplete?: Function
}

export default function CustomerDetailsSnippet(props: CustomerDetailsSnippetProps) {
    const snippetProfile = props.snippetProfile.CustomerDetails
    // @ts-ignore
    const {profile} = useDeviceSessionContext();
    // @ts-ignore
    const { customer, session, setSession } = useAuth()
    const [state, dispatch] = useReducer(signupReducer, buildSignupState(customer, profile.DefaultCountry));
    const [passwordState, setPasswordState] = useState(PasswordState.Unsubmitted);
    const [passwordDisplayed, setPasswordDisplayed] = useState(false);
    const [customCodes, setCustomCodes] = useState<Array<{code: string, value: string}>>([])
    const [done, setDone] = useState(false)
    const [changed, setChanged] = useState(false)
    // @ts-ignore
    const { domain, deviceCode } = useSnippetContext();
    const { serviceUrls } = useServiceUrls(domain);
    const { customerSession, status: customerStatus } = useGetCustomerSession(serviceUrls, session, domain, deviceCode)
    const { countries } = useCountries(serviceUrls, domain, deviceCode);
    const { states } = useStates(serviceUrls, domain, deviceCode, state.Country, countries && doesCountryHaveStates(countries, state.Country));

    const [countryOptions, setCountryOptions] = useState<Array<ISelectOption>>([]);
    const [stateOptions, setStateOptions] = useState<Array<ISelectOption>>([]);
    
    const [saveMember, {data, status, error}] = useSaveMember()

    useEffect(() => {
        if (customerStatus === 'success') {
            setSession(customerSession.SessionKey, customerSession.Customer)
            if (done)
                window.setTimeout(function () {
                    props.onComplete?.()
                }, (1500))
        }
    }, [customerStatus, customerSession]);

    useEffect(() => {
        updateLanguageCode(profile)
    }, [profile])

    useEffect(() => {
        if (status === 'success') {
            if (changed)
                setDone(true)
            else
                window.setTimeout(function () {
                    props.onComplete?.()
                }, (1500))
        }
    }, [status, props.onComplete])    

    const handleCustomFieldChange = (field: string, value: any) => {
        if (!changed)
            setChanged(true)
        
        let fields = customCodes.slice()
        let found = false
        fields = fields.map((f) => {
            if (f.code === field) {
                found = true
                return {
                    code: field,
                    value: value,
                }
            }

            return f
        })

        if (!found)
            fields.push({code: field, value: value})

        setCustomCodes(fields)
    }

    useEffect(() => {
        if (!countries)
            return;

        const countryList = countries.map((country: ICountry) => {
            return {
                value: country.Code,
                text: country.Name
            };
        });

        setCountryOptions(countryList);
    }, [countries]);

    useEffect(() => {
        if (!states)
            return;

        const stateList = states.map((state: IState) => {
            return {
                value: state.Code,
                text: state.Name
            };
        });

        setStateOptions(stateList);
    }, [states]);

    const handleChange = (fieldName: string, value: string) => {
        if (!changed)
            setChanged(true)
        // @ts-ignore
        dispatch(updateSignupFieldAction(fieldName, value));
    };
    
    const saveCustomer = (event: SyntheticEvent) => {
        event.preventDefault()
        saveMember({urls: mapServiceUrls(serviceUrls), deviceCode, domain, member: {...state, Address: {Address1: state.Address1, Address2: state.Address2, City: state.City, State: state.State, Country: state.Country, PostalCode: state.PostalCode}}, session})
    }

    const mapCustomField = (code: string, index: number) => {
        const field = profile.ClubMembershipCustomFields.find((d: any) => d.Code === code)

        if (!field)
            return null;

        const mapListOptions = (options: Array<any>) => {
            return options.map((option: any) => {
                return {
                    text: option.Name,
                    value: option.Value,
                }
            })
        }

        const mapRadioOptions = (options: Array<any>) => {
            return options.map((option: any) => {
                return {
                    label: option.Name,
                    value: option.Value,
                }
            })
        }

        let value = customCodes.find((curr: any) => curr.code === code)?.value
        switch (field.FieldType) {
            case ICustomFieldType.Int:
            case ICustomFieldType.Decimal:
                return (
                    <Fragment key={index}>
                        {/*@ts-ignore*/}
                        <NumberInput id={field.Code} value={value ?? ''} label={field.Name}
                                     handleChange={handleCustomFieldChange}/>
                    </Fragment>
                )
            case ICustomFieldType.ValidatedList:
                // if (snippetProfile.CustomFieldsDisplayType === CustomFieldDisplayType.Radio)
                //     return (
                //         <Fragment key={index}>
                //             <RadioSelection fieldName={field.Code} value={value} handleChange={handleCustomFieldChange}
                //                             helpText={field.HelpText} label={field.Name}
                //                             options={mapRadioOptions(field.ValidValues)}/>
                //         </Fragment>
                //     )

                return (
                    <Fragment key={index}>
                        <SelectInput options={mapListOptions(field.ValidValues)} label={field.Name}
                                     current={value ?? ""} fieldName={field.Code} handleChange={handleCustomFieldChange}
                                     disabled={false} required={field.IsRequired}/>
                    </Fragment>
                )
            case ICustomFieldType.Money:
            case ICustomFieldType.Date:
            case ICustomFieldType.Unknown:
            case ICustomFieldType.Time:
            case ICustomFieldType.DateTime:
            case ICustomFieldType.Bool:
            case ICustomFieldType.Byte:
            case ICustomFieldType.Blob:
            case ICustomFieldType.List:
            case ICustomFieldType.MultiSelectValidatedList:
            case ICustomFieldType.String:
            default:
                return (
                    <Fragment key={index}>
                        <TextInput id={field.Code} value={value ?? ''} label={field.Name}
                                   handleChange={handleCustomFieldChange}/>
                    </Fragment>
                )
        }
    }

    const mapCustomerField = (field: IInputConfig, index: number) => {
        if (!field.IsIncluded && !field.IsRequired)
            return;

        let fieldDefinition = profile.CustomerCustomFields?.find((cf: any) => cf.Name === field.FieldName);

        if (fieldDefinition) {
            return mapCustomField(fieldDefinition.Code, index);
        }

        // @ts-ignore
        let value = state[field.FieldName];
        if (!value && field.FieldName === 'Zip') {
            value = state.PostalCode
            field.FieldName = "PostalCode"
        }
        
        let inputField: any;
        const label = field.Label ? field.Label : getDisplayValue(field.FieldName);
        switch (field.FieldName) {
            case 'Password':
                if (customer)
                    return <></>

                if (!passwordDisplayed)
                    setPasswordDisplayed(true);

                inputField = <>
                    <PasswordField required={field.IsRequired} id={field.FieldName} value={''} label={label}
                                   handleChange={handleChange}/>
                    <br/>
                    <PasswordField id={'VerifyPassword'} value={''} label={`Verify Password`}
                                   required={field.IsRequired} handleChange={handleChange}/>
                </>;
                break;
            case 'EmailAddress':
                inputField = <EmailInput required={field.IsRequired} id={field.FieldName} value={value} label={label} handleChange={handleChange}/>;
                break;
            case 'Country':
                return (
                    <Fragment key={index}>
                        <SelectInput current={value} disabled={false} fieldName={field.FieldName} handleChange={handleChange} options={countryOptions}
                                     label={label} required={field.IsRequired}/>
                    </Fragment>
                );
            case 'State':
                if (state.Country === '') {
                    return;
                }
                const selected: ICountry = countries?.find((curr: ICountry) => curr.Code === state.Country);
                if (selected?.HasStates)
                    return (
                        <Fragment key={index}>
                            <SelectInput current={value} disabled={false} fieldName={field.FieldName} handleChange={handleChange}
                                         options={stateOptions} label={label} required={field.IsRequired}/>
                        </Fragment>
                    );
                else
                    inputField = <TextInput required={field.IsRequired} id={field.FieldName} value={value} label={label} handleChange={handleChange}/>;

                break;
            case 'BirthDate':
                // value = value
                return (
                    <Fragment key={index}>

                        <TextInput id={field.FieldName} value={value ?? ''} label={label} required={field.IsRequired}
                                   handleChange={handleChange}/>
                    </Fragment>
                );
            default:
                inputField = <TextInput required={field.IsRequired} id={field.FieldName} value={value} label={label} handleChange={handleChange}/>;
        }
        return (
            <Fragment key={index}>
                {inputField}
            </Fragment>
        );
    };
    
    return (
        <div className='bLoyal-center bLoyal-form bLoyal-customer-details-page'>
            {!snippetProfile && <p className={'bLoyal-error-text'}>No Snippet found</p>}
            {snippetProfile &&
                <>
                    <SnippetHeader logoUrl={props.snippetProfile.LogoUrl} title={props.snippetProfile.Title} message={props.snippetProfile.Message} snippetCode={props.snippetProfile.Code}/>
                    <form onSubmit={saveCustomer}>
                        <div className='bLoyal-center bLoyal-input-holder'>
                            {snippetProfile.CustomerFields.map(mapCustomerField)}
                        </div>
                        {/*@ts-ignore*/}
                        {props.backLabel !== undefined && <button className='bl-snippet-button' onClick={props.onBack !== undefined ? props.onBack : () => {}}>{props.backLabel}</button>}
                        <button className='bl-snippet-button' type={'submit'}>{props.saveLabel ?? snippetProfile.SaveButtonText}</button>
                        {status === 'loading' && <LoadingSpinner />}
                        {/*@ts-ignore*/}
                        {status === 'error' && <p className='bLoyal-error-text'>{error?.includes('MobilePhone') ? `${snippetProfile.CustomerFields.find((f) => f.FieldName === 'MobilePhone')?.Label ?? 'Mobile'} is in use by another customer` : error }</p>}
                    </form>
                    {status === 'success' &&
                        <p className='message'>{props.snippetProfile.OnSuccessMessage ?? 'Success'}</p>
                    }
                    <SnippetFooter footer={props.snippetProfile.Footer} snippetCode={props.snippetProfile.Code}/>
                </>
            }
        </div>
    )
}