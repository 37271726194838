import React from 'react';
import NumberInput from './NumberInput';
import SelectInput from './SelectInput';

type TableInputProps = {
	type: string,
	value: any,
	label: string,
	id: string,
	getValue: (value: any, rowNum: number) => void
	rowNum: number,
	className: string,
	options?: Array<any>
}

export default function TableInput(props: TableInputProps) {
	
	const getValue = (value: any) => {
		props.getValue(value, props.rowNum);
	}
	
	const handleChange = (field: string, value: string) => {
		let intValue = parseInt(value);
		if (Number.isNaN(intValue))
			intValue = props.options![0].value;
		props.getValue(intValue, props.rowNum);
	}
	
	if (props.type === 'number') {
		return (
			<div style={{float: 'right'}}>
				<NumberInput id={props.id} value={props.value} label={props.label} getValue={getValue} className={props.className}/>
			</div>
		)
	}
	if (props.type === 'options')
		return (
			<div style={{float: 'right'}}>
				<SelectInput options={props.options!} label={props.label} current={props.value} fieldName={props.id} handleChange={handleChange} disabled={false} isTableInput={true} width={100}/>
			</div>
		)
	return (
		<div>
		</div>
	);
}