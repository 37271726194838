import React from 'react';

type PaymentApprovedLandingProps = {}

export default function PaymentApprovedLanding(props: PaymentApprovedLandingProps) {
	return (
		<div className='bLoyal-center form'>
			<h1>Payment Method Approved</h1>
		</div>
	);
}